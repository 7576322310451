import { DownOutlined, LogoutOutlined, UpOutlined, UploadOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd'
import React, { useMemo, useState } from 'react'
import { useConsultation } from '../../../contexts'
import css from './FlowControls.module.css'
import { Close, Handover } from '../consultation'
import { ControlButton } from './ControlButton'
import { useCan } from '../../../hooks'
import { useTranslation } from 'react-i18next'

interface FlowControlsProps {
  className?: string
}

export const FlowControls: React.FC<FlowControlsProps> = (props) => {
  const { className } = props
  const { loading, move, canForward, canBackward, shareable, flush, closable, visible, active, consultation } =
    useConsultation()
  const [handover, setHandover] = useState<boolean>(false)
  const [close, setClose] = useState<boolean>(false)
  const canHandover = useCan('consultation:handover')
  const canClose = useCan('consultation:close')
  const canExitToRoot = useCan('consultation:exitToRoot')
  const canExitToExercises = useCan('consultation:exitToExercises')
  const canExitToArchive = useCan('consultation:exitToArchive')
  const { t } = useTranslation('consultation')

  const isAtEnd = useMemo(() => active[active.length - 1] === visible[visible.length - 1], [visible, active])
  const isTraining = useMemo(() => !!consultation?.training, [consultation?.training])

  const isPublic = useMemo<boolean>(
    () => !!canExitToRoot && !canExitToArchive && !canExitToExercises,
    [canExitToArchive, canExitToRoot, canExitToExercises]
  )

  return (
    <div className={className}>
      <div className={css['controls']} id='flow-controls'>
        {canHandover && !isTraining && (
          <Tooltip title={t('controls.handover')} placement='top'>
            <div>
              <ControlButton
                id='handover'
                icon={<UploadOutlined />}
                loading={loading}
                disabled={!shareable}
                type={isAtEnd && isPublic ? 'primary' : undefined}
                pulse={isAtEnd && isPublic}
                onClick={() => {
                  setHandover((state) => !state)
                }}
              />
            </div>
          </Tooltip>
        )}

        {canClose && (
          <Tooltip title={isPublic ? t('controls.exit') : t('controls.close')} placement='top'>
            <div>
              <ControlButton
                id='close'
                icon={<LogoutOutlined />}
                loading={loading}
                type={isAtEnd && !isPublic ? 'primary' : undefined}
                pulse={isAtEnd && !isPublic}
                disabled={!closable}
                onClick={() => {
                  setClose((state) => !state)
                }}
              />
            </div>
          </Tooltip>
        )}

        <div className={css['stacked']} id='navigate'>
          <div>
            <ControlButton
              icon={<UpOutlined />}
              loading={loading}
              disabled={!canBackward}
              onClick={() => {
                move({ direction: 'backward' })
              }}
              aria-label={t('controls.back')}
              title={t('controls.back')}
            />
          </div>

          <div>
            <ControlButton
              icon={<DownOutlined />}
              loading={loading}
              disabled={!canForward}
              type='primary'
              onClick={() => {
                move({ direction: 'forward' })
              }}
              aria-label={t('controls.next')}
              title={t('controls.next')}
            />
          </div>
        </div>
      </div>

      <Close
        execute={close}
        onCancel={() => {
          setClose(false)
        }}
        onDone={() => {
          flush()
          setClose(false)
        }}
        showExit={isPublic}
      />

      <Handover
        execute={handover}
        onCancel={() => {
          setHandover(false)
        }}
        onDone={() => {
          flush()
          setHandover(false)
        }}
      />
    </div>
  )
}
