import React, { useEffect, useMemo, useRef, useState } from 'react'
import type { Step as StepInterface } from '../../../@types'

import { ComponentSelect } from '../component'
import { useConsultation, useError } from '../../../contexts'

import './Step.css'

interface StepProps {
  className?: string
  step: StepInterface
  position: number
  visible: boolean
}

export const Step: React.FC<StepProps> = (props) => {
  const {
    className,
    step: { component, name, nameMap },
    position: stepPosition,
    visible,
  } = props

  const { active, validation, data, required, move } = useConsultation()
  const { pushError } = useError()
  const [touched, setTouched] = useState<boolean>(false)
  const ref = useRef<HTMLElement>(null)

  /** indicates if step is currently active */
  const isActive = useMemo<boolean>(() => active.includes(stepPosition), [stepPosition, active])
  const isRequired = useMemo<boolean>(() => required.includes(stepPosition), [required, stepPosition])

  useEffect(() => {
    if (typeof component === 'object' && component.requires.length && isActive && data !== undefined) {
      const { requires } = component
      const missing = requires.reduce<string[]>((missing: string[], key: string) => {
        const reverseNameMap =
          nameMap && Object.fromEntries(Object.entries(nameMap).map(([key, value]) => [value, key]))
        if (data[reverseNameMap?.[key] || key] === undefined) return [...missing, key]
        return [...missing]
      }, [])
      if (missing.length) pushError(new Error(`Component ${name} lacks required data items [${missing.join(', ')}]`))
    }
  }, [active, data, component, isActive, pushError, name, nameMap])

  const shieldClassName = useMemo(() => {
    const classNames = []
    classNames.push('step')
    if (!isActive) classNames.push('not-active')
    return classNames.join(' ')
  }, [isActive])

  // reset touched when not active
  useEffect(() => {
    if (!isActive) {
      setTouched(false)
      ref.current?.blur()
    }
  }, [isActive])

  return (
    <div className={className}>
      <div className={shieldClassName}>
        {visible && (
          <ComponentSelect
            ref={ref}
            className='mb-4'
            name={name}
            data={data}
            nameMap={nameMap}
            position={stepPosition}
            validation={validation[stepPosition]}
            status={validation[stepPosition] ? 'error' : undefined}
            isRequired={isRequired}
            isActive={isActive}
            touched={touched}
            onFocus={() => {
              if (!isActive) {
                // if an unactive step gets focused (i.e. by tabbing)
                if (active.every((stepIndex) => !validation[stepIndex])) {
                  // move to this step, if active steps are all valid
                  move({ toStep: name })
                } else {
                  // if not all active steps valid, remove focus from unactive, focused step
                  ref.current?.blur && ref.current?.blur()
                }
              }
            }}
            onBlur={() => {
              setTouched(true)
            }}
            {...component}
          />
        )}
      </div>
    </div>
  )
}
