import React, { forwardRef, useEffect, useMemo } from 'react'
import type { ComponentProps, Localized } from '../../../../@types'
import { Markdown } from '../../../utils'
import css from './Text.module.css'
import { useError } from '../../../../contexts'
import { useHandlebars, useLocalize } from '../../../../hooks'

export const Text = forwardRef<HTMLDivElement, ComponentProps<TextMeta>>(function Text(
  props: ComponentProps<TextMeta>,
  ref
): JSX.Element {
  const { meta, className, data, nameMap, name, _id } = props
  const { text } = meta
  const { pushError } = useError()
  const { compile } = useHandlebars()
  const { localize } = useLocalize()

  // maps the consultation data to the name-value items defined by nameMap
  const mappedData = useMemo(() => {
    if (!nameMap || data === undefined) return data
    return Object.fromEntries(Object.entries(data).map(([key, value]) => [nameMap[key] || key, value]))
  }, [data, nameMap])

  useEffect(() => {
    if (typeof localize(text) !== 'string')
      pushError(new Error(`In step ${name} and component ${_id}: meta.text is not a string!`))
  }, [text, pushError, name, _id, localize])

  const compiled = useMemo<string | undefined>(() => {
    if (!localize(text) || typeof localize(text) !== 'string') {
      return undefined
    }
    const template = compile(localize(text))
    return template({ ...mappedData })
  }, [text, mappedData, compile, localize])

  return (
    <div ref={ref} className={className}>
      {compiled && <Markdown className={css['text']}>{compiled}</Markdown>}
    </div>
  )
})

interface TextMeta {
  text?: Localized
}
