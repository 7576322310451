import React from 'react'
import { InputLabel, Search } from '../../utils'
import { useTranslation } from 'react-i18next'
import { ConsultationsCSV } from './ConsultationsCSV'
import { FileExcelOutlined } from '@ant-design/icons'
import css from './ConsultationsSider.module.css'
import { useCan } from '../../../hooks'

interface ConsultationsSiderProps {
  className?: string
  refSearch?: string | null
  query?: Api.ListQuery
  onSearch?: (text: string | null) => void
}

export const ConsultationsSider = (props: ConsultationsSiderProps): JSX.Element => {
  const { className, refSearch, query, onSearch } = props
  const { t } = useTranslation('consultations')
  const canConsultationsCSV = useCan('consultation:csv')

  return (
    <div className={className ? `${css['container']} ${className}` : css['container']}>
      <InputLabel id='ref-search' label={t('search.label')}>
        <Search initValue={refSearch || undefined} className='w-full' onSearch={onSearch} />
      </InputLabel>
      {canConsultationsCSV && (
        <InputLabel id='csv-download' label={t('csv.button.label')}>
          <ConsultationsCSV
            className='w-full'
            query={query}
            icon={<FileExcelOutlined />}
            title={t('csv.button.description')}
            aria-label={t('csv.button.description')}
          >
            {t('csv.button.title')}
          </ConsultationsCSV>
        </InputLabel>
      )}
    </div>
  )
}
