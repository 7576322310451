import { StepConfig } from '../../@types'

export const startSteps: StepConfig[] = [
  {
    key: 'welcome',
    location: {
      pathname: '/',
    },
    target: '#app',
    placement: 'center',
    disableScrolling: true,
  },
]

export const endSteps: StepConfig[] = [
  {
    key: 'end',
    location: {
      pathname: '/',
    },
    target: '#app',
    placement: 'center',
    disableScrolling: true,
    sider: false,
  },
]
