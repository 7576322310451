const colors = {
  primary: {
    DEFAULT: '#2cb99b',
  },
  secondary: {
    DEFAULT: '#D6F0EC',
  },
  dark: {
    DEFAULT: '#123b52',
  },
  light: {
    DEFAULT: '#FBFEFD',
  },
  error: {
    DEFAULT: '#db5a42',
  },
  success: {
    DEFAULT: '#5ECD81',
  },
  warning: {
    DEFAULT: '#f6ae2d',
  },
  info: {
    DEFAULT: '#37A8E6',
  },
}

module.exports = { colors }
