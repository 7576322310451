/* eslint-disable @typescript-eslint/no-explicit-any */
import type { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import { useState, useEffect, useRef, useCallback } from 'react'
import { useError, useApi, useIdentity } from '../contexts'

interface ApiRequest<ResponseData> {
  /** @prop {object} data - the response body */
  data?: ResponseData

  /** count of document if list request */
  count?: number

  /** message of api */
  message?: string

  /** @prop {boolean} loading - indicates if request is pending */
  loading: boolean

  /**
   * @prop {object} error - the error if one. The hook calls `pushError()` internally. You can use `error`
   * to modify the message for example.
   */
  error?: AxiosError<ResponseData>

  /** the response headers */
  headers?: AxiosResponse['headers']

  /** flush the respnse data loaded from the api */
  flush: () => void

  /** fires the api once */
  fire: () => Promise<void> | null
}

export interface ApiRequestConfig<RequestData = undefined> extends AxiosRequestConfig<RequestData> {
  /** @prop {boolean} skip - if set to true the request is not excecuted */
  skip?: boolean
}

/**
 * hook to perform api calls using the `APP_API_URL` from the environment as base url.
 *
 * @arg {object} config - the memorized config object `RequestConfig<RequestData>`
 */
export function useApiRequest<ResponseData = any, RequestData = undefined>(
  config: ApiRequestConfig<RequestData>
): ApiRequest<ResponseData> {
  const controllerRef = useRef<AbortController | null>(null)
  const requestRef = useRef<Promise<void> | null>(null)
  const [fireOnce, setFireOnce] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [data, setData] = useState<ResponseData | undefined>(undefined)
  const [count, setCount] = useState<number | undefined>(undefined)
  const [headers, setHeaders] = useState<AxiosResponse['headers'] | undefined>(undefined)
  const [message, setMessage] = useState<string | undefined>(undefined)
  const [error, setLocalError] = useState<ApiRequest<ResponseData>['error']>(undefined)
  const { clientRef, setRetry, onError401Ref } = useApi()
  const { token } = useIdentity()

  const { pushError } = useError()

  const fire = useCallback(() => {
    setFireOnce(true)
    return requestRef.current
  }, [])

  const flush = useCallback(() => {
    controllerRef.current?.abort()
    setData(undefined)
    setCount(undefined)
    setMessage(undefined)
    setHeaders(undefined)
    setLocalError(undefined)
    setLoading(false)
    setFireOnce(false)
  }, [])

  useEffect(() => {
    if ((!config.skip || fireOnce) && token !== undefined) {
      controllerRef.current = new AbortController()
      const controller = controllerRef.current

      // request job
      const request = async () => {
        setLoading(true)

        // add token to header if available
        const requestHeaders: AxiosRequestConfig['headers'] = {
          ...config.headers,
          Authorization: token && !config.headers?.Authorization ? `Bearer ${token}` : config.headers?.Authorization,
        }

        const { data: raw, headers } = await clientRef.current({
          ...config,
          headers: requestHeaders,
          signal: controller.signal,
        })

        if (typeof headers?.['content-type'] === 'string' && !headers?.['content-type'].includes('application/json')) {
          setData(raw)
        } else {
          const { data, message, count } = raw
          setData(data)
          setCount(count)
          setMessage(message)
        }

        setHeaders(headers)
      }

      // execute job
      requestRef.current = request()
        .then(() => {
          setLocalError(undefined)
          setLoading(false)
          setFireOnce(false)
          setRetry(false)
        })
        .catch((error: AxiosError<ResponseData>) => {
          const isError401 = error.response?.status === 401
          const error401 = isError401 && onError401Ref.current ? onError401Ref.current(error) : undefined

          if (!isError401 || (isError401 && error401)) {
            pushError(error)
            setLocalError(error)
            if (error.name !== 'CanceledError') {
              // important: only set loading false if not canceled
              setLoading(false)
              setFireOnce(false)
            }
          }
        })

      // effect cleanup
      return () => {
        controller.abort()
        controllerRef.current = null
      }
    }
  }, [pushError, config, clientRef, token, setRetry, onError401Ref, fireOnce])

  return { data, count, loading, message, error, headers, flush, fire }
}
