import React, { useEffect, useMemo, useState } from 'react'
import { ApiRequestConfig, useApiRequest, useCan, useUserAgent } from '../../../../../../hooks'
import { AuthDevice, RemoveDevice } from '../../../../../auth'
import { useTranslation } from 'react-i18next'
import { useAuth } from '../../../../../../contexts'
import { Button } from 'antd'
import { LoginOutlined, LogoutOutlined } from '@ant-design/icons'
import { Heading } from '../../../../../typography'
import dayjs from 'dayjs'
import css from './AdminDevice.module.css'
import { Branch } from '../../../../../../@types'

export const AdminDevice = (): JSX.Element => {
  const { userAgent } = useUserAgent()
  const canDeviceCreate = useCan('device:create')
  const canDeviceRemove = useCan('device:removeCurrent')
  const [showAuthDevice, setShowAuthDevice] = useState<boolean>(false)
  const [showRemoveDevice, setShowRemoveDevice] = useState<boolean>(false)
  const { device } = useAuth()
  const { t } = useTranslation('admin', { keyPrefix: 'device' })

  const getBranch = useMemo<ApiRequestConfig>(
    () => ({
      method: 'GET',
      url: `/branches/${device?.branch}`,
      skip: !device?.branch,
    }),
    [device]
  )
  const { data: branch, flush: flushBranch } = useApiRequest<Branch>(getBranch)

  useEffect(() => {
    if (!device) {
      flushBranch()
    }
  }, [device, flushBranch])

  return (
    <div className={css['container']}>
      {device && (
        <div>
          <Heading level={4}>{device.name}</Heading>
          {branch && (
            <p>
              <span className='font-medium'>{t('branch')}</span>: {branch.name}
            </p>
          )}
          {device.createdAt && (
            <p>
              {t('createdAt')} {dayjs(device.createdAt).fromNow()}
            </p>
          )}
          {canDeviceRemove && (
            <>
              <Button
                icon={<LogoutOutlined />}
                danger
                onClick={() => {
                  setShowRemoveDevice(true)
                }}
              >
                {t('remove.button')}
              </Button>
              <RemoveDevice
                show={showRemoveDevice}
                onCancel={() => {
                  setShowRemoveDevice(false)
                }}
                onRemoved={() => {
                  setShowRemoveDevice(false)
                }}
              />
            </>
          )}
        </div>
      )}

      {!device && (
        <div>
          <Heading level={4}>{t('unknown')}</Heading>
          {canDeviceCreate && (
            <div>
              <Button
                type='primary'
                icon={<LoginOutlined />}
                onClick={() => {
                  setShowAuthDevice(true)
                }}
              >
                {t('authenticate.button')}
              </Button>

              <AuthDevice
                show={showAuthDevice}
                onCancel={() => {
                  setShowAuthDevice(false)
                }}
                onAuthenticated={() => {
                  setShowAuthDevice(false)
                }}
              />
            </div>
          )}
        </div>
      )}
      <div>
        <Heading level={5}>{t('info')}</Heading>
        <p>{userAgent}</p>
      </div>
    </div>
  )
}
