import React, { ReactNode } from 'react'
import css from './Bubble.module.css'

interface BubbleProps {
  className?: string
  children?: ReactNode
}

export const Bubble = (props: BubbleProps): JSX.Element => {
  const { className, children } = props

  return (
    <div className={className ? `${css['bubble']} ${className}` : css['bubble']}>
      <div className={css['content']}>{children}</div>
    </div>
  )
}
