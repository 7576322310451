import React, { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { Spin } from 'antd'
// import css from './Handout.module.css'
import { ApiRequestConfig, useApiRequest, useJsonLogic, useLocalize } from '../../../../hooks'
import { Consultation, HandoutPopulated } from '../../../../@types'
import { Heading } from '../../../typography'
import { ReportContent } from '../../../content/report'
import { CardContainer } from '../../../utils'

export const Handout = (): JSX.Element => {
  const { hash } = useParams()
  const { apply } = useJsonLogic()
  const { localize } = useLocalize()

  const getHandout = useMemo<ApiRequestConfig>(
    () => ({
      method: 'GET',
      url: `/handouts/${hash}`,
      skip: !hash,
    }),
    [hash]
  )
  const { data: handout, loading } = useApiRequest<HandoutPopulated>(getHandout)

  const computed = useMemo<Consultation['data'] | undefined>(() => {
    const { consultation, variables } = handout || {}
    const { data } = consultation || {}
    if (!data || !variables) return undefined
    return Object.fromEntries(Object.entries(variables).map(([key, rule]) => [key, apply(rule, data)]))
  }, [handout, apply])

  const data = useMemo<Consultation['data'] | undefined>(
    () =>
      handout?.consultation?.data || computed
        ? { ...handout?.consultation?.data, ...computed, ref: handout?.consultation?.ref }
        : undefined,
    [handout, computed]
  )

  return (
    <Spin spinning={loading}>
      <CardContainer>
        {handout?.report && (
          <>
            {handout.report.name && <Heading level={2}>{localize(handout.report.name)}</Heading>}
            {(handout.report.contents || []).map(({ _id, ...rest }, index) => (
              <ReportContent key={_id} {...rest} data={data} position={index} />
            ))}
          </>
        )}
      </CardContainer>
    </Spin>
  )
}
