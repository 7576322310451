import React, { createContext, useMemo, useContext, PropsWithChildren, useEffect, useState } from 'react'
import { usePublic } from '../hooks'
import { SpinPlaceholder } from '../components/utils'

type AppEnvironment = Record<string, string | undefined>

interface EnvironmentContextInterface {
  environment: AppEnvironment
}

const EnvironmentContext = createContext<EnvironmentContextInterface | undefined>(undefined)

const EnvironmentContextProvider: React.FC<PropsWithChildren> = (props) => {
  const { children } = props
  const [initialized, setInitialized] = useState<boolean | undefined>(undefined)

  const { data: environment, error } = usePublic<AppEnvironment>(
    useMemo(() => ({ url: 'env.json', skip: initialized === undefined }), [initialized])
  )

  useEffect(() => {
    if (error) {
      if (error.response?.status === 404) {
        // modify error message
        error.message = 'No operation environment specified! Specify it in a static file env.json'
      }
    }
  }, [error])

  const value = useMemo<EnvironmentContextInterface>(
    () => ({
      environment: environment || {},
    }),
    [environment]
  )

  useEffect(() => {
    if (initialized === undefined) {
      setInitialized(true)
    }
  }, [initialized])

  return (
    <EnvironmentContext.Provider value={value}>
      {environment ? children : <SpinPlaceholder id='environment-context' />}
    </EnvironmentContext.Provider>
  )
}

const useEnvironment = (): EnvironmentContextInterface => {
  const context = useContext(EnvironmentContext)
  if (!context) {
    throw new Error('useEnvironment must be inside a Provider with a value')
  }
  return context
}

export { EnvironmentContextProvider, useEnvironment }
