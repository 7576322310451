import React, { useEffect, useMemo, useState } from 'react'
import { Input } from 'antd'
import { ConsultationContextInterface } from '../../../contexts'
import { useCan, useDebounce } from '../../../hooks'
import css from './Memo.module.css'
import { TrialSwitch } from '../../utils'

const { TextArea } = Input

interface MemoProps {
  className?: string
  consultationContext: ConsultationContextInterface
}

export const Memo: React.FC<MemoProps> = (props) => {
  const { className, consultationContext } = props
  const { update, loading, consultation } = consultationContext
  const [syncValue, setSyncValue] = useState<string | null>(null)
  const debouncedSyncValue = useDebounce(syncValue)

  const value = useMemo(() => {
    if (syncValue !== null) {
      return syncValue
    }
    if (consultation?.memo) {
      return consultation.memo
    }
    return ''
  }, [syncValue, consultation])

  useEffect(() => {
    if (debouncedSyncValue !== null && !loading && consultation) {
      if (debouncedSyncValue === '' && consultation.memo !== null) {
        update({ memo: null })
      } else if (debouncedSyncValue !== consultation.memo && debouncedSyncValue !== '') {
        update({ memo: debouncedSyncValue })
      }
    }
  }, [debouncedSyncValue, update, consultation, loading])

  useEffect(() => {
    if (!loading && consultation && syncValue !== null) {
      if (consultation.memo === null && syncValue === '') {
        setSyncValue(null)
      }
      if (consultation.memo === syncValue) {
        setSyncValue(null)
      }
    }
  }, [loading, consultation, syncValue])

  const canTrialToggle = useCan('trial:toggle')

  return (
    <div className={className ? `${className} ${css['container']}` : css['container']}>
      <TextArea
        value={value}
        onChange={(e) => {
          setSyncValue(e.target.value)
        }}
        autoSize={{ minRows: 4, maxRows: 16 }}
      />
      {canTrialToggle && <TrialSwitch />}
    </div>
  )
}
