import React from 'react'
import type { Consultation, Step } from '../../../@types'
import { ComponentSelect } from '../component'
// import './Info.css'

interface InfoProps {
  className?: string
  visible?: boolean
  component: Step['info']
  nameMap?: Step['nameMap']
  data?: Consultation['data']
  /** the name of the step (unique identifier) */
  stepName: Step['name']
  /** the position of the corresponding step */
  position: number
}

export const Info: React.FC<InfoProps> = (props) => {
  const { className, component, visible = true, nameMap, stepName, position, data } = props

  return (
    <div className={className}>
      {component && visible && (
        <ComponentSelect name={`${stepName}Info`} data={data} nameMap={nameMap} position={position} {...component} />
      )}
    </div>
  )
}
