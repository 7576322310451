import React, { ReactNode, useMemo } from 'react'
import css from './Label.module.css'

interface LabelProps {
  className?: string
  required?: boolean
  children?: ReactNode
}

export const Label: React.FC<LabelProps> = (props) => {
  const { className: propsClassName, children, required = false } = props

  const className = useMemo(() => {
    const classNames = propsClassName?.split(' ') || []
    classNames.push(css.label)
    if (required) classNames.push(css.required)
    return classNames.join(' ')
  }, [propsClassName, required])

  return <span className={className}>{children}</span>
}
