/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios'
import { useMemo, useState, useEffect } from 'react'
import type { AppError } from '../@types'
import { useError } from '../contexts'

interface Public<ResponseData> {
  /** @prop {object} data - the response body */
  data?: ResponseData
  /** @prop {boolean} loading - indicates if request is pending */
  loading: boolean
  /**
   * @prop {object} error - the error if one. The hook calls `pushError()` internally. You can use `error`
   * to modify the message for example.
   */
  error?: AppError
}

export interface PublicConfig {
  /** @prop {string} url - the path of the file in the public folder */
  url: string
  /** @prop {boolean} skip - if set to true the request is not excecuted */
  skip?: boolean
}

/**
 * hook to load files and data from the public folder.
 *
 * @arg {object} config - the memorized configuration of the hook.
 */
export function usePublic<ResponseData = Buffer>(config: PublicConfig): Public<ResponseData> {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<ResponseData | undefined>(undefined)
  const [error, setLocalError] = useState<AppError | undefined>(undefined)
  const { pushError } = useError()

  // create momorized axios client instance
  const client = useMemo(() => axios.create({ baseURL: '/' }), [])

  useEffect(() => {
    if (!config.skip) {
      const controler = new AbortController()

      // request job
      const request = async () => {
        setLoading(true)
        const { data } = await client.get<ResponseData>(config.url, { signal: controler.signal })
        setData(data)
      }

      // execute job
      request()
        .then(() => {
          setLocalError(undefined)
          setLoading(false)
        })
        .catch((error) => {
          pushError(error)
          setLocalError(error)
          if (error.name !== 'CanceledError') {
            // important: only set loading false if not canceled
            setLoading(false)
          }
        })

      // effect cleanup
      return () => {
        controler.abort()
      }
    }
  }, [pushError, client, config])

  return { data, loading, error }
}
