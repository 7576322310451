import { Button, ButtonProps, Modal } from 'antd'
import React, { ReactNode, useEffect, useMemo, useState } from 'react'
import { ApiRequestConfig, useApiRequest } from '../../../hooks'
import { DeleteOutlined } from '@ant-design/icons'
import { Consultation } from '../../../@types'
import { useMessage } from '../../../contexts'
import { useTranslation } from 'react-i18next'
// import css from './ConsultationRemove.module.css'

interface ConsultationRemoveProps extends ButtonProps {
  consultation: string
  confirm?: {
    title?: ReactNode
    description?: ReactNode
  }
  /** event when consultation is closed successfully */
  onDone?: () => void
  /** event when closing is aborted */
  onCancel?: () => void
}

export const ConsultationRemove = (props: ConsultationRemoveProps): JSX.Element => {
  const { consultation, confirm, onDone, onCancel, ...rest } = props
  const [exec, setExec] = useState<boolean>(false)
  const [remove, setRemove] = useState<boolean>(false)
  const { message } = useMessage()
  const { t } = useTranslation('consultation', { keyPrefix: 'remove' })

  const removeConsultation = useMemo<ApiRequestConfig>(
    () => ({
      method: 'DELETE',
      url: `/consultations/${consultation}`,
      skip: !consultation || !exec,
    }),
    [consultation, exec]
  )
  const { message: removeMessage, loading, flush } = useApiRequest<Consultation>(removeConsultation)

  useEffect(() => {
    if (removeMessage) {
      message.success(removeMessage)
      setExec(false)
      setRemove(false)
      flush()
      onDone && onDone()
    }
  }, [removeMessage, message, onDone, flush])

  return (
    <>
      <Button
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onClick={() => {
          setRemove(true)
        }}
        icon={<DeleteOutlined />}
        {...rest}
      />
      <Modal
        title={confirm?.title ? confirm.title : t('title')}
        open={remove && !!consultation}
        onOk={() => {
          setExec(true)
        }}
        onCancel={() => {
          setRemove(false)
          onCancel && onCancel()
        }}
        cancelText={t('cancel')}
        okText={t('ok')}
        okButtonProps={{ loading }}
        cancelButtonProps={{ loading }}
      >
        {confirm?.description ? confirm.description : <p>{t('description')}</p>}
      </Modal>
    </>
  )
}
