import React, { useMemo } from 'react'
import './HeaderNavigation.css'
import { Button, Space, Tooltip } from 'antd'
import { HeaderLogo, AuthMenu } from './util'
import { useBranches } from '../../hooks'
import { DesktopOutlined, LoginOutlined } from '@ant-design/icons'
import { useAuth, useIdentity } from '../../contexts'
import { useLocation } from 'react-router-dom'
import { IntroTrigger, LocaleSelect } from '../utils'
import { useTranslation } from 'react-i18next'

interface HeaderNavigationProps {
  title?: string
}

const HeaderNavigation: React.FC<HeaderNavigationProps> = (props) => {
  const { title } = props
  const location = useLocation()
  const { t } = useTranslation('common')
  const { authenticate } = useIdentity()

  const { device, employee, user } = useAuth()

  const showAuthMenu = useMemo<boolean>(() => !!user || !!employee, [user, employee])

  const branchIds = useMemo(() => (device?.branch ? [device.branch] : []), [device])
  const { branches, loading: branchesLoading } = useBranches(branchIds)
  const branchName = useMemo(() => branches[0]?.name, [branches])

  return (
    <div className='header-navigation'>
      <HeaderLogo className='logo' style={{ lineHeight: 'inherit' }} />

      {title && (
        <h1 className='title' style={{ lineHeight: 'inherit' }}>
          {title}
        </h1>
      )}

      <div className='cta'>
        <Space direction='horizontal'>
          <IntroTrigger />

          <LocaleSelect />

          {device && (
            <Tooltip title={branchName ? `${device.name} - ${branchName}` : device.name}>
              <Button type='default' shape='circle' icon={<DesktopOutlined />} loading={branchesLoading} />
            </Tooltip>
          )}

          {showAuthMenu && <AuthMenu />}
          {!employee && !user && (
            <Tooltip title={t('auth.login')}>
              <Button
                type='default'
                shape='circle'
                icon={<LoginOutlined />}
                onClick={() => {
                  window.localStorage.setItem('pathname', location.pathname)
                  authenticate()
                }}
              />
            </Tooltip>
          )}
        </Space>
      </div>
    </div>
  )
}

export { HeaderNavigation }
