import { useMemo } from 'react'

export const useUserAgent = () => {
  const userAgent = useMemo(() => navigator.userAgent, [])

  const flags = useMemo(
    () => ({
      isWebkit:
        /\b(iPad|iPhone|iPod)\b/.test(userAgent) &&
        /WebKit/.test(userAgent) &&
        !/Edge/.test(userAgent) &&
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        !(window as unknown as any).MSStream,

      isSafari: /Safari\//.test(userAgent) && !/Chrome\//.test(userAgent) && !/Chromium\//.test(userAgent),

      isFirefox: /Firefox\//.test(userAgent) && !/Seamonkey\//.test(userAgent),

      isChrome: /Chrome\//.test(userAgent) && !/Chromium\//.test(userAgent) && !/Edg.*\//.test(userAgent),

      isChromium: /Chromium\//.test(userAgent),

      isOperaGt15: /OPR\//.test(userAgent),

      isOperaLt12: /Opera\//.test(userAgent),
    }),
    [userAgent]
  )

  return { userAgent, ...flags }
}
