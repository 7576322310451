import React, { createContext, useMemo, useContext, PropsWithChildren } from 'react'
import { message, notification } from 'antd'
import type { MessageInstance } from 'antd/es/message/interface'
import type { NotificationInstance } from 'antd/es/notification/interface'

const { useMessage: useAntMessage } = message
const { useNotification } = notification

interface MessageContextInterface {
  message: MessageInstance
  notification: NotificationInstance
}

const MessageContext = createContext<MessageContextInterface | undefined>(undefined)

const MessageContextProvider: React.FC<PropsWithChildren> = (props) => {
  const { children } = props
  const [message, messageComponent] = useAntMessage()
  const [notification, notificationComponent] = useNotification()

  const value = useMemo<MessageContextInterface>(
    () => ({
      message,
      notification,
    }),
    [message, notification]
  )

  return (
    <MessageContext.Provider value={value}>
      {messageComponent}
      {notificationComponent}
      {children}
    </MessageContext.Provider>
  )
}

const useMessage = (): MessageContextInterface => {
  const context = useContext(MessageContext)
  if (!context) {
    throw new Error('useMessage must be inside a Provider with a value')
  }
  return context
}

export { MessageContextProvider, useMessage }
