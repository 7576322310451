import { Input, Modal, Spin } from 'antd'
import React, { useEffect, useMemo, useState } from 'react'
import { ApiRequestConfig, useApiRequest, useCan, useQuery } from '../../../hooks'
import { useConsultation, useMessage } from '../../../contexts'

import css from './Takeover.module.css'
import { Heading } from '../../typography'
import { DownloadOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Container } from '../../utils'

interface TakeoverProps {
  className?: string
}

export const Takeover: React.FC<TakeoverProps> = (props) => {
  const { className } = props
  const [params, setParams] = useQuery()
  const { message } = useMessage()
  const [code, setCode] = useState<string | undefined>(undefined)
  const canTakeover = useCan('consultation:takeover')
  const { t } = useTranslation('consultation', { keyPrefix: 'takeover' })
  const { hasConsultation, flush: flushConsultation } = useConsultation()
  const navigate = useNavigate()
  const [modal, modalContext] = Modal.useModal()

  const paramsCode = useMemo(() => params.code, [params.code])

  useEffect(() => {
    if (paramsCode && typeof paramsCode === 'string' && paramsCode.length === 6) setCode(paramsCode)
  }, [paramsCode])

  const codeReady = useMemo<boolean>(() => code?.length === 6, [code])

  useEffect(() => {
    if (codeReady && hasConsultation) {
      modal.warning({
        title: t('conflict.title'),
        content: <p> {t('conflict.description')}</p>,
        onOk: () => {
          navigate('/consultation')
        },
        okText: t('conflict.ok'),
      })
    }
  }, [modal, codeReady, hasConsultation, navigate, t])

  const getTakeover = useMemo<ApiRequestConfig>(
    () => ({
      method: 'GET',
      url: '/consultations/takeover',
      skip: !codeReady || hasConsultation || hasConsultation === undefined,
      params: { code },
    }),
    [codeReady, code, hasConsultation]
  )
  const { loading, message: messageTakeover, flush, error } = useApiRequest<undefined>(getTakeover)

  useEffect(() => {
    if (messageTakeover) {
      const doneCode = code
      setCode(undefined)
      flush()
      setParams((oldParams) => ({ ...oldParams, code: undefined }))
      // message.success('Takeover erfolgreich')
      flushConsultation(doneCode)
      navigate('/consultation')
    }
  }, [code, flush, flushConsultation, messageTakeover, setParams, message, navigate])

  useEffect(() => {
    if (error && error.response?.status === 404) {
      setParams((oldParams) => ({ ...oldParams, code: undefined }))
    }
  }, [error, setParams])

  return (
    <Container className={className} id='takeover'>
      {canTakeover && (
        <>
          <Heading level={2} className='text-center'>
            {t('title')}
          </Heading>
          <div id='code-input'>
            <div className={css['icon']}>
              <DownloadOutlined />
            </div>
            <Heading level={4} className={css['title']}>
              {t('code')}
            </Heading>
            <div className={css['container']}>
              <Spin spinning={loading}>
                <Input
                  value={code}
                  autoCapitalize='off'
                  placeholder='______'
                  onChange={(e) => {
                    setCode(e.target.value)
                  }}
                  maxLength={6}
                  className={css['input']}
                  style={{ paddingLeft: 'calc(5px + 0.6em)', width: 202, paddingRight: 5 }}
                />
              </Spin>
            </div>
          </div>
        </>
      )}
      {modalContext}
    </Container>
  )
}
