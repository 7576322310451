import {
  BorderOutlined,
  CheckSquareOutlined,
  CloseSquareOutlined,
  MinusSquareOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons'
import { Tooltip } from 'antd'
import React, { useMemo } from 'react'
import css from './ExerciseStatus.module.css'
import { Localized } from '../../../@types'
import { supportedExerciseStatus, useLocalize } from '../../../hooks'

type ExerciseStatus = (typeof supportedExerciseStatus)[number]

export interface ExerciseStatusProps {
  className?: string
  status?: ExerciseStatus
}

const infos: Partial<Record<ExerciseStatus | 'missing', Localized>> = {
  passed: {
    'de-CH': 'Alle trainings bestanden',
    'en-US': 'All trainings passed',
  },
  completed: {
    'de-CH': 'Gewisse trainings fehlerhaft',
    'en-US': 'Some trainings are faulty',
  },
  incomplete: {
    'de-CH': 'Noch trainings unbearbeitet',
    'en-US': 'Still unprocessed trainings',
  },
  open: {
    'de-CH': 'Übungset noch nicht gestarted',
    'en-US': 'Exercise set not yet started',
  },
  missing: {
    'de-CH': 'Der Status ist nicht verfügbar',
    'en-US': 'The status is not available',
  },
}

export const ExerciseStatus = (props: ExerciseStatusProps): JSX.Element => {
  const { className, status = 'missing' } = props
  const { localize } = useLocalize()

  const statusIconMap: Record<ExerciseStatus | 'missing', JSX.Element> = {
    passed: <CheckSquareOutlined />,
    completed: <CloseSquareOutlined />,
    incomplete: <MinusSquareOutlined />,
    open: <BorderOutlined />,
    missing: <QuestionCircleOutlined />,
  }

  const text = useMemo(() => {
    if (infos[status]) {
      return localize(infos[status])
    }
    return status
  }, [status, localize])

  return (
    <div className={className}>
      <Tooltip title={text}>
        <div className={`${css['status']} ${css[status]}`} style={{ lineHeight: 0 }}>
          {statusIconMap[status]}
        </div>
      </Tooltip>
    </div>
  )
}
