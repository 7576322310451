import React, { Suspense, useMemo, useState } from 'react'
import { BasicLayout, BasicLayoutProps } from '../../layouts'

import { Navigate, Route, Routes, useLocation, useParams } from 'react-router-dom'

import {
  ConsultationsList,
  FlowsList,
  Report,
  Consultation,
  Handout,
  ExerciseList,
  PrivacyPolicy,
  LegalNotice,
  PharMe,
  Admin,
  PostConsultation,
} from './routes'
import { SiderNavigation } from '../../navigation'
import { Protect } from '../../auth'
import { Alert } from 'antd'
import { SpinPlaceholder } from '../../utils'
import { DownloadOutlined, HistoryOutlined, NodeIndexOutlined, ReadOutlined, SettingOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { useAuth } from '../../../contexts'
import { useCan } from '../../../hooks'
import { MenuItemType } from 'antd/es/menu/hooks/useItems'

// import './Root.css'

const { ErrorBoundary } = Alert

export const Root: React.FC = () => {
  const [consultationLayoutProps, setConsultationLayoutProps] = useState<BasicLayoutProps | undefined>(undefined)
  const location = useLocation()
  const { t } = useTranslation('common', { keyPrefix: 'navigation.sider' })
  const { device, user } = useAuth()
  const [hasConsultation, setHasConsultation] = useState<boolean>(false)
  const [toggleSider, setToggleSider] = useState<boolean>(false)

  const isConsultation = useMemo<boolean>(() => location.pathname === '/consultation', [location])

  const canConsultationView = useCan('consultation:view') || (!!device && !user)
  const canConsultationsView = useCan('consultations:view') || (!!device && !user)
  const canExercisesView = useCan('exercises:view')
  const canFlowsView = useCan('flows:view') || (!!device && !user)
  const canAdminEmployees = useCan('admin:employees')
  const canAdminDevice = useCan('admin:device')

  const navItems = useMemo<MenuItemType[]>(
    () =>
      [
        {
          key: '/consultation/takeover',
          icon: <DownloadOutlined />,
          label: t('takeover'),
          can: canConsultationView,
          id: 'takeover',
        },
        {
          key: '/consultations',
          icon: <HistoryOutlined />,
          label: t('consultations'),
          can: canConsultationsView,
          id: 'consultations',
        },
        {
          key: '/exercises',
          icon: <ReadOutlined />,
          label: t('exercises'),
          can: canExercisesView,
          id: 'exercises',
        },
        {
          key: '/flows',
          icon: <NodeIndexOutlined />,
          label: t('flows'),
          can: canFlowsView,
          id: 'flows',
        },
        {
          key: '/admin',
          icon: <SettingOutlined />,
          label: t('admin'),
          can: canAdminDevice || canAdminEmployees,
          id: 'admin',
        },
      ]
        .filter(({ can }) => can)
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .map(({ can, ...rest }) => rest),
    [canAdminDevice, canAdminEmployees, canConsultationView, canConsultationsView, canExercisesView, canFlowsView, t]
  )

  return (
    <BasicLayout
      navigation={
        navItems.length ? (
          isConsultation && hasConsultation ? undefined : (
            <SiderNavigation
              items={navItems}
              onClick={() => {
                setToggleSider(true)
              }}
            />
          )
        ) : undefined
      }
      toggleSider={toggleSider}
      onToggleSider={() => {
        if (toggleSider === true) {
          setToggleSider(false)
        }
      }}
      {...(isConsultation ? consultationLayoutProps : undefined)}
    >
      <ErrorBoundary>
        <Suspense fallback={<SpinPlaceholder className='mt-4' id='root-suspense-fallback' />}>
          <Routes>
            <Route path='' element={<PharMe />} />
            <Route path='/done' element={<PostConsultation />} />
            <Route
              path='consultation/*'
              element={
                <Protect operation='consultation:access' requireEmployee requireUser allowPublic showAuth>
                  <Consultation
                    onBasicLayoutProps={(props) => {
                      setConsultationLayoutProps(props)
                    }}
                    onHasConsultation={(hasConsultation) => {
                      if (hasConsultation !== undefined) {
                        setHasConsultation(hasConsultation)
                      }
                    }}
                  />
                </Protect>
              }
            />
            <Route
              path='flows'
              element={
                <Protect operation='flows:view' showAuth requireUser requireEmployee>
                  <FlowsList />
                </Protect>
              }
            />
            <Route
              path='consultations'
              element={
                <Protect operation='consultations:view' showAuth requireUser requireEmployee>
                  <ConsultationsList />
                </Protect>
              }
            />
            <Route
              path='consultations/:consultationId/reports/:reportId'
              element={
                <Protect operation='report:view' context={{ params: useParams() }} showAuth requireUser requireEmployee>
                  <Report />
                </Protect>
              }
            />
            <Route
              path='exercises'
              element={
                <Protect operation='exercises:view' showAuth requireUser>
                  <ExerciseList />
                </Protect>
              }
            />
            <Route path='admin/*' element={<Admin />} />
            <Route path='handouts/:hash' element={<Handout />} />
            <Route path='privacy-policy' element={<PrivacyPolicy />} />
            <Route path='legal-notice' element={<LegalNotice />} />
            <Route path='*' element={<Navigate to={'/'} />} />
          </Routes>
        </Suspense>
      </ErrorBoundary>
    </BasicLayout>
  )
}
