import { useEffect, useMemo, useState } from 'react'
import { Branch } from '../@types'
import { ApiRequestConfig, useApiRequest } from './ApiRequest'

interface BranchesInterface {
  branches: Branch[]
  loading: boolean
}

export function useBranches(ids: Branch['_id'][], limit = 10): BranchesInterface {
  const [initialized, setInitialized] = useState<boolean | undefined>(undefined)

  const getBranches = useMemo<ApiRequestConfig>(
    () => ({
      method: 'GET',
      url: '/branches',
      skip: !ids.length || !initialized,
      params: {
        filter: {
          _id: { $in: ids },
        },
        limit,
      },
    }),
    [ids, limit, initialized]
  )
  const { data: branches, loading } = useApiRequest<Branch[]>(getBranches)

  useEffect(() => {
    if (initialized === undefined) {
      setInitialized(true)
    }
  }, [initialized])

  return { branches: branches || [], loading }
}
