import { Consultation, StepConfig } from '../../@types'

export const employeeSteps: StepConfig[] = [
  // {
  //   key: 'questionsBefore',
  //   location: {
  //     pathname: '/',
  //   },
  //   target: '#app',
  //   placement: 'center',
  //   disableScrolling: true,
  // },
  {
    key: 'linkStart',
    location: {
      pathname: '/',
    },
    target: '#app',
    placement: 'center',
    disableScrolling: true,
    sider: false,
  },
  {
    key: 'flowLinks',
    location: {
      pathname: '/flows',
    },
    target: '#sider-navigation',
    placement: 'top',
    disableScrolling: true,
    sider: true,
  },
  {
    key: 'showQR',
    location: {
      pathname: '/flows',
    },
    target: '#flows div:first-child',
    placement: 'auto',
    disableScrolling: false,
    sider: false,
  },
  {
    key: 'takeover',
    location: {
      pathname: '/consultation/takeover',
    },
    target: '#takeover #code-input',
    placement: 'auto',
    disableScrolling: true,
  },
  {
    key: 'consultationMode',
    location: {
      pathname: '/consultation',
      search: '?flow=646e2412578910064ddf2661',
    },
    target: '#app',
    placement: 'center',
    disableScrolling: true,
  },
  {
    key: 'stepByStep',
    location: {
      pathname: '/consultation',
      search: '?flow=646e2412578910064ddf2661',
    },
    target: '#flow-container .active.last-active',
    placement: 'auto',
    disableScrolling: true,
  },
  {
    key: 'navByButton',
    location: {
      pathname: '/consultation',
      search: '?flow=646e2412578910064ddf2661',
    },
    target: '#flow-container #flow-controls #navigate',
    placement: 'top',
    disableScrolling: true,
    spotlightClicks: true,
  },
  {
    key: 'navByAlternative',
    location: {
      pathname: '/consultation',
      search: '?flow=646e2412578910064ddf2661',
    },
    target: '#app',
    placement: 'center',
    sider: false,
  },
  // {
  //   key: 'tabs',
  //   location: {
  //     pathname: '/consultation',
  //     search: '?flow=646e2412578910064ddf2661',
  //   },
  //   target: '.consultation-sider .ant-tabs-nav',
  //   placement: 'auto',
  //   disableScrolling: true,
  //   spotlightClicks: true,
  //   sider: true,
  // },
  {
    key: 'summaryTab',
    location: {
      pathname: '/consultation',
      search: '?flow=646e2412578910064ddf2661',
    },
    target: '.consultation-sider .ant-tabs-nav #summary',
    placement: 'auto',
    disableScrolling: true,
    spotlightClicks: true,
    sider: true,
  },
  {
    key: 'infoTab',
    location: {
      pathname: '/consultation',
      search: '?flow=646e2412578910064ddf2661',
    },
    target: '.consultation-sider .ant-tabs-nav #info',
    placement: 'auto',
    disableScrolling: true,
    spotlightClicks: true,
    sider: true,
  },
  {
    key: 'memoTab',
    location: {
      pathname: '/consultation',
      search: '?flow=646e2412578910064ddf2661',
    },
    target: '.consultation-sider .ant-tabs-nav #memo',
    placement: 'auto',
    disableScrolling: true,
    spotlightClicks: true,
    sider: true,
  },
  {
    key: 'close',
    location: {
      pathname: '/consultation',
      search: '?flow=646e2412578910064ddf2661',
    },
    target: '#flow-container #flow-controls #close',
    placement: 'top',
    disableScrolling: true,
    spotlightClicks: false,
    sider: false,
  },
  {
    key: 'archive',
    location: {
      pathname: '/consultations',
    },
    target: '#consultations-list #consultation-card:first-child',
    placement: 'auto',
    disableScrolling: false,
  },
  {
    key: 'reference',
    location: {
      pathname: '/consultations',
    },
    target: '#consultations-list #consultation-card:first-child #reference',
    placement: 'auto',
    disableScrolling: true,
    sider: false,
  },
  {
    key: 'refSearch',
    location: {
      pathname: '/consultations',
    },
    target: '#ref-search',
    placement: 'bottom',
    disableScrolling: true,
    sider: true,
  },
  {
    key: 'reports',
    location: {
      pathname: '/consultations',
    },
    target: '#consultations-list #consultation-card:first-child #report-select',
    placement: 'auto',
    disableScrolling: true,
    sider: false,
  },
  {
    key: 'publicReport',
    location: ({ consultation }) => {
      if (!consultation) throw new Error('Can not navigate intro tour to report because no consultation on context!')
      const { flow, _id } = consultation as Consultation
      const { _id: reportId } = flow.reports.find(({ access }) => access === 'public') || {}
      if (!reportId) throw new Error('Can not navigate intro tour to report because no public report!')
      return { pathname: `/consultations/${_id}/reports/${reportId}` }
    },
    target: '#handout-report #handout-sharing',
    placement: 'auto',
    disableScrolling: true,
    sider: true,
  },
  {
    key: 'pinLogin',
    location: {
      pathname: '/',
    },
    target: '.header-navigation #auth-menu-button',
    placement: 'auto',
    disableScrolling: true,
    sider: false,
  },
]
