import React, { forwardRef, useEffect, useMemo } from 'react'
import type {
  ClinicalDecisionCheckDetailed,
  ClinicalDecisionCheckRelevanceCode,
  ComponentProps,
  Consultation,
  Localized,
  ProductSlim,
} from '../../../../@types'
import css from './DrugsComparison.module.css'
import { useError } from '../../../../contexts'
import { RulesLogic } from 'json-logic-js'
import { ApiRequestConfig, useApiRequest, useHandlebars, useLocalize } from '../../../../hooks'
import { Markdown } from '../../../utils'
import { Heading } from '../../../typography'
import { Popover, Spin, Tooltip } from 'antd'
import {
  CheckCircleFilled,
  CloseCircleFilled,
  CompassFilled,
  ExclamationCircleFilled,
  InfoCircleOutlined,
  MinusCircleFilled,
  QuestionCircleFilled,
  StopFilled,
  ToolFilled,
  WarningFilled,
  PauseCircleFilled,
} from '@ant-design/icons'
import { useTranslation } from 'react-i18next'

export const DrugsComparison = forwardRef<HTMLDivElement, ComponentProps<DrugsComparisonMeta>>(function DrugsComparison(
  props: ComponentProps<DrugsComparisonMeta>,
  ref
): JSX.Element {
  const { meta, data, nameMap, name, _id } = props
  const { drugs, interactingDrugs } = meta
  const { pushError } = useError()

  const mappedData = useMemo(() => {
    if (!nameMap || data === undefined) return data
    return Object.fromEntries(Object.entries(data).map(([key, value]) => [nameMap[key] || key, value]))
  }, [data, nameMap])

  const interacting = useMemo<ProductSlim[] | undefined>(
    () => (interactingDrugs ? (mappedData?.[interactingDrugs] as ProductSlim[] | undefined) : undefined),
    [interactingDrugs, mappedData]
  )

  useEffect(() => {
    if (!Array.isArray(drugs)) {
      pushError(new Error(`In step ${name} and component ${_id}: meta.drugs is not an array!`))
    }
  }, [drugs, pushError, name, _id])

  return (
    <div ref={ref}>
      {drugs.map((drug, index) => (
        <ComparisonSection key={index} data={mappedData} drug={drug} interacting={interacting} />
      ))}
    </div>
  )
})

const ComparisonSection = (props: ComparisonSectionProps): JSX.Element => {
  const { drug, data, interacting } = props
  const { compile } = useHandlebars()
  const { localize } = useLocalize()
  const { t } = useTranslation('components', { keyPrefix: 'DrugsComparison' })

  const { pros, cons } = useMemo<{ pros: string | undefined; cons: string | undefined }>(() => {
    let pros,
      cons = undefined

    if (typeof localize(drug.pros) === 'string') {
      const prosTemplate = compile(localize(drug.pros))
      pros = prosTemplate({ ...data })
    }

    if (typeof localize(drug.cons) === 'string') {
      const consTemplate = compile(localize(drug.cons))
      cons = consTemplate({ ...data })
    }

    return { pros, cons }
  }, [drug, data, compile, localize])

  return (
    <div className={css['drug']}>
      <Heading level={5}>{localize(drug.name)}</Heading>
      <div className={css['comparison']}>
        <div className={css['pros']}>
          <Heading level={6}>{t('pros')}</Heading>
          {pros ? <Markdown>{pros}</Markdown> : <span>{t('none')}</span>}
        </div>
        <div className={css['cons']}>
          <Heading level={6}>{t('cons')}</Heading>
          {cons ? <Markdown>{cons}</Markdown> : <span>{t('none')}</span>}
        </div>

        <Interactions className={css['interactions']} interacting={interacting} drug={drug} />
      </div>
    </div>
  )
}

const Interactions = (props: InteractionsProps): JSX.Element => {
  const { className, drug, interacting = [] } = props
  const { t } = useTranslation('components', { keyPrefix: 'DrugsComparison' })

  const interactingIds = useMemo(() => interacting.map(({ id }) => id), [interacting])

  const showInteractions = useMemo<boolean>(() => !!interactingIds.length && !!drug.productId, [interactingIds, drug])

  const getInteractions = useMemo<ApiRequestConfig>(
    () => ({
      method: 'GET',
      url: '/drugs/interactions',
      skip: !showInteractions,
      params: {
        ids: [drug.productId, ...interactingIds],
      },
    }),
    [interactingIds, drug.productId, showInteractions]
  )
  const { data: interactionCheck, loading } = useApiRequest<ClinicalDecisionCheckDetailed>(getInteractions)

  const interactingDrugs = useMemo(
    () => interactionCheck?.medicationItems, // .filter(({ medicament: { id } }) => id !== drug.productId),
    [interactionCheck]
  )

  return showInteractions && !!interactingDrugs ? (
    <div className={className}>
      <Spin spinning={loading}>
        <Heading level={6}>{t('interactions')}</Heading>
        <ul className={css['products']}>
          {interactingDrugs.map(({ medicament, medicationItemChecks, relevance }) => (
            <li key={medicament.id}>
              <div className={css['product']}>
                <div>
                  <Tooltip title={relevance.description} placement='right'>
                    <span>
                      <InteractionIcon code={relevance.code} />
                    </span>
                  </Tooltip>
                </div>
                <div className={medicament.id === drug.productId ? 'font-medium' : undefined}>
                  {medicament.productDescription}
                </div>
              </div>

              {!!medicationItemChecks[0]?.interactions?.length && (
                <ul className={css['product-interactions']}>
                  {medicationItemChecks[0]?.interactions.map(({ interactionNumber, descriptions, relevance }) =>
                    interactionNumber && descriptions[0]?.description ? (
                      <li key={interactionNumber}>
                        <div>
                          <Tooltip title={relevance.description} placement='right'>
                            <span>
                              <InteractionIcon code={relevance.code} />
                            </span>
                          </Tooltip>
                        </div>
                        <div>{descriptions[0].description}</div>

                        <div className='grow text-right'>
                          <Popover
                            placement='left'
                            title={
                              <div className={css['additional-info']}>
                                <Heading level={5} className='mb-6'>
                                  <InteractionIcon code={relevance.code} /> {descriptions[0].description}
                                </Heading>
                              </div>
                            }
                            content={
                              <div className={css['additional-info']}>
                                <Heading level={6}>Massnahmen</Heading>
                                <p>{relevance.description}</p>
                                {!!descriptions.slice(1).length && (
                                  <>
                                    <Heading level={6}>Effekt</Heading>
                                    {descriptions.slice(1).map(({ description }, index) => (
                                      <p key={index}>{description}</p>
                                    ))}
                                  </>
                                )}
                              </div>
                            }
                          >
                            {<InfoCircleOutlined className='text-gray-400' />}
                          </Popover>
                        </div>
                      </li>
                    ) : null
                  )}
                </ul>
              )}
            </li>
          ))}
        </ul>
      </Spin>
    </div>
  ) : (
    <></>
  )
}

const InteractionIcon = (props: InteractionIconProps): JSX.Element => {
  const { code } = props

  if (code === 500) return <ToolFilled className='text-blue-800' />
  if (code === 1) return <CloseCircleFilled className='text-error' />
  if (code === 2) return <ExclamationCircleFilled className='text-orange-400' />
  if (code === -1) return <MinusCircleFilled className='text-gray-400' />
  if (code === 0) return <QuestionCircleFilled className='text-gray-400' />
  if (code === 3 || code === 4 || code === 5) return <WarningFilled className='text-warning' />
  if (code === 6) return <StopFilled className='text-blue-800' />
  if (code === 70) return <CompassFilled className='text-purple-500' />
  if (code === 99) return <CheckCircleFilled className='text-success' />

  return <PauseCircleFilled className='text-gray-400' />
}

interface DrugsComparisonMeta {
  drugs: DrugSpecs[]
  /** name of the variable containing a list of drug products (`Product[]`) */
  interactingDrugs: string
}

interface DrugSpecs {
  name: Localized
  productId: string
  pros?: Localized
  cons?: Localized
  rule?: RulesLogic
}

interface ComparisonSectionProps {
  data: Consultation['data'] | undefined
  drug: DrugSpecs
  interacting?: ProductSlim[]
}

interface InteractionIconProps {
  code: ClinicalDecisionCheckRelevanceCode
}

interface InteractionsProps extends Omit<ComparisonSectionProps, 'data'> {
  className?: string
}
