import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react'
import { Input, InputRef } from 'antd'
import type { ComponentProps, Localized } from '../../../../@types'
import { useConsultation } from '../../../../contexts'
import { useDebounce, useLocalize } from '../../../../hooks'
import css from './ShortInput.module.css'
import { InputLayout } from '../utils'

export const ShortInput = forwardRef<unknown, ComponentProps<InputLayoutMeta>>(function ShortInput(
  props: ComponentProps<InputLayoutMeta>,
  ref
): JSX.Element {
  const { meta, className, name, status, validation, data, touched, isRequired, onFocus, onBlur, isActive } = props
  const { label, description } = meta
  const { update, loading } = useConsultation()
  const { localize } = useLocalize()

  const [syncValue, setSyncValue] = useState<string | null>(null)
  const debouncedSyncValue = useDebounce(syncValue)
  const localRef = useRef<InputRef | null>(null)

  useImperativeHandle(ref, () => localRef.current)

  const value = useMemo(() => {
    if (syncValue !== null) {
      return syncValue
    }
    if (data?.[name] && typeof data[name] === 'string') {
      return data[name] as string
    }
    return ''
  }, [syncValue, data, name])

  useEffect(() => {
    if (debouncedSyncValue !== null && !loading && data) {
      if (debouncedSyncValue === '' && data[name] !== undefined) {
        update({ data: { [name]: undefined } })
      } else if (debouncedSyncValue !== data[name] && debouncedSyncValue !== '') {
        update({ data: { [name]: debouncedSyncValue } })
      }
    }
  }, [debouncedSyncValue, update, data, name, loading])

  useEffect(() => {
    if (!loading && data && syncValue !== null) {
      if (data[name] === undefined && syncValue === '') {
        setSyncValue(null)
      }
      if (data[name] === syncValue) {
        setSyncValue(null)
      }
    }
  }, [loading, data, name, syncValue])

  return (
    <InputLayout
      className={className}
      label={localize(label)}
      description={localize(description)}
      status={touched ? status : undefined}
      validation={touched ? validation : undefined}
      required={isRequired}
      loading={isActive && loading}
    >
      <Input
        ref={localRef}
        value={value}
        allowClear
        className={css['input']}
        status={touched ? status : undefined}
        // the disable on loading causes a focus loss.
        // dynamic addonAfter causes focus loss.
        // see: https://ant.design/components/input#why-input-lose-focus-when-change-prefixsuffixshowcount
        // addonAfter={loading ? <LoadingOutlined /> : null}
        onChange={(e) => {
          setSyncValue(e.target.value)
        }}
        onPressEnter={() => {
          localRef.current?.blur()
        }}
        onBlur={onBlur}
        onFocus={onFocus}
      />
    </InputLayout>
  )
})

interface InputLayoutMeta {
  label?: Localized
  description?: Localized
}
