import { useEffect, useMemo, useState } from 'react'
import { ApiRequestConfig, useApiRequest } from './ApiRequest'
import type { FileMeta } from '../@types'
import { CacheItem, useError, useFile } from '../contexts'

export type ResponsiveImageArgs = [src?: string, options?: ImgproxyOptions]

interface ResponsiveImageInterface {
  src: string | undefined
  meta: FileMeta | undefined
  loading: boolean
}

/** loads image from API in desired size if `_src` is or contains a valid ObjectId (`_id`) */
export const useResponsiveImage = (...args: ResponsiveImageArgs): ResponsiveImageInterface => {
  const [_src, _options] = args
  const [src, setSrc] = useState<string | undefined>(undefined)
  const [hash, setHash] = useState<string | undefined | null>(undefined)
  const [cached, setCached] = useState<CacheItem | undefined | null>(undefined)
  const { fromCache, toCache } = useFile()
  const { pushError } = useError()

  // try to extract image _id from _src
  const _id = useMemo(() => {
    if (_src === undefined) return undefined
    if (_src && /^[a-f\d]{24}$/.test(_src)) return _src
    if (_src && /^[a-f\d]{24}$/.test(_src.slice(1)) && _src.charAt(0) === '/') return _src.slice(1)
    return null
  }, [_src])

  // compute unique image hash for _id & options combination
  useEffect(() => {
    if (_id) {
      const computeHash = async () =>
        Array.from(
          new Uint8Array(
            await crypto.subtle.digest('SHA-256', new TextEncoder().encode(JSON.stringify({ _id, _options })))
          )
        )
          .map((byte) => byte.toString(16).padStart(2, '0'))
          .join('')

      computeHash()
        .then((hash) => {
          setHash(hash)
        })
        .catch((error) => {
          pushError(error)
        })
    } else if (_id === null) {
      setHash(null)
    }
  }, [_id, _options, pushError])

  // reset cached whenever hash changes
  useEffect(() => {
    if (hash) {
      setCached(undefined)
    }
  }, [hash])

  // try to load from cache if cached not initialized (undefined)
  useEffect(() => {
    if (hash && cached === undefined) {
      setCached(fromCache(hash) || null)
    }
  }, [fromCache, hash, cached])

  // set img src if possible without fetching image
  useEffect(() => {
    if (_src && _id === null && src !== _src) {
      // if no image _id, set src from _src
      setSrc(_src)
    } else if (cached) {
      // if cached, set from cache
      setSrc(cached.src)
    }
  }, [_src, _id, src, cached])

  // fetch image meta
  const getImageMeta = useMemo<ApiRequestConfig>(
    () => ({
      method: 'GET',
      url: `/files/${_id}`,
      skip: !_id || cached !== null,
    }),
    [_id, cached]
  )
  const { data: fetchedMeta, loading: metaLoading } = useApiRequest<FileMeta>(getImageMeta)

  // provide image meta either from cache or fetched
  const meta = useMemo<undefined | FileMeta>(() => {
    if (cached === undefined) return undefined
    if (cached?.meta) return cached.meta
    if (cached === null) return fetchedMeta
  }, [fetchedMeta, cached])

  // fetch image
  const getImage = useMemo<ApiRequestConfig>(
    () => ({
      method: 'GET',
      url: `/images/${_id}`,
      skip: !_id || !meta || cached !== null,
      // if svg, convert to png
      params:
        meta?.mime !== 'image/svg+xml'
          ? { ..._options, dpr: window.devicePixelRatio }
          : { ..._options, dpr: window.devicePixelRatio, format: 'png' as ImgproxyFormat },
      responseType: 'blob',
    }),
    [_id, _options, meta, cached]
  )
  const { data: image, loading: fileLoading, headers, flush: flushImage } = useApiRequest<Blob>(getImage)

  // create blob image url and store image to cache
  useEffect(() => {
    if (image && headers?.['content-type'] && hash && meta && cached === null) {
      const blob = new Blob([image], { type: headers['content-type'] as string })
      const src = URL.createObjectURL(blob)

      setSrc(src)
      toCache(hash, { src, meta })
      flushImage()

      return () => {
        setCached(fromCache(hash) || null)
      }
    }
  }, [image, headers, hash, meta, cached, toCache, fromCache, flushImage])

  const loading = useMemo(() => metaLoading || fileLoading, [metaLoading, fileLoading])

  return { src, meta, loading }
}

/**
 * control how to process the image with [imgproxy](https://docs.imgproxy.net/)
 *
 * @style "deepObject"
 * @explode true
 */
export interface ImgproxyOptions {
  /**
   *sets resize options
   *
   * @style "deepObject"
   * @explode true
   * @example { "type": "fit", "width": 400, "height": 200, "enlarge": false }
   */
  resize?: {
    type?: 'fit' | 'fill' | 'fill-down' | 'force' | 'auto'

    width?: number

    height?: number

    enlarge?: boolean
  }

  /**
   *sets gravity options
   *
   * @style "deepObject"
   * @explode true
   */
  gravity?: {
    type?: 'no' | 'so' | 'ea' | 'we' | 'noea' | 'nowe' | 'soea' | 'sowe' | 'ce'
    x?: number
    y?: number
  }
  /**
   *modifies resolution
   *
   * @example 1
   */
  dpr?: number

  /**
   * the output image format
   *
   * @example png
   */
  format?: ImgproxyFormat
}

type ImgproxyFormat = 'png' | 'jpg' | 'webp' | 'avif' | 'gif' | 'ico' | 'svg' | 'heic' | 'bmp' | 'tiff'
