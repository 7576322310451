import React, { forwardRef, useMemo } from 'react'
import { Radio as AntRadio, Space } from 'antd'
import type { ComponentProps, Localized, SelectOption, SelectOptions } from '../../../../@types'
import css from './Radio.module.css'
import { useConsultation } from '../../../../contexts'
import { InputLayout } from '../utils'
import { useLocalize } from '../../../../hooks'

type RadioMeta = { options: SelectOptions; label?: Localized; description?: Localized }

export const Radio = forwardRef<HTMLDivElement, ComponentProps<RadioMeta>>(function Radio(
  props: ComponentProps<RadioMeta>,
  ref
): JSX.Element {
  const { meta, className, name, status, validation, data, isRequired, touched, onFocus, onBlur, isActive } = props
  const { options: metaOptions, label, description } = meta
  const { update, loading } = useConsultation()
  const { localize } = useLocalize()

  const options = useMemo(
    () =>
      (metaOptions ?? []).map(({ label, value }) => {
        if (typeof label === 'string') return { label, value }
        return { value, label: localize(label) }
      }),
    [metaOptions, localize]
  )

  const value = useMemo(() => (data?.[name] as undefined | SelectOption)?.value, [data, name])

  const radioClassName = useMemo(() => {
    if (touched && status === 'error') return `${css['radio']} ${css['error']}`
    if (touched && status === 'warning') return `${css['radio']} ${css['warning']}`
    return css['radio']
  }, [touched, status])

  const indicateLoading = useMemo(() => isActive && loading, [isActive, loading])

  const radios = useMemo(
    () =>
      options.map(({ value: optionValue, label }) => (
        <AntRadio
          disabled={indicateLoading}
          className={radioClassName}
          key={optionValue}
          value={optionValue}
          onKeyDown={(e) => {
            // avoids value change on arrow key navigation
            e.preventDefault()
          }}
          onClick={() => {
            // allows resetting a selected radio
            if (value === optionValue) {
              update({ data: { [name]: undefined } })
            }
          }}
        >
          {label}
        </AntRadio>
      )),
    [options, name, indicateLoading, value, update, radioClassName]
  )

  return (
    <InputLayout
      className={className}
      label={localize(label)}
      description={localize(description)}
      status={touched ? status : undefined}
      validation={touched ? validation : undefined}
      required={isRequired}
      loading={indicateLoading}
    >
      <AntRadio.Group
        ref={ref}
        value={value}
        className={css['input']}
        onChange={(e) => {
          onBlur && onBlur(e)
          update({ data: { [name]: metaOptions.find(({ value: optionValue }) => e.target.value === optionValue) } })
        }}
        onBlur={onBlur}
        disabled={loading}
        onFocus={onFocus}
      >
        <Space direction='vertical'>{radios}</Space>
      </AntRadio.Group>
    </InputLayout>
  )
})
