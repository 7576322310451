import { PrinterOutlined } from '@ant-design/icons'
import { Button, ButtonProps } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
// import css from './PrintButton.module.css'

export const PrintButton = (props: ButtonProps): JSX.Element => {
  const { t } = useTranslation('common', { keyPrefix: 'print' })
  const [print, setPrint] = useState<boolean>(false)

  useEffect(() => {
    if (print) {
      const timer = setTimeout(() => {
        setPrint(false)
        window.print()
      }, 500)
      return () => {
        clearTimeout(timer)
      }
    }
  }, [print])

  return (
    <>
      <Button
        icon={<PrinterOutlined />}
        type='default'
        {...props}
        onClick={() => {
          setPrint(true)
        }}
        loading={print}
      >
        {t('button')}
      </Button>
    </>
  )
}
