import { useEffect, useState } from 'react'
import { useAccess } from '../contexts/AccessContext'
import { useError } from '../contexts'
import { useParams } from 'react-router-dom'

export function useCan(operation: string, extraContext?: Record<string, unknown>): boolean | undefined {
  const { rbac, context: globalContext, roles } = useAccess()
  const [can, setCan] = useState<boolean | undefined>(undefined)
  const { pushError } = useError()
  const params = useParams()

  useEffect(() => {
    const check = async (): Promise<boolean> => {
      const { permission } = await rbac.can(roles, operation, { ...globalContext, ...extraContext, params })
      return permission
    }

    check()
      .then((permission) => {
        setCan(permission)
      })
      .catch((error) => {
        pushError(error)
      })
  }, [roles, globalContext, extraContext, operation, pushError, rbac, params])

  return can
}
