import React, { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { ApiRequestConfig, useApiRequest, useCan, useJsonLogic, useLocalize } from '../../../../hooks'
import { Consultation } from '../../../../@types'
import { Heading } from '../../../typography'
import { Spin } from 'antd'
import { useError } from '../../../../contexts'
import { ReportContent, ReportMemo, ReportSider } from '../../../content'
import css from './Report.module.css'
import { useBasicLayout } from '../../../layouts'
import { CardContainer } from '../../../utils'
import { useTranslation } from 'react-i18next'

export const Report = (): JSX.Element => {
  const { consultationId, reportId } = useParams()
  // start with initialized undefined to avoid double fetching in react strict mode
  const [initialized, setInitialized] = useState<boolean | undefined>(undefined)
  const { pushError } = useError()
  const { apply } = useJsonLogic()
  const { setSider } = useBasicLayout()
  const { localize } = useLocalize()
  const { t } = useTranslation('report')

  const getConsultation = useMemo<ApiRequestConfig>(
    () => ({
      method: 'GET',
      url: `/consultations/${consultationId}`,
      skip: !consultationId || initialized === undefined,
    }),
    [consultationId, initialized]
  )
  const { data: consultation, loading } = useApiRequest<Consultation>(getConsultation)

  const report = useMemo(
    () =>
      consultation?.flow?.reports?.find(({ _id }) => _id === reportId) ||
      consultation?.training?.reports?.find(({ _id }) => _id === reportId),
    [consultation, reportId]
  )

  const memo = useMemo(() => consultation?.memo, [consultation])

  const handout = useMemo(() => {
    if (consultation?.handouts) {
      return consultation.handouts.find(({ report: rId }) => rId === report?._id) || null
    }
    return undefined
  }, [consultation, report])

  const canMemoView = useCan('report:memoView', { report })

  useEffect(() => {
    if (report && consultation) {
      setSider(
        <ReportSider className='p-6' initHandout={handout} report={report} consultation={consultation._id} />
      )
      return () => {
        setSider(undefined)
      }
    } else {
      setSider(undefined)
    }
  }, [handout, setSider, report, consultation])

  const computed = useMemo<Consultation['data'] | undefined>(() => {
    const { data } = consultation || {}
    const { variables } = consultation?.flow || {}
    if (!data || !variables) return undefined
    return Object.fromEntries(Object.entries(variables).map(([key, rule]) => [key, apply(rule, data)]))
  }, [consultation, apply])

  const data = useMemo<Consultation['data'] | undefined>(
    () => (consultation?.data || computed ? { ...consultation?.data, ...computed, ref: consultation?.ref } : undefined),
    [consultation, computed]
  )

  useEffect(() => {
    if (consultation && reportId && !report) {
      pushError(new Error(`The report ${reportId} is missing on consultation ${consultation._id}`))
    }
  }, [report, consultation, reportId, pushError])

  useEffect(() => {
    if (initialized === undefined) {
      setInitialized(true)
    }
  }, [initialized])

  return (
    <Spin spinning={loading}>
      <CardContainer>
        <div className={css['header']}>
          <div>
            {report?.name && (
              <Heading level={2} className='mb-0'>
                {localize(report.name)}
              </Heading>
            )}
          </div>
          <div>
            {consultation && report?.access === 'internal' && (
              <span className={css['print']}>
                {t('ref.label')}: <span className={css['ref']}>{consultation.ref}</span>
              </span>
            )}
          </div>
        </div>

        {canMemoView && memo && (
          <>
            <ReportMemo memo={memo} className='mb-8' />
            {!!report?.contents.length && <Heading level={3}>{t('title')}</Heading>}
          </>
        )}

        {(report?.contents || []).map(({ _id, ...rest }, index) => (
          <ReportContent key={_id} {...rest} data={data} position={index} className='mb-6' />
        ))}
      </CardContainer>
    </Spin>
  )
}
