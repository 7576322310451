import React from 'react'
import { CardContainer, Markdown } from '../../../utils'
import { Heading } from '../../../typography'
import { privacyPolicy } from '../../../../config/legal'
// import css from './PrivacyPolicy.module.css'

export const PrivacyPolicy = (): JSX.Element => {
  return (
    <CardContainer>
      <h1 className='text-primary'>Datenschutzerklärung</h1>
      <Heading level={2} className='mb-6 mt-4 text-4xl'>
        Ihre Daten sind in guten Händen.
      </Heading>
      <p className='text-lg font-medium text-gray-600 mb-8'>
        Wir halten uns an hohe Standards und geltende Gesetze zum Schutz von Daten und Privatsphäre.
      </p>
      <Markdown>{privacyPolicy}</Markdown>
    </CardContainer>
  )
}
