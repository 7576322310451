import { useMemo } from 'react'
import { IdentityUser } from '../@types'
import { ApiRequestConfig, useApiRequest } from './ApiRequest'

interface UsersInterface {
  users: IdentityUser[]
  loading: boolean
  flush: () => void
}

export function useUsers(ids: IdentityUser['id'][], limit = 10): UsersInterface {

  const getUsers = useMemo<ApiRequestConfig>(
    () => ({
      method: 'GET',
      url: '/users',
      skip: !ids.length,
      params: {
        filter: {
          id: { $in: ids },
        },
        limit,
      },
    }),
    [ids, limit]
  )
  const { data: users, loading, flush } = useApiRequest<IdentityUser[]>(getUsers)

  return { users: users || [], loading, flush }
}
