function base64URLEncode(value: string) {
  return window.btoa(value).replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '')
}

async function computeHash(value: string): Promise<string> {
  const hash = await window.crypto.subtle.digest('SHA-256', new TextEncoder().encode(value))
  return base64URLEncode(String.fromCharCode(...new Uint8Array(hash)))
}

function randomBytes(size: number): string {
  // return new TextDecoder('utf-8').decode(crypto.getRandomValues(new Uint8Array(size)))
  return String.fromCharCode(...crypto.getRandomValues(new Uint8Array(size)))
}

export function useHelpers() {
  return { base64URLEncode, computeHash, randomBytes }
}
