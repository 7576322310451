import { Button, ButtonProps, Tooltip } from 'antd'
import React, { useEffect, useState } from 'react'
import css from './CopyButton.module.css'
import { CheckOutlined, LinkOutlined } from '@ant-design/icons'
import { colors } from '../../config'
import { useMessage } from '../../contexts'
import { useTranslation } from 'react-i18next'

interface CopyButtonProps extends ButtonProps {
  value: string
  compact?: boolean
}

export const CopyButton = (props: CopyButtonProps): JSX.Element => {
  const { value, children, compact = false, ...buttonProps } = props
  const { message } = useMessage()
  const { t } = useTranslation()

  const [copied, setCopied] = useState<boolean>(false)

  useEffect(() => {
    const timer = copied
      ? setTimeout(() => {
          setCopied(false)
        }, 2000)
      : undefined

    if (copied && compact) message.success(t('link.copied'), 2)

    return () => {
      clearTimeout(timer)
    }
  }, [copied, message, compact, t])

  const button = (
    <Button
      type='primary'
      className={css['copy']}
      style={{ backgroundColor: copied ? colors.success['DEFAULT'] : undefined, color: copied ? 'white' : undefined }}
      icon={copied ? <CheckOutlined /> : <LinkOutlined />}
      onClick={() => {
        navigator.clipboard.writeText(value)
        setCopied(true)
      }}
      {...buttonProps}
    >
      {compact ? null : copied ? <>{t('link.copied')}</> : <>{children || t('link.copy')}</>}
    </Button>
  )

  return compact ? <Tooltip title={children || t('link.copy')}>{button}</Tooltip> : button
}
