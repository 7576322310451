import {
  CheckCircleFilled,
  ClockCircleFilled,
  CloseCircleFilled,
  ExclamationCircleFilled,
  ExperimentFilled,
  QuestionCircleFilled,
} from '@ant-design/icons'
import { Tooltip } from 'antd'
import React, { ReactNode, useMemo } from 'react'
import css from './TrainingStatus.module.css'
import { Localized, Training } from '../../../@types'
import { supportedTrainingStatus, useLocalize } from '../../../hooks'

type TrainingStatus = (typeof supportedTrainingStatus)[number]

export interface TrainingStatusProps {
  className?: string
  children?: ReactNode
  status?: TrainingStatus
  assessment?: Training['assessment']
}

const infos: Record<TrainingStatus | 'missing', Localized> = {
  error: {
    'de-CH': 'Das Resultat ist fehlerhaft',
    'en-US': 'The result is incorrect',
  },
  incomplete: {
    'de-CH': 'Das Resultat weisst Mängel auf',
    'en-US': 'The result has shortcomings',
  },
  success: {
    'de-CH': 'Das Resultat ist korrekt',
    'en-US': 'The result is correct',
  },
  open: {
    'de-CH': 'Training noch nicht gestartet',
    'en-US': 'Training not yet started',
  },
  missing: {
    'de-CH': 'Der Status ist nicht verfügbar',
    'en-US': 'The status is not available',
  },
  ignore: {
    'de-CH': 'Probetraining ohne status',
    'en-US': 'Probetraining ohne status',
  },
}

export const TrainingStatus = (props: TrainingStatusProps): JSX.Element => {
  const { className, status = 'missing' } = props
  const { localize } = useLocalize()

  const statusIconMap: Record<TrainingStatus | 'missing', JSX.Element> = {
    error: <CloseCircleFilled />,
    incomplete: <ExclamationCircleFilled />,
    success: <CheckCircleFilled />,
    open: <ClockCircleFilled />,
    missing: <QuestionCircleFilled />,
    ignore: <ExperimentFilled />,
  }

  const text = useMemo(() => {
    if (infos[status]) {
      return localize(infos[status])
    }
    return status
  }, [status, localize])

  return (
    <div className={className}>
      <Tooltip title={text}>
        <div className={`${css['status']} ${css[status]}`} style={{ lineHeight: 0 }}>
          {statusIconMap[status]}
        </div>
      </Tooltip>
    </div>
  )
}
