import React, { ReactNode, useCallback, useMemo } from 'react'
import { Navigate, Route, Routes, matchPath, useLocation, useNavigate } from 'react-router-dom'
import { Protect } from '../../../../auth'
import { AdminDevice, AdminEmployees } from './routes'
import { CardContainer } from '../../../../utils'
import { Menu, Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'
import { useCan } from '../../../../../hooks'
import css from './Admin.module.css'
import { DesktopOutlined, KeyOutlined } from '@ant-design/icons'
import { MenuItemType } from 'antd/es/menu/hooks/useItems'

export const Admin = (): JSX.Element => {
  const { t } = useTranslation('admin', { keyPrefix: 'navigation' })
  const navigate = useNavigate()
  const location = useLocation()

  const buildLabel = useCallback((text: ReactNode, tooltip?: string) => {
    const label = <span className={css['label']}>{text}</span>
    return tooltip ? <Tooltip title={tooltip}>{label}</Tooltip> : label
  }, [])

  const canAdminEmployees = useCan('admin:employees')
  const canAdminDevice = useCan('admin:device')

  const items = useMemo<MenuItemType[]>(
    () =>
      [
        {
          key: '/admin/device',
          label: buildLabel(t('device.label')),
          can: canAdminDevice,
          id: 'device',
          className: css['item'],
          icon: <DesktopOutlined />,
        },
        {
          key: '/admin/employees',
          label: buildLabel(t('employees.label')),
          can: canAdminEmployees,
          id: 'employees',
          className: css['item'],
          icon: <KeyOutlined />,
        },
      ]
        .filter(({ can }) => can === undefined || can)
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .map(({ can, ...rest }) => rest),
    [canAdminDevice, canAdminEmployees, t, buildLabel]
  )

  const selectedKeys = useMemo<string[]>(() => {
    return items.map(({ key }) => key.toString()).filter((key) => !!matchPath(key, location.pathname))
  }, [location, items])

  return (
    <CardContainer id='admin'>
      {!!items.length && (
        <Menu
          className={css['menu']}
          id='admin-nav'
          selectedKeys={selectedKeys}
          items={items}
          onClick={({ key }) => {
            if (key) navigate(key)
          }}
          mode='horizontal'
        />
      )}
      <div className={css['content']}>
        <Routes>
          <Route
            path='device'
            element={
              <Protect operation='admin:device' showAuth requireUser>
                <AdminDevice />
              </Protect>
            }
          />

          <Route
            path='employees'
            element={
              <Protect operation='admin:employees' showAuth requireUser>
                <AdminEmployees />
              </Protect>
            }
          />

          <Route path='*' element={<Navigate to={'device'} />} />
        </Routes>
      </div>
    </CardContainer>
  )
}
