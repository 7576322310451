import { useEffect, useState, useMemo } from 'react'
import { ApiRequestConfig, useApiRequest } from '../../hooks'
import type { IdentityUser } from '../../@types'

interface UserContext {
  ready: boolean
}

export function useUser(context: UserContext) {
  const { ready } = context
  const [user, setUser] = useState<IdentityUser | null | undefined>(undefined)

  const getUser = useMemo<ApiRequestConfig>(
    () => ({
      method: 'GET',
      url: '/users/authenticated',
      skip: !!user || user === null || !ready,
    }),
    [user, ready]
  )
  const { data, loading, error } = useApiRequest<IdentityUser | null>(getUser)

  useEffect(() => {
    setUser(data)
  }, [data])

  return { user, loading, error }
}
