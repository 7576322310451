import { useCallback } from 'react'
import { IdentityContextTypes, useError } from '../../contexts'

export type Callback = (params: CallbackParams) => void

export interface CallbackContext {
  state: IdentityContextTypes['state']
  flush: IdentityContextTypes['flush']
  setCode: IdentityContextTypes['setCode']
}

export function useIdentityCallback(context: CallbackContext): Callback {
  const { state, flush: flushIdentity, setCode } = context
  const { pushError } = useError()

  const callback = useCallback<Callback>(
    (params) => {
      const { code, state: callbackState, userState } = params

      if (userState === 'AuthenticatedNotRegistered') {
        pushError(new Error('User is not registered for this application!'))
      }
      if (userState === 'AuthenticatedRegistrationNotVerified') {
        pushError(new Error('User registration is not verified!'))
      }
      if (state !== callbackState) {
        pushError(new Error('Missmatch authentication states!'))
      }

      if (state === callbackState && userState === 'Authenticated' && code) {
        setCode(code)
      } else {
        flushIdentity()
      }

      // TODO: clean up if logout
    },
    [state, pushError, setCode, flushIdentity]
  )

  return callback
}

interface CallbackParams {
  code?: string
  locale?: string
  state?: string
  userState?: 'Authenticated' | 'AuthenticatedNotRegistered' | 'AuthenticatedRegistrationNotVerified'
}
