import { MutableRefObject, useCallback, useEffect, useMemo, useState } from 'react'
import { useEnvironment, useError } from '../../contexts'
import type { IdentityContextTypes } from '../../contexts'
import qs from 'qs'
import { ApiRequestConfig, useRequest } from '../../hooks'
import { AxiosInstance } from 'axios'

export interface Token {
  loading: boolean
  flush: () => void
  refresh: () => void
}

export type TokenContext = Pick<
  IdentityContextTypes,
  | 'verifier'
  | 'callbackUri'
  | 'code'
  | 'ready'
  | 'setIdentity'
  | 'token'
  | 'setToken'
  | 'refreshToken'
  | 'setRefreshToken'
  | 'initByCallback'
> & { clientRef: MutableRefObject<AxiosInstance>; onFlush?: () => void }

/** contains token loading and refreshing logic */
export function useToken(context: TokenContext): Token {
  const {
    verifier,
    callbackUri,
    code,
    ready,
    clientRef,
    setIdentity,
    token,
    setToken,
    refreshToken,
    setRefreshToken: _setRefreshToken,
    initByCallback,
    onFlush,
  } = context
  const {
    environment: { APP_IDENTITY_CLIENT_ID },
  } = useEnvironment()
  const { pushError } = useError()

  // initialize refresh with true, to try refreshing if refresh token on local storage
  const [refresh, setRefresh] = useState<boolean | undefined>(true)

  // initialize refresh token from local storage
  useEffect(() => {
    if (refreshToken === undefined) {
      const storedToken = window.localStorage.getItem('rft')
      if (storedToken) {
        _setRefreshToken(storedToken)
      } else {
        _setRefreshToken(null)
      }
    }
  }, [refreshToken, _setRefreshToken])

  const setRefreshToken = useCallback(
    (token: string | null) => {
      if (token) {
        window.localStorage.setItem('rft', token)
      } else {
        window.localStorage.removeItem('rft')
      }
      _setRefreshToken(token ?? null)
    },
    [_setRefreshToken]
  )

  const storeTokens = useCallback(
    (tokens: TokenResponse) => {
      const { access_token, id_token, refresh_token, userId } = tokens
      setIdentity(id_token)
      setRefreshToken(refresh_token)
      setToken(access_token)
      setRefresh(false)
      window.localStorage.setItem('sub', userId)
    },
    [setIdentity, setRefreshToken, setToken]
  )

  // get initial tokens
  const skipGetTokens = useMemo<boolean>(
    () => !code || !ready || !APP_IDENTITY_CLIENT_ID || !callbackUri || !!refreshToken,
    [code, ready, APP_IDENTITY_CLIENT_ID, callbackUri, refreshToken]
  )
  useEffect(() => {
    if (!skipGetTokens && (!code || !verifier || !APP_IDENTITY_CLIENT_ID || !callbackUri)) {
      pushError(new Error('Can not load tokens because of missing parameters!'))
    }
  }, [skipGetTokens, code, verifier, APP_IDENTITY_CLIENT_ID, callbackUri, pushError])
  const getTokens = useMemo<ApiRequestConfig<string>>(
    () => ({
      method: 'POST',
      url: '/oauth2/token',
      skip: skipGetTokens,
      data: qs.stringify({
        grant_type: 'authorization_code',
        client_id: APP_IDENTITY_CLIENT_ID,
        code,
        code_verifier: verifier,
        redirect_uri: callbackUri,
      }),
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Accept: 'application/json',
      },
    }),
    [code, APP_IDENTITY_CLIENT_ID, verifier, callbackUri, skipGetTokens]
  )
  const {
    data: initialTokens,
    loading: getLoading,
    flush: flushGet,
  } = useRequest<TokenResponse, string>(getTokens, clientRef)
  useEffect(() => {
    if (initialTokens) {
      storeTokens(initialTokens)
    }
  }, [storeTokens, initialTokens])

  // refresh tokens
  const skipUpdateTokens = useMemo<boolean>(
    () => !refresh || !refreshToken || !ready || !APP_IDENTITY_CLIENT_ID || !callbackUri,
    [refresh, refreshToken, ready, APP_IDENTITY_CLIENT_ID, callbackUri]
  )
  useEffect(() => {
    if (!skipUpdateTokens && (!refreshToken || !APP_IDENTITY_CLIENT_ID || !callbackUri)) {
      pushError(new Error('Can not refresh tokens because of missing parameters!'))
    }
  }, [skipUpdateTokens, refreshToken, APP_IDENTITY_CLIENT_ID, callbackUri, pushError])
  const updateTokens = useMemo<ApiRequestConfig<string>>(
    () => ({
      method: 'POST',
      url: '/oauth2/token',
      skip: skipUpdateTokens,
      data: qs.stringify({
        grant_type: 'refresh_token',
        client_id: APP_IDENTITY_CLIENT_ID,
        refresh_token: refreshToken,
        redirect_uri: callbackUri,
      }),
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Accept: 'application/json',
      },
    }),
    [refreshToken, APP_IDENTITY_CLIENT_ID, callbackUri, skipUpdateTokens]
  )
  const {
    data: refreshedTokens,
    loading: updateLoading,
    flush: flushUpdate,
  } = useRequest<TokenResponse, string>(updateTokens, clientRef)
  useEffect(() => {
    if (refreshedTokens) {
      storeTokens(refreshedTokens)
    }
  }, [storeTokens, refreshedTokens])

  const loading = useMemo<boolean>(() => getLoading || updateLoading, [getLoading, updateLoading])

  const flush = useCallback(() => {
    onFlush && onFlush()
    flushGet()
    flushUpdate()
    setRefreshToken(null)
  }, [flushGet, flushUpdate, setRefreshToken, onFlush])

  const onRefresh = useCallback(() => {
    setToken(undefined)
    setRefresh(true)
  }, [setToken])

  useEffect(() => {
    // console.log('-----------------------')
    // console.log('refresh: ', refresh)
    // console.log('token: ', token)
    // console.log('refreshToken: ', refreshToken)
    // console.log('ready: ', ready)
    // console.log('initByCallback: ', initByCallback)
    if (ready) {
      if (token === undefined && !refresh && refreshToken === null && !initByCallback) {
        // if token not loaded or refreshed
        // console.log('------------set token null-----------')
        setToken(null)
      }
      if (token === undefined && refresh && refreshToken === null && !initByCallback) {
        // if no refresh token on local storage found and no callback
        // console.log('------------set refresh false (no rft)---------')
        setRefresh(false)
      }
      if (initByCallback && refresh) {
        // if callback
        // console.log('---------set refresh false (initByCallback)----------')
        setRefresh(false)
      }
    }
  }, [refresh, token, refreshToken, ready, setToken, initByCallback])

  const value = useMemo<Token>(
    () => ({
      loading,
      flush,
      refresh: onRefresh,
    }),
    [loading, flush, onRefresh]
  )

  return value
}

interface TokenResponse {
  access_token: string
  expires_in: number
  id_token: string
  refresh_token: string
  refresh_token_id: string
  token_type: string
  userId: string
}
