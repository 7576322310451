import React, { useMemo } from 'react'
import dayjs from 'dayjs'
import { Consultation, Employee, IdentityUser } from '../../../@types'
import css from './ConsultationCard.module.css'
import { Card, CopyButton } from '../../utils'
import { CommentOutlined, ExperimentOutlined, ReadOutlined, TagOutlined, UserOutlined } from '@ant-design/icons'
import { ReportSelect } from './ReportSelect'
import { ConsultationEdit } from './ConsultationEdit'
import { useCan, useLocalize } from '../../../hooks'
import { useTranslation } from 'react-i18next'
import { useTrial } from '../../../contexts'

interface ConsultationCardProps {
  className?: string
  consultation: Consultation
  users?: IdentityUser[]
  employees?: Employee[]
}

export const ConsultationCard = (props: ConsultationCardProps): JSX.Element => {
  const { className, consultation, users, employees } = props
  const {
    _id: consultationId,
    updatedAt,
    createdAt,
    flow: { displayName, reports },
    training,
    createdBy,
    updatedBy,
    expiresAt,
    ref,
  } = consultation
  const { trial } = useTrial()
  const canConsultationReedit = useCan('consultation:reedit', { document: consultation, trial })
  const { localize } = useLocalize()
  const {
    i18n: { language },
    t,
  } = useTranslation('consultations', { keyPrefix: 'card' })

  const editor = useMemo(
    () =>
      users?.find(({ id }) => id === updatedBy)?.fullName ||
      users?.find(({ id }) => id === createdBy)?.fullName ||
      employees?.find(({ _id }) => _id === updatedBy)?.name ||
      employees?.find(({ _id }) => _id === createdBy)?.name,
    [users, employees, createdBy, updatedBy]
  )

  return (
    <Card id='consultation-card' className={className ? `${className} ${css['card']}` : css['card']}>
      <div className={css['content']}>
        <div className={css['ref']} id='reference'>
          <div>
            <TagOutlined /> {t('reference')}
          </div>
          <div className={css['link']}>
            <div className={css['code']}>{ref}</div>
            <CopyButton
              className={css['copy']}
              value={`${window.location.origin}/consultations?ref=${ref}`}
              type='default'
              compact
            />
          </div>
        </div>
        <div className={css['process']}>
          {training ? (
            <div>
              <ReadOutlined /> {t('training')}
            </div>
          ) : expiresAt ? (
            <div>
              <ExperimentOutlined /> {t('trial')}
            </div>
          ) : (
            <div>
              <CommentOutlined /> {t('consultation')}
            </div>
          )}
          <div className={css['flow']}>{localize(displayName)}</div>
          {expiresAt && (
            <div className={css['expires']}>
              {t('expiresAt')}: {dayjs(expiresAt).locale(language).fromNow()}
            </div>
          )}
        </div>

        <div className={css['meta']}>
          {editor && (
            <div>
              <UserOutlined /> {editor}
            </div>
          )}
          {createdAt && (
            <div className={css['date']}>
              {t('createdAt')}: {dayjs(createdAt).locale(language).fromNow()}
            </div>
          )}
          {updatedAt && (
            <div className={css['date']}>
              {t('updatedAt')}: {dayjs(updatedAt).locale(language).fromNow()}
            </div>
          )}
        </div>
      </div>

      <div className={css['actions']}>
        {canConsultationReedit && <ConsultationEdit consultation={consultationId} />}
        <ReportSelect reports={training ? training.reports : reports} consultation={consultationId} />
      </div>
    </Card>
  )
}
