import { useMemo } from 'react'
import { Employee } from '../@types'
import { ApiRequestConfig, useApiRequest } from './ApiRequest'

interface EmployeesInterface {
  employees: Employee[]
  loading: boolean
}

export function useEmployees(ids: Employee['_id'][], limit = 10): EmployeesInterface {
  const getEmployees = useMemo<ApiRequestConfig>(
    () => ({
      method: 'GET',
      url: '/employees',
      skip: !ids.length,
      params: {
        filter: {
          _id: { $in: ids },
        },
        limit,
      },
    }),
    [ids, limit]
  )
  const { data: employees, loading } = useApiRequest<Employee[]>(getEmployees)

  return { employees: employees || [], loading }
}
