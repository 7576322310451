import React, { useMemo, useRef } from 'react'
// import css from './QRCode.module.css'
import { useDebounce, useSize } from '../../hooks'
import { QRCode as AntQRCode, QRCodeProps as AntQRCodeProps } from 'antd'

interface QRCodeProps {
  className?: string
  value: string
  status?: AntQRCodeProps['status']
}

export const QRCode = (props: QRCodeProps): JSX.Element => {
  const { className, value, status } = props
  const ref = useRef<HTMLDivElement | null>(null)
  const { width } = useDebounce(useSize(ref))

  const flooredWith = useMemo(() => Math.floor(width), [width])

  return (
    <div className={className} ref={ref}>
      <AntQRCode value={value} size={flooredWith} status={status} />
    </div>
  )
}
