/* eslint-disable no-useless-escape */
import React, { useMemo } from 'react'
import { CreateUpdateModal } from '../../../admin/CreateUpdateModal'
import { Employee, EmployeeEmail } from '../../../../@types'
import { useTranslation } from 'react-i18next'
import { useUsers } from '../../../../hooks'
import { useAuth, useEnvironment } from '../../../../contexts'
// import css from './EditEmployee.module.css'

interface EditEmployeeProps {
  show?: boolean
  employee?: Employee | null
  onClose?: (
    e?: React.MouseEvent,
    triggerRefresh?: boolean,
    context?: { document?: Employee } & Record<string, unknown>
  ) => void
}

export const EditEmployee = (props: EditEmployeeProps): JSX.Element => {
  const { employee, show = false, onClose } = props
  const { t } = useTranslation('admin', { keyPrefix: 'employees' })
  const { user: authenticatedUser } = useAuth()
  const {
    environment: { APP_IDENTITY_CLIENT_ID },
  } = useEnvironment()

  const userIds = useMemo(() => (employee?.user && show ? [employee.user] : []), [employee, show])
  const { users, loading: usersLoading, flush: flushUsers } = useUsers(userIds)

  const modalProps = useMemo(
    () => ({
      open: show && !!employee,
      id: (show && employee?._id) || null,
      context: { email: users[0]?.email },
    }),
    [show, employee, users]
  )

  const notVerified = useMemo<boolean>(
    () =>
      users[0] &&
      !users[0].registrations?.find(({ applicationId }) => applicationId === APP_IDENTITY_CLIENT_ID)?.verified,
    [users, APP_IDENTITY_CLIENT_ID]
  )

  return (
    <CreateUpdateModal<Employee & EmployeeEmail>
      {...modalProps}
      loading={usersLoading}
      modelName='employees'
      fields={[
        { name: 'name', layout: { required: true } },
        {
          name: 'pin',
          input: {
            type: 'password',
            maxLength: 6,
            // prettier-ignore
            pattern: '^\d{0,6}$',

            onInput: (e) => {
              // only process digits
              if (!/^\d{0,6}$/.test(e.currentTarget.value)) e.currentTarget.value = e.currentTarget.value.slice(0, -1)
            },
          },
          layout: { description: t('pinDescription'), required: !modalProps.id },
        },
        {
          name: 'email',
          nullable: true,
          layout: {
            description: notVerified ? <span className='text-warning'>{t('notVerified')}</span> : t('emailDescription'),
          },
          input: {
            disabled: !!authenticatedUser || notVerified,
          },
        },
      ]}
      onClose={(...args) => {
        flushUsers()
        onClose && onClose(...args)
      }}
    />
  )
}
