import { useTranslation } from 'react-i18next'
import { Locale, Localized } from '../@types'
import { useCallback } from 'react'

export interface Localize {
  localize: (content?: string | Localized) => string | undefined
}

export function useLocalize(): Localize {
  const {
    i18n: { language },
  } = useTranslation()

  const localize = useCallback<Localize['localize']>(
    (content) => {
      if (!content) return undefined
      if (typeof content === 'string') return content
      return content[language as Locale] || `⚠️ content translation ${language} missing ⚠️`
    },
    [language]
  )

  return { localize }
}
