import React from 'react'
import { LightboxImage } from '../../LightboxImage'
import type { ExtraProps } from 'react-markdown'
// import css from './Img.module.css'

export const Img = (
  markdownProps: React.ClassAttributes<HTMLImageElement> & React.ImgHTMLAttributes<HTMLImageElement> & ExtraProps
): JSX.Element => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { node, ...props } = markdownProps

  return <LightboxImage {...props} measureWidth />
}
