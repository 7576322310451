import { FullscreenOutlined, QuestionOutlined } from '@ant-design/icons'
import { Button, Tooltip } from 'antd'
import React, { useEffect, useMemo } from 'react'
import { useAuth, useEnvironment, useIntro } from '../../../contexts'
import { useTranslation } from 'react-i18next'
import { startSteps, endSteps, employeeSteps, managerSteps } from '../../../config'
import { useCan } from '../../../hooks'
// import css from './IntroTrigger.module.css'

interface IntroTriggerProps {
  className?: string
}

const ONE_WEEK = 7 * 24 * 60 * 60 * 1000

export const IntroTrigger = (props: IntroTriggerProps): JSX.Element => {
  const { className } = props
  const { play, active, paused, setIntro, hasSteps } = useIntro()
  const { t } = useTranslation()
  const { user, employee } = useAuth()
  const {
    environment: { APP_IDENTITY_CLIENT_ID },
  } = useEnvironment()

  const canEmployeeIntro = useCan('intro:employee')
  const canManagerIntro = useCan('intro:manager')

  const canIntroPlay = useMemo(() => canEmployeeIntro || canManagerIntro, [canEmployeeIntro, canManagerIntro])

  useEffect(() => {
    if (canIntroPlay) {
      const intro = [...startSteps]
      if (canEmployeeIntro) intro.push(...employeeSteps)
      if (canManagerIntro) intro.push(...managerSteps)
      intro.push(...endSteps)
      setIntro(intro)
    }
  }, [canIntroPlay, canEmployeeIntro, canManagerIntro, setIntro])

  const isNewbie = useMemo<boolean>(() => {
    const now = Date.now()
    if (user) {
      const added = user.registrations?.find(
        ({ applicationId }) => applicationId === APP_IDENTITY_CLIENT_ID
      )?.insertInstant
      if (added === undefined) return false

      return now - added < ONE_WEEK
    }

    if (employee) {
      const added = employee.createdAt && new Date(employee.createdAt).valueOf()
      if (added === undefined) return false

      return now - added < ONE_WEEK
    }
    return false
  }, [user, employee, APP_IDENTITY_CLIENT_ID])

  useEffect(() => {
    if (isNewbie && !active && !window.sessionStorage.getItem('intro')) {
      play()
    }
  }, [isNewbie, active, play])

  return (
    <>
      {hasSteps && canIntroPlay && (
        <Tooltip
          title={paused ? t('navigation.header.help.paused') : t('navigation.header.help.start')}
          open={paused || undefined}
          placement='left'
        >
          <Button
            id='help'
            className={className}
            type={active ? 'primary' : 'default'}
            shape='circle'
            icon={paused ? <FullscreenOutlined /> : <QuestionOutlined />}
            onClick={() => {
              play()
            }}
          />
        </Tooltip>
      )}
    </>
  )
}
