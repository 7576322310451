import React, { useEffect } from 'react'
import { Button } from 'antd'
import { Link, useNavigate } from 'react-router-dom'
import { InfoCircleOutlined, SafetyCertificateOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { use100vh } from 'react-div-100vh'
import { useBasicLayout } from '../../../layouts'

import css from './PharMe.module.css'
import { useAuth } from '../../../../contexts'

export const PharMe = (): JSX.Element => {
  const { t } = useTranslation()
  const screenHeight = use100vh()
  const { FOOTER_HEIGHT, HEADER_HEIGHT } = useBasicLayout()
  const { device, employee, user } = useAuth()
  const navigate = useNavigate()

  useEffect(() => {
    if (device !== undefined && employee !== undefined && user !== undefined) {
      if (device && !employee && !user) {
        navigate('/consultation/takeover')
      }
    }
  })

  return (
    <div
      className={css['container']}
      style={{ minHeight: `calc(${screenHeight}px - ${HEADER_HEIGHT}px - ${FOOTER_HEIGHT}px)` }}
    >
      <h1 className={css['heading']}>
        <div className={css['logo']}>
          <span className='text-dark'>phar</span>
          <span className='text-primary'>Me</span>
          <span className='text-dark'>.ch</span>
        </div>
      </h1>

      <div className={css['slogan']}>{t('slogan')}</div>

      <div className={css['legal-nav']}>
        <div>
          <Link to='/privacy-policy'>
            <Button type='link' icon={<SafetyCertificateOutlined />}>
              {t('privacy')}
            </Button>
          </Link>
        </div>
        <div>
          <Link to='/legal-notice'>
            <Button type='link' icon={<InfoCircleOutlined />}>
              {t('notice')}
            </Button>
          </Link>
        </div>
      </div>
    </div>
  )
}
