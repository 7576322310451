import React, { createContext, useState, useMemo, useContext, useEffect, PropsWithChildren, useCallback } from 'react'

import { useMessage } from './MessageContext'
import { useTranslation } from 'react-i18next'
import { Button } from 'antd'
import { useBreakpoints, useQuery } from '../hooks'
import css from './TrialContext.module.css'

interface TrialContextInterface {
  trial: boolean | undefined
  setTrial: React.Dispatch<React.SetStateAction<boolean | undefined>>
}

const TrialContext = createContext<TrialContextInterface | undefined>(undefined)

const TrialContextProvider: React.FC<PropsWithChildren> = (props) => {
  const { children } = props
  const { notification } = useMessage()
  const [trial, setTrial] = useState<boolean | undefined>(undefined)
  const { t } = useTranslation('common', { keyPrefix: 'trial' })
  const { ltSM } = useBreakpoints()

  const [params, setParams] = useQuery()

  const urlTrial = useMemo(() => !!params['trial'], [params])

  const setUrlTrial = useCallback(
    (value: boolean) => {
      if (value) {
        setParams((oldParams) => {
          return { ...oldParams, trial: 'true' }
        })
      } else {
        setParams((oldParams) => {
          return { ...oldParams, trial: undefined }
        })
      }
    },
    [setParams]
  )

  const value = useMemo<TrialContextInterface>(
    () => ({
      trial,
      setTrial,
    }),
    [trial, setTrial]
  )

  useEffect(() => {
    if (trial === undefined) {
      setTrial(urlTrial)
    }
  }, [trial, urlTrial])

  useEffect(() => {
    if (urlTrial !== trial && trial !== undefined) {
      setUrlTrial(trial)
    }
  }, [urlTrial, trial, setUrlTrial])

  useEffect(() => {
    if (trial !== undefined) {
      if (trial) {
        notification.warning({
          key: 'trial',
          className: css['notification'],
          message: <span className={css['title']}>{t('title')}</span>,
          description: ltSM ? undefined : <span className={css['description']}>{t('description')}</span>,
          duration: 0,
          placement: ltSM ? 'top' : 'bottom',
          onClose: () => {
            setTrial(false)
          },
          closeIcon: ltSM ? undefined : null,
          btn: ltSM ? undefined : (
            <Button
              title={t('end')}
              onClick={() => {
                notification.destroy('trial')
              }}
            >
              {' '}
              {t('end')}
            </Button>
          ),
        })
      } else {
        notification.destroy('trial')
      }
    }
  }, [trial, notification, setTrial, t, ltSM])

  return <TrialContext.Provider value={value}>{children}</TrialContext.Provider>
}

const useTrial = (): TrialContextInterface => {
  const context = useContext(TrialContext)
  if (!context) {
    throw new Error('useTrial must be inside a Provider with a value')
  }
  return context
}

export { TrialContextProvider, useTrial }
