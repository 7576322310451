import React, { ReactNode, useMemo } from 'react'
import css from './InputLayout.module.css'
import { Description, Label } from '../../../typography'
import { LoadingOutlined } from '@ant-design/icons'

interface InputLayoutProps {
  className?: string
  children?: ReactNode
  label?: ReactNode
  description?: ReactNode
  validation?: ReactNode
  status?: 'warning' | 'error'
  required?: boolean
  loading?: boolean
}

export const InputLayout: React.FC<InputLayoutProps> = (props) => {
  const { className, children, label, description, status, validation, required = false, loading = false } = props

  const validationClassName = useMemo(() => {
    const classNames: string[] = []
    if (status === 'warning') classNames.push('text-warning')
    if (status === 'error') classNames.push('text-error')
    return classNames.join(' ')
  }, [status])

  return (
    <div className={className}>
      <div className={css['input-layout']}>
        {label && <Label required={required}>{label}</Label>}
        {validation && <Description className={validationClassName}>{validation}</Description>}
        {description && !validation && <Description>{description}</Description>}
        <div className={css['input']}>
          <LoadingOutlined className={loading ? undefined : 'invisible' } />
          {children}
          <LoadingOutlined className='invisible' />
        </div>
      </div>
    </div>
  )
}
