import { Button } from 'antd'
import React, { useMemo } from 'react'
import { Flow } from '../../../@types'
import { Heading } from '../../typography'
import { Link } from 'react-router-dom'
import { Card, CopyButton, QRCode } from '../../utils'
import { PlusCircleOutlined } from '@ant-design/icons'
import { useBreakpoints, useLocalize } from '../../../hooks'
import css from './FlowCard.module.css'
import { useTrial } from '../../../contexts'
import { useTranslation } from 'react-i18next'

interface FlowCardProps {
  className?: string
  flow: Flow
}

export const FlowCard = (props: FlowCardProps): JSX.Element => {
  const {
    className,
    flow: { _id, displayName },
  } = props
  const { ltSM } = useBreakpoints()
  const { localize } = useLocalize()
  const { t } = useTranslation('flows')
  const { trial } = useTrial()

  const url = useMemo(
    () => `${window.location.origin}/consultation?flow=${_id}${trial ? '&trial=true' : ''}`,
    [_id, trial]
  )

  const compact = useMemo<boolean>(() => ltSM, [ltSM])

  return (
    <Card className={className}>
      <div className={css['content']}>
        <div>
          <Heading level={4}>{localize(displayName)}</Heading>
          {/* <pre className={css['flow-id']}>{_id}</pre> */}
        </div>
        <QRCode value={url} className={css['qrcode']} />
      </div>
      <div className={css['actions']}>
        <CopyButton type='default' value={url} compact={compact} />
        <Link to={`/consultation?flow=${_id}${trial ? '&trial=true' : ''}`}>
          <Button type='primary' icon={<PlusCircleOutlined />}>
            {t('new')}
          </Button>
        </Link>
      </div>
    </Card>
  )
}
