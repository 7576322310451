import React, {
  createContext,
  useMemo,
  useContext,
  PropsWithChildren,
  useEffect,
  useCallback,
  useReducer,
  useState,
} from 'react'
import type { Consultation, ConsultationCreate, ConsultationUpdate, Flow } from '../@types'
import { useApiRequest, ApiRequestConfig, useQuery, useJsonLogic, useKey, KeyConfig, useDebounce } from '../hooks'
import { useMessage } from './MessageContext'
import { useTraining } from './TrainingContext'
import { useError } from './ErrorContext'
import { useTranslation } from 'react-i18next'
import { useTrial } from './TrialContext'

const ONE_HOUR = 60 * 60 * 1000
export interface ConsultationContextInterface {
  /** the consultation */
  consultation?: Omit<Consultation, 'flow'> | null
  /** the consultation meta data only */
  consultationMeta?: ConsultationMeta
  flow?: Flow
  /** indicates if there is an active consultation */
  hasConsultation: boolean | undefined
  /**
   * move the consultation forward or backward with `direction`
   *
   * move the consultation to a step with `toStep`
   *
   * move to beginning with `reset: true`
   */
  move: React.Dispatch<{ direction?: 'forward' | 'backward'; toStep?: string; reset?: boolean }>
  /** contains the indexes of the currently active steps */
  active: number[]
  /** contains the indexes of the required steps */
  required: number[]
  /** contains the indices of the visible steps */
  visible: number[]
  /** the total number of steps */
  totalSteps: number
  /** the data (state) of a consultation */
  data: Consultation['data'] | undefined
  /** update the data (state) of a consultation */
  update: (update: { memo?: Consultation['memo']; data?: Partial<Consultation['data']> }) => void
  /** flush all consultation data from context state */
  flush: (code?: string) => void
  /** holds the ref of the closing consultation (flushing) and/or the takeover code (takeover) */
  flushing: { ref?: string; code?: string; isTraining: boolean } | null
  /**
   * executes validation of steps of current position
   * @returns object with position index and error message of invalid steps
   */
  validate: () => { [step: number]: string }
  /** contains validation messages for active steps */
  validation: { [step: number]: string }
  /** indicates if consultation can move forward */
  canForward: boolean
  /** indicates if consultation can move backward */
  canBackward: boolean
  /** indicates if the consultation can be closed (delete session) */
  closable: boolean
  /** indicates if the consultation can be shared (handover) */
  shareable: boolean
  /** indicates if any api requests are pending */
  loading: boolean
  /** indicates if any initialization api requests are pending */
  initLoading: boolean
}

type ConsultationMeta = Omit<Consultation, 'data' | 'flow' | 'training' | 'cursor' | 'memo'>

const ConsultationContext = createContext<ConsultationContextInterface | undefined>(undefined)

const ConsultationContextProvider: React.FC<PropsWithChildren> = (props) => {
  const { children } = props
  const { training, onCreated } = useTraining()
  const { pushError } = useError()
  const [params, setParams] = useQuery()
  const { apply, mapData } = useJsonLogic()
  const [consultation, setConsultation] = useState<Omit<Consultation, 'flow'> | null | undefined>(undefined)
  const [syncConsultation, setSyncConsultation] = useState<Partial<Pick<Consultation, 'data' | 'memo'>> | null>(null)
  const { t } = useTranslation('consultation')
  const { trial } = useTrial()

  const hasConsultation = useMemo<ConsultationContextInterface['hasConsultation']>(
    () => (consultation === undefined ? undefined : consultation !== null),
    [consultation]
  )

  const consultationMeta = useMemo<ConsultationMeta | undefined>(() => {
    if (!consultation) return undefined
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { data, training, cursor, memo, ...meta } = consultation
    return meta
  }, [consultation])

  const { message } = useMessage()

  // start with initialized undefined to avoid double fetching in react strict mode
  const [initialized, setInitialized] = useState<boolean | undefined>(undefined)
  const [loaded, setLoaded] = useState<boolean | undefined>(undefined)
  const [created, setCreated] = useState<boolean | undefined>(undefined)
  const [flushing, setFlushing] = useState<ConsultationContextInterface['flushing']>(null)

  const flowId = useMemo(() => training?.flow || params.flow, [params.flow, training?.flow])

  // try to load consultation from current session
  const getCurrentConsultation = useMemo<ApiRequestConfig>(
    () => ({ method: 'GET', url: '/consultations/current', skip: initialized || initialized === undefined }),
    [initialized]
  )
  const {
    data: currentConsultation,
    loading: getCurrentConsultationLoading,
    flush: flushCurrentConsultation,
  } = useApiRequest<Consultation | null>(getCurrentConsultation)

  useEffect(() => {
    if (currentConsultation || (currentConsultation === null && !flowId && training !== undefined)) {
      setLoaded(true)
      setCreated(false)
    }
  }, [currentConsultation, flowId, training])

  // create new consultation if no current
  const createConsultation = useMemo<ApiRequestConfig<ConsultationCreate>>(
    () => ({
      method: 'POST',
      url: '/consultations',
      skip:
        !flowId ||
        typeof flowId !== 'string' ||
        currentConsultation !== null ||
        training === undefined ||
        hasConsultation,
      data: {
        flow: flowId as string,
        data: training?.initial,
        cursor: training?.cursor,
        training: training ?? undefined,
      },
    }),
    [flowId, currentConsultation, training, hasConsultation]
  )
  const {
    data: newConsultation,
    loading: createConsultationLoading,
    flush: flushNewConsultation,
  } = useApiRequest<Consultation, Partial<ConsultationCreate>>(createConsultation)

  useEffect(() => {
    if (createConsultation) {
      setCreated(true)
    }
  }, [createConsultation])

  // remove flowId from url params after new consultation is created
  useEffect(() => {
    if ((newConsultation || currentConsultation) && flowId) {
      if (params.flow) {
        setParams((oldParams) => {
          return { ...oldParams, flow: undefined }
        })
      }
      // tell training context that a flow was laoded or created
      onCreated()
    }
  }, [newConsultation, currentConsultation, flowId, setParams, onCreated, params.flow])

  // set inizialized to true, when current loaded
  useEffect(() => {
    if (currentConsultation || currentConsultation === null) {
      setInitialized(true)
    }
  }, [currentConsultation, newConsultation, training])

  /** consultation flow either from current or newly created consultation */
  const flow = useMemo<Flow | undefined>(() => {
    if (currentConsultation) return currentConsultation.flow
    if (newConsultation) return newConsultation.flow
    return undefined
  }, [newConsultation, currentConsultation])

  const computed = useMemo<Consultation['data'] | undefined>(() => {
    const { data } = consultation || {}
    const { variables } = flow || {}
    if (!data || !variables) return undefined
    return Object.fromEntries(Object.entries(variables).map(([key, rule]) => [key, apply(rule, data)]))
  }, [consultation, flow, apply])

  const data = useMemo<Consultation['data'] | undefined>(
    () => (consultation?.data || computed ? { ...consultation?.data, ...computed, ref: consultation?.ref } : undefined),
    [consultation, computed]
  )

  const totalSteps = useMemo(() => flow?.steps.length || 0, [flow?.steps])

  const groupedSteps = useMemo<number[][]>(
    () =>
      (flow?.steps || []).reduce<number[][]>((groups, { linkPrevious }, index) => {
        if (index === 0 || !linkPrevious) {
          groups.push([index])
        } else {
          groups[groups.length - 1].push(index)
        }
        return groups
      }, []),
    [flow]
  )

  /** boolean flag for every step, if visible or not (true) */
  const stepsVisible: boolean[] = useMemo(
    () =>
      flow?.steps.map(({ rule, nameMap }) => {
        // if no rule, visible true,
        if (rule === null) return true

        // if rule, evaluate rule
        return apply(rule, mapData(data ?? {}, nameMap))
      }) || [],
    [data, flow, mapData, apply]
  )

  /** contains a visibility flag for each step group (`groupedSteps`) */
  const groupVisible = useMemo(() => {
    return groupedSteps.map((group) => {
      // if at least one step visible, groupVisible = true
      const groupVisible = group.some((index) => {
        // step visibility
        return stepsVisible[index]
      })

      return groupVisible
    })
  }, [groupedSteps, stepsVisible])

  /** contains the indices of the visible steps */
  const visible = useMemo<number[]>(() => {
    return stepsVisible.reduce((visible: number[], stepFlag, index) => {
      if (stepFlag) return [...visible, index]
      return [...visible]
    }, [])
  }, [stepsVisible])

  const mover = useCallback(
    (position: number, command: { direction?: 'forward' | 'backward'; toStep?: string; reset?: boolean }): number => {
      const { direction, toStep, reset } = command
      if (reset) {
        return 0
      } else if (toStep) {
        const stepNames = (flow?.steps ?? []).map(({ name }) => name)
        const stepIndex = stepNames.findIndex((name) => name === toStep)
        if (stepIndex === -1) {
          pushError(new Error(`Unknown step name ${toStep}`))
          return 0
        }
        const groupIndex = groupedSteps.findIndex((group) => group.includes(stepIndex))
        if (groupIndex === -1) {
          pushError(new Error(`Step with index ${stepIndex} is in no step group`))
          return 0
        }
        return groupIndex
      } else if (direction === 'forward') {
        const next = Math.min(position + 1, (groupedSteps.length || 1) - 1)
        if (groupVisible[next]) return next
        return mover(next, { direction: 'forward' })
      } else if (direction === 'backward') {
        const next = Math.max(position - 1, 0)
        if (groupVisible[next]) return next
        return mover(next, { direction: 'backward' })
      } else {
        throw new Error(`Can not move ${direction}`)
      }
    },
    [groupedSteps, groupVisible, flow, pushError]
  )
  const [position, move] = useReducer(mover, 0)

  const active = useMemo(() => groupedSteps[position] || [], [groupedSteps, position])

  /** executes validation on active steps and @returns the result */
  const validate = useCallback<ConsultationContextInterface['validate']>(() => {
    /** steps which are active and can be validated */
    const targetSteps =
      flow?.steps
        .map((step, index) => ({ ...step, position: index }))
        .filter(
          ({ component }, index) => active.includes(index) && typeof component != 'string' && !!component.verify
        ) || []

    return Object.fromEntries(
      targetSteps.map(({ component, position, nameMap }) => {
        // this check should never apply
        if (typeof component === 'string' || !component.verify) return []
        const { verify } = component
        return [position, apply(verify, { ...mapData(data || {}, nameMap) }) || undefined]
      })
    )
  }, [data, apply, mapData, flow, active])

  const validation = useMemo(() => validate(), [validate])

  const required = useMemo<number[]>(() => {
    /** steps which can be validated */
    const targetSteps =
      flow?.steps
        .map((step, index) => ({ ...step, position: index }))
        .filter(({ component }) => typeof component != 'string' && !!component.verify) || []

    return targetSteps
      .filter(({ component }) => {
        // this check should never apply
        if (typeof component === 'string' || !component.verify) {
          throw new Error('Component is missing or has no validation logic.')
        }
        const { verify } = component
        return !!apply(verify, {})
      })
      .map(({ position }) => position)
  }, [apply, flow?.steps])

  // only update data, when the second input collected data
  const postponeSync = useMemo<boolean>(
    () => (consultation?.data && Object.keys(consultation?.data).length < 2) || !flow,
    [flow, consultation]
  )

  const updateConsultation = useDebounce(
    useMemo<ApiRequestConfig<ConsultationUpdate>>(() => {
      return {
        method: 'PATCH',
        url: `/consultations/${consultationMeta?._id}`,
        skip: postponeSync || !syncConsultation || !consultationMeta,
        data: {
          ...syncConsultation,
          __v: consultationMeta?.__v,
          cursor: flow?.steps[active[active.length - 1]].name,
          // this makes the consultation persistent (disable auto delete)
          expiresAt: trial ? new Date(Date.now() + ONE_HOUR) : null,
        },
      }
    }, [syncConsultation, postponeSync, consultationMeta, flow, active, trial]),
    250
  )
  const {
    data: updatedConsultation,
    loading: updateConsultationLoading,
    flush: flushConsultation,
  } = useApiRequest<Consultation, Partial<ConsultationUpdate>>(updateConsultation)

  useEffect(() => {
    if (initialized !== undefined && loaded !== undefined && created !== undefined) {
      if (currentConsultation && !updatedConsultation) {
        // initialize with data from currentConsultation but without flow
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { cursor, flow, ...rest } = currentConsultation
        setConsultation({ cursor, ...rest })
        if (cursor) move({ toStep: cursor })
        message.success(t('loaded'))
      } else if (newConsultation && !updatedConsultation) {
        // initialize with data from newConsultation but without flow
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { flow, cursor, ...rest } = newConsultation
        setConsultation({ cursor, ...rest })
        if (cursor) move({ toStep: cursor })
        message.success(t('created'))
      } else if (updatedConsultation) {
        // update with data after update
        setSyncConsultation(null)
        setConsultation(updatedConsultation)
      } else if (!updatedConsultation && currentConsultation === null && !newConsultation) {
        // flush everything
        setSyncConsultation(null)
        setConsultation(null)
        move({ reset: true })
      }
    }
  }, [updatedConsultation, currentConsultation, newConsultation, message, created, loaded, initialized, t])

  const initLoading = useMemo<boolean>(
    () => getCurrentConsultationLoading || createConsultationLoading,
    [getCurrentConsultationLoading, createConsultationLoading]
  )

  const loading = useMemo<boolean>(
    () => updateConsultationLoading || !!syncConsultation || createConsultationLoading || getCurrentConsultationLoading,
    [updateConsultationLoading, createConsultationLoading, getCurrentConsultationLoading, syncConsultation]
  )

  const canForward = useMemo<boolean>(
    () =>
      (!Object.values(validation).length || Object.values(validation).every((val) => val === undefined)) &&
      !active.includes(visible[visible.length - 1]) &&
      !loading,
    [validation, visible, active, loading]
  )

  const canBackward = useMemo<boolean>(() => !active.includes(0) && !loading, [active, loading])

  const downKeyConfig = useMemo<KeyConfig>(
    () => ({
      key: 'ArrowDown',
      onKeyup: canForward
        ? () => {
            move({ direction: 'forward' })
          }
        : undefined,
    }),
    [move, canForward]
  )
  useKey(downKeyConfig)

  const upKeyConfig = useMemo<KeyConfig>(
    () => ({
      key: 'ArrowUp',
      onKeyup: canBackward
        ? () => {
            move({ direction: 'backward' })
          }
        : undefined,
    }),
    [move, canBackward]
  )
  useKey(upKeyConfig)

  const closable = useMemo<boolean>(() => !loading && !!consultationMeta, [loading, consultationMeta])

  const shareable = useMemo<boolean>(
    () => !!consultationMeta && (consultationMeta.expiresAt === null || !!trial) && !loading,
    [consultationMeta, loading, trial]
  )

  const update = useCallback(
    (update: { memo?: Consultation['memo']; data?: Partial<Consultation['data']> }) => {
      const { memo, data } = update

      if (!consultation) throw new Error('Can not update undefined consultation.')

      if (!loading) {
        setConsultation({
          ...consultation,
          data: { ...consultation?.data, ...data },
          memo: memo || consultation.memo || null,
        })
        if (!postponeSync) {
          setSyncConsultation({
            data: { ...consultation?.data, ...data },
            memo: memo || consultation.memo || null,
          })
        }
      }
    },
    [consultation, postponeSync, loading]
  )

  // reset data of steps that become invisible
  useEffect(() => {
    if (consultation?.data && flow?.steps.length && !loading) {
      const reset: Record<string, undefined> = {}

      stepsVisible.forEach((visible, index) => {
        const step = flow.steps[index]
        if (!step) throw new Error(`Step ${index} is missing!`)
        const { name } = step
        if (!visible && consultation.data[name] !== undefined) {
          reset[name] = undefined
        }
      })

      if (Object.keys(reset).length) {
        update({ data: reset })
      }
    }
  }, [consultation?.data, flow?.steps, update, stepsVisible, loading])

  // trigger an update when trial changes to make sure expiresAt is set accordingly
  useEffect(() => {
    if (consultation && ((consultation.expiresAt && trial === false) || (!consultation.expiresAt && trial === true))) {
      update({})
    }
  }, [trial, consultation, update])

  const flush = useCallback(
    (code?: string) => {
      if (params.code && typeof params.code !== 'string') throw new Error('code has wrong type!')
      if (consultationMeta?.ref || params.code || code) {
        setFlushing({
          ref: consultationMeta?.ref,
          code: params.code || code,
          isTraining: !!consultation?.training,
        })
      } else {
        setFlushing(null)
      }
    },
    [consultationMeta, params.code, consultation]
  )

  useEffect(() => {
    if (flushing) {
      flushConsultation()
      flushNewConsultation()
      flushCurrentConsultation()
      setConsultation(undefined)
      setInitialized(undefined)
      setLoaded(undefined)
      setCreated(undefined)
    }
  }, [flushConsultation, flushCurrentConsultation, flushNewConsultation, flushing])

  useEffect(() => {
    if (initialized === undefined) {
      // after first render, set initialized to false to trigger initialization (avoid doubele fetching in strict mode)
      setInitialized(false)
    }
  }, [initialized])

  useEffect(() => {
    if (consultation === null && flushing) {
      setFlushing(null)
    }
  }, [consultation, flushing])

  const value = useMemo<ConsultationContextInterface>(
    () => ({
      consultation,
      consultationMeta,
      flow,
      hasConsultation,
      move,
      active,
      required,
      visible,
      totalSteps,
      update,
      flush,
      flushing,
      data,
      validate,
      validation,
      canForward,
      canBackward,
      closable,
      shareable,
      loading,
      initLoading,
    }),
    [
      consultation,
      consultationMeta,
      flow,
      hasConsultation,
      active,
      required,
      visible,
      move,
      totalSteps,
      update,
      flush,
      flushing,
      validate,
      validation,
      canForward,
      canBackward,
      closable,
      shareable,
      data,
      loading,
      initLoading,
    ]
  )

  return <ConsultationContext.Provider value={value}>{children}</ConsultationContext.Provider>
}

const useConsultation = (): ConsultationContextInterface => {
  const context = useContext(ConsultationContext)
  if (!context) {
    throw new Error('useConsultation must be inside a Provider with a value')
  }
  return context
}

export { ConsultationContextProvider, useConsultation }
