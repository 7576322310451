import React, { RefObject, useMemo, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { useBreakpoints, useSize } from '../../../hooks'
import logos from '../../../svg'

import css from './HeaderLogo.module.css'

interface HeaderLogoProps {
  className?: string
  style?: React.CSSProperties
}

export const HeaderLogo: React.FC<HeaderLogoProps> = (props) => {
  const { className } = props
  const { ltSM } = useBreakpoints()

  const wrapperRef = useRef<HTMLDivElement>(null)

  const [logoRef, setLogoRef] = useState<RefObject<SVGSVGElement>>({ current: null })
  const logoCallbackRef = useRef((ref: SVGSVGElement) => {
    setLogoRef({ current: ref })
  })

  // get original width-height-ratio from svg. SVG must have viewBox defined!
  const ratio = useMemo(() => {
    if (!logoRef.current) return 1
    const { width, height } = logoRef.current.viewBox.baseVal
    return width / height
  }, [logoRef])

  // get height from logo wrapper
  const { height } = useSize(wrapperRef)

  // calculate width from original ratio and wrapper height
  const width = useMemo(() => height * ratio || 0, [height, ratio])

  const Logo = useMemo(() => {
    if (ltSM) return logos['LogoCompactDark']
    return logos['LogoLongDark']
  }, [ltSM])

  const PrintLogo = logos['LogoLongLight']

  return (
    <div ref={wrapperRef} className={className}>
      <Link to='/'>
        <Logo ref={logoCallbackRef.current} className={css['logo']} style={{ width }} />
        <PrintLogo className={`${css['logo']} ${css['print']}`} />
      </Link>
    </div>
  )
}
