import React, { useEffect, useMemo, useRef, useState } from 'react'
import css from './CustomerInput.module.css'
import { MessageOutlined } from '@ant-design/icons'
import { ControlButton } from './ControlButton'
import { useConsultation } from '../../../contexts'
import { Bubble, Markdown } from '../../utils'
import { useLocalize } from '../../../hooks'

interface CustomerInputProps {
  className?: string
}

export const CustomerInput = (props: CustomerInputProps): JSX.Element => {
  const { className } = props
  const { active, flow, consultation } = useConsultation()
  const [show, setShow] = useState<boolean>(false)
  const [pulse, setPulse] = useState<boolean>(false)
  const [opened, setOpened] = useState<boolean>(false)
  const { localize } = useLocalize()
  const ref = useRef<HTMLElement | null>(null)

  const inputs = useMemo(() => consultation?.training?.inputs || {}, [consultation])

  const activeStepNames = useMemo<string[]>(
    () => (flow?.steps || []).filter((step, index) => active.includes(index)).map(({ name }) => name),
    [active, flow]
  )

  const activeInputs = useMemo<string[]>(
    () => activeStepNames.map((name) => localize(inputs[name]) || '').filter((input) => input !== ''),
    [activeStepNames, inputs, localize]
  )

  useEffect(() => {
    setShow(false)
  }, [active])

  // show and hide speech bubble automatically, depending on activeInputs
  useEffect(() => {
    if (activeInputs.length && !show && !opened) {
      setPulse(true)
    } else {
      setPulse(false)
    }
  }, [activeInputs, show, opened])

  useEffect(() => {
    setOpened(false)
    ref.current?.blur()
  }, [activeInputs])

  return (
    <div className={className}>
      <div className={css['input']}>
        <ControlButton
          ref={ref}
          icon={<MessageOutlined />}
          disabled={!activeInputs.length} // TODO: disable if no input statements?
          type='primary'
          onClick={() => {
            setShow((showing) => {
              if (!showing) setOpened(true)
              return !showing
            })
          }}
          pulse={pulse}
        />

        {!!activeInputs.length && show && (
          <div className={css['container']}>
            {activeInputs.map((input, index) => (
              <Bubble key={`bubble-${index}`}>
                <Markdown>{input}</Markdown>
              </Bubble>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}
