import { LoadingOutlined } from '@ant-design/icons'
import React, { CSSProperties } from 'react'
import css from './SpinPlaceholder.module.css'
import { use100vh } from 'react-div-100vh'

interface SpinPlaceholderProps {
  className?: string
  style?: CSSProperties
  fullScreen?: boolean
  id?: string
}

export const SpinPlaceholder = (props: SpinPlaceholderProps): JSX.Element => {
  const { className, style, fullScreen = false, id } = props
  const height = use100vh()

  return (
    <div
      id={id}
      style={{ fontSize: '1.5rem', height: fullScreen ? height || '100vh' : undefined, ...style }}
      className={className ? `${className} ${css['placeholder']}` : css['placeholder']}
    >
      <LoadingOutlined />
    </div>
  )
}
