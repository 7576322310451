import React, { useMemo } from 'react'
import { Consultation, Content } from '../../../@types'
import { useJsonLogic } from '../../../hooks'
import { ComponentSelect } from '../component'
// import css from './ReportContent.module.css'

interface ReportContentProps {
  className?: string
  data?: Consultation['data']
  component: Content['component']
  rule: Content['rule']
  nameMap: Content['nameMap']
  position: number
}

export const ReportContent = (props: ReportContentProps): JSX.Element => {
  const { className, data, component, rule, nameMap, position } = props
  const { apply, mapData } = useJsonLogic()

  const visible = useMemo<boolean>(() => {
    if (!rule) return true
    return !!apply(rule, mapData(data ?? {}, nameMap ?? undefined))
  }, [apply, mapData, data, rule, nameMap])

  return (
    <div className={className}>
      {visible && (
        <ComponentSelect
          className='mb-6'
          name={`content-${position}`}
          data={data}
          nameMap={nameMap ?? undefined}
          position={position}
          {...component}
        />
      )}
    </div>
  )
}
