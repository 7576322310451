import React, { forwardRef, useCallback, useEffect, useMemo, useState } from 'react'
import { Select as AntSelect, SelectProps } from 'antd'
import type { BaseSelectRef } from 'rc-select'
import type { ComponentProps, DrugAutocompleteData, Localized, Product } from '../../../../@types'
import css from './DrugsSelect.module.css'
import { useConsultation } from '../../../../contexts'
import { InputLayout } from '../utils'
import {
  ApiRequestConfig,
  useApiRequest,
  useBreakpoints,
  useDebounce,
  useJsonLogic,
  useLocalize,
} from '../../../../hooks'
import { RulesLogic } from 'json-logic-js'

type DrugsSelectMeta = {
  label?: Localized
  allowMultiple?: true
  description?: Localized
  /** a JSONLogic rule, that returns a JSON Object which is added to the product data as `mapped` */
  productMap?: RulesLogic
}

export const DrugsSelect = forwardRef<BaseSelectRef, ComponentProps<DrugsSelectMeta>>(function DrugsSelect(
  props: ComponentProps<DrugsSelectMeta>,
  ref
): JSX.Element {
  const { meta, className, name, status, validation, data, isRequired, touched, onFocus, onBlur, isActive } = props
  const { label, description, allowMultiple, productMap } = meta
  const { update, loading: consultationLoading } = useConsultation()
  const [search, setSearch] = useState<string | null>(null)
  const debouncedSearch = useDebounce(search)
  const { apply } = useJsonLogic()
  const { localize } = useLocalize()
  const { ltXS } = useBreakpoints()

  const value = useMemo<SelectProps['value']>(() => {
    if (!allowMultiple && (data?.[name] as undefined | Product[])?.[0]) {
      const { id, description: descriptions } = (data?.[name] as undefined | Product[])?.[0] || {}
      const { descriptionLong } = descriptions || {}
      if (!id || !descriptionLong) throw new Error('id or description is undefined!')
      return {
        key: id,
        value: id,
        label: descriptionLong,
      }
    }

    if (allowMultiple && (data?.[name] as undefined | Product[])?.length) {
      return (data?.[name] as undefined | Product[])?.map(({ id, description: { descriptionLong } }) => ({
        key: id,
        value: id,
        label: descriptionLong,
      }))
    }

    return undefined
  }, [data, name, allowMultiple])

  const searchDrugs = useMemo<ApiRequestConfig>(
    () => ({
      method: 'GET',
      url: '/drugs/autocomplete',
      skip: !debouncedSearch,
      params: {
        q: debouncedSearch,
        limit: 10,
      },
    }),
    [debouncedSearch]
  )
  const { data: drugsData, loading: drugsLoading, flush: drugsFlush } = useApiRequest<DrugAutocompleteData>(searchDrugs)

  const options = useMemo<SelectProps['options']>(() => {
    if (!drugsData?.brands?.length) return []
    return (
      drugsData.brands?.map(({ description, products }) => ({
        label: description || 'Unbekannte Marke',
        options:
          products?.map(({ description, productNumber }) => ({
            label: description,
            value: productNumber?.toString(),
          })) || [],
      })) || []
    )
  }, [drugsData])

  const [getProductId, setGetProductId] = useState<string | undefined>(undefined)
  const getProduct = useMemo<ApiRequestConfig>(
    () => ({
      method: 'GET',
      url: `/drugs/${getProductId}`,
      skip: !getProductId,
    }),
    [getProductId]
  )
  const { data: productFull, loading: productLoading, flush: productFlush } = useApiRequest<Product>(getProduct)

  const product = useMemo(() => {
    if (productFull) {
      // Do not store large lists of interactions on consultation data state
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { interactions, ...rest } = productFull
      return { ...rest, mapped: productMap ? apply(productMap, productFull) : undefined }
    }
    return undefined
  }, [productFull, apply, productMap])

  useEffect(() => {
    if (product && !(data?.[name] as undefined | Product[])?.find(({ id }) => id === product.id)) {
      update({ data: { [name]: [...((data?.[name] as undefined | Product[]) || []), product] } })
      productFlush()
    }
  }, [product, update, name, data, productFlush])

  const onChange = useCallback<Required<SelectProps>['onChange']>(
    (value?: string | string[]) => {
      if (value && typeof value === 'string') {
        setGetProductId(value)
      } else if (value && Array.isArray(value) && value.length) {
        /** products on consultation state */
        const stateProducts = [...((data?.[name] as undefined | Product[]) || [])]
        /** ids of products on consultation state */
        const stateIds: string[] = stateProducts.map(({ id }) => id)

        if (stateIds.length < value.length) {
          // new item selected

          setGetProductId(value[value.length - 1])
        } else if (stateIds.length > value.length) {
          // item removed
          const removedIdIndex = stateIds.findIndex((id) => !value.includes(id))
          if (removedIdIndex < 0) throw new Error('Could not deterrmine removed product index')
          stateProducts.splice(removedIdIndex, 1)
          update({ data: { [name]: stateProducts } })
        }
      } else {
        update({ data: { [name]: undefined } })
      }

      // always flush options
      drugsFlush()
    },
    [update, name, data, drugsFlush]
  )

  const loading = useMemo(
    () => isActive && (drugsLoading || productLoading || consultationLoading),
    [isActive, consultationLoading, drugsLoading, productLoading]
  )

  return (
    <InputLayout
      className={className}
      label={localize(label)}
      description={localize(description)}
      status={touched ? status : undefined}
      validation={touched ? validation : undefined}
      required={isRequired}
      loading={loading}
    >
      <AntSelect
        ref={ref}
        showSearch
        mode={allowMultiple ? 'multiple' : undefined}
        defaultActiveFirstOption={false}
        filterOption={false}
        options={options}
        value={value}
        className={css['input']}
        placement={ltXS ? 'topLeft' : undefined}
        popupClassName={ltXS ? css['compact-list'] : undefined}
        status={touched ? status : undefined}
        allowClear
        notFoundContent={null}
        onSearch={(search) => {
          if (search) {
            setSearch(search)
          } else {
            setSearch(null)
            drugsFlush()
          }
        }}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
      />
    </InputLayout>
  )
})
