import { ReactComponent as EmbrioWordmarkLight } from './embrio-wordmakr-light.svg'
import { ReactComponent as LogoCompactDark } from './logo-compact-dark.svg'
import { ReactComponent as LogoCompactLight } from './logo-compact-light.svg'
import { ReactComponent as LogoLongDark } from './logo-long-dark.svg'
import { ReactComponent as LogoLongLight } from './logo-long-light.svg'
import { ReactComponent as EmbrioCompactDark } from './embrio-compact-dark.svg'

const logos = {
  EmbrioWordmarkLight,
  LogoCompactDark,
  LogoCompactLight,
  LogoLongDark,
  LogoLongLight,
  EmbrioCompactDark,
}

export default logos
