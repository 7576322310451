import { useEffect, useMemo, useState } from 'react'
import debounce from 'lodash/debounce'
import type { DebouncedFunc } from 'lodash'

/**
 * Returns a debounced value that will only change when it hasn't been changed for the `delay` period
 * @param value The function to be called
 * @param delay Wait period after function hasn't been called for
 * @returns A value that is debounced
 */
export function useDebounce<T>(value: T, delay?: number): T {
  const [debouncedValue, setDebouncedValue] = useState<T>(value)

  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), delay || 500)

    return () => {
      clearTimeout(timer)
    }
  }, [value, delay])

  return debouncedValue
}

/**
 * Returns a debounced function that will only call the passed function when it hasn't been
 * called for the `delay` period
 * @param callback The function to be called
 * @param delay Wait period after function hasn't been called for
 * @returns A memorized function that is debounced
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useDebouncedCallback<T extends (...args: any[]) => any>(callback: T, delay?: number): DebouncedFunc<T> {
  const debouncedCallback = useMemo<DebouncedFunc<T>>(() => {
    return debounce<T>(callback, delay ?? 500)
  }, [callback, delay])

  useEffect(() => {
    return () => {
      debouncedCallback.cancel()
    }
  }, [debouncedCallback])

  return debouncedCallback
}
