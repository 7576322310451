import type { AxiosInstance } from 'axios'
import React, { createContext, useContext, PropsWithChildren, useEffect, useMemo, useState, useCallback } from 'react'
import { useLocation } from 'react-router-dom'
import { useHelpers } from './Helpers'
import { useIdentityClient } from './IdentityClient'
import { TokenContext, useToken, Token } from './Token'
import { Authenticate, useAuthenticate } from './Authenticate'
import { Logout, useLogout } from './Logout'
import { Callback, useIdentityCallback } from './Callback'

export interface IdentityContextInterface {
  /** indicates that the context is ready to operate */
  ready: IdentityContextTypes['ready']

  /** indicates that token requests are pending */
  loading: boolean

  /** the access token */
  token: IdentityContextTypes['token']

  /** executes the authentication process */
  authenticate: Authenticate

  /** executes the logout process */
  logout: Logout

  /** processes the callback after a autenticate or logout redirect */
  callback: Callback

  /** executes a token refresh atempt */
  refresh: Token['refresh']
}

export interface IdentityContextTypes {
  client: AxiosInstance
  ready: boolean
  verifier: string | null
  state: string | null
  identity: string | null
  setIdentity: (identity: string) => void
  code: string | null
  setCode: React.Dispatch<React.SetStateAction<string | null>>
  callbackUri: string
  flush: () => void
  flushToken: () => void
  refresh: () => void
  token: string | null | undefined
  setToken: React.Dispatch<React.SetStateAction<string | null | undefined>>
  refreshToken: string | null | undefined
  setRefreshToken: React.Dispatch<React.SetStateAction<string | null | undefined>>
  loading: boolean
  initByCallback: boolean
}

const IdentityContext = createContext<IdentityContextInterface | undefined>(undefined)

const callbackPath = '/identity'
const callbackUri = `${window.location.protocol}//${window.location.host}${callbackPath}`

const IdentityContextProvider: React.FC<PropsWithChildren> = (props) => {
  const { children } = props
  const { base64URLEncode, randomBytes, computeHash } = useHelpers()
  const [verifier, _setVerifier] = useState<IdentityContextTypes['verifier']>(null)
  const [state, _setState] = useState<IdentityContextTypes['state']>(null)
  const [identity, _setIdentity] = useState<IdentityContextTypes['identity']>(null)
  const [code, setCode] = useState<IdentityContextTypes['code']>(null)
  const [token, setToken] = useState<IdentityContextTypes['token']>(undefined)
  const [refreshToken, setRefreshToken] = useState<IdentityContextTypes['refreshToken']>(undefined)
  const [execLogout, setExecLogout] = useState<boolean>(false)
  const location = useLocation()
  const [initByCallback, setInitByCallback] = useState<boolean>(false)

  useEffect(() => {
    if (location.pathname === callbackPath) {
      setInitByCallback(true)
    }
  }, [location])

  // identity api client
  const identityClientContext = useMemo(
    () => ({
      setExecLogout,
    }),
    []
  )
  const clientRef = useIdentityClient(identityClientContext)

  /** stores verifier on state and session storage */
  const setVerifier = useCallback((verifier: string | null) => {
    if (verifier) {
      window.sessionStorage.setItem('verifier', verifier)
    } else {
      window.sessionStorage.removeItem('verifier')
    }
    _setVerifier(verifier ?? null)
  }, [])

  /** stores state on state and session storage */
  const setState = useCallback((state: string | null) => {
    if (state) {
      window.sessionStorage.setItem('state', state)
    } else {
      window.sessionStorage.removeItem('state')
    }
    _setState(state ?? null)
  }, [])

  /** stores identity (id_token) on state and local storage */
  const setIdentity = useCallback((identity: string | null) => {
    if (identity) {
      window.localStorage.setItem('idt', identity)
    } else {
      window.localStorage.removeItem('idt')
    }
    _setIdentity(identity ?? null)
  }, [])

  const ready = useMemo<boolean>(() => !!verifier && !!state, [verifier, state])

  // initialize verifier from session storage or create new
  useEffect(() => {
    if (!window.sessionStorage.getItem('verifier')) {
      const newVerifier = base64URLEncode(randomBytes(32))
      window.sessionStorage.setItem('verifier', newVerifier)
    }
    if (verifier !== window.sessionStorage.getItem('verifier')) {
      _setVerifier(window.sessionStorage.getItem('verifier'))
    }
  }, [verifier, base64URLEncode, randomBytes])

  // initialize state from session storage or create new
  useEffect(() => {
    if (!window.sessionStorage.getItem('state')) {
      const newState = base64URLEncode(randomBytes(32))
      window.sessionStorage.setItem('state', newState)
    }
    if (state !== window.sessionStorage.getItem('state')) {
      _setState(window.sessionStorage.getItem('state'))
    }
  }, [state, base64URLEncode, randomBytes])

  // initialize identity (id_token) from local storage or create new
  useEffect(() => {
    const identity = window.localStorage.getItem('idt')
    if (identity) {
      _setIdentity(identity)
    }
  }, [])

  /** flush identity context */
  const flush = useCallback(() => {
    setIdentity(null)
    setState(null)
    setVerifier(null)
    setCode(null)
    setToken(null)
  }, [setState, setIdentity, setVerifier])

  /** context for the useToken hook */
  const tokenContext = useMemo<TokenContext>(
    () => ({
      verifier,
      callbackUri,
      code,
      ready,
      clientRef,
      setIdentity,
      token,
      setToken,
      refreshToken,
      setRefreshToken,
      initByCallback,
      onFlush: () => {
        setCode(null)
      },
    }),
    [verifier, code, ready, setIdentity, token, setToken, refreshToken, setRefreshToken, initByCallback, clientRef]
  )
  const { loading, flush: flushToken, refresh } = useToken(tokenContext)

  const authenticateContext = useMemo(
    () => ({
      verifier,
      state,
      computeHash,
      callbackUri,
      token,
    }),
    [verifier, state, computeHash, token]
  )
  const authenticate = useAuthenticate(authenticateContext)

  const logoutContext = useMemo(
    () => ({
      identity,
      state,
      flushToken,
      callbackUri,
      execLogout,
    }),
    [identity, state, flushToken, execLogout]
  )
  const logout = useLogout(logoutContext)

  const callbackContext = useMemo(
    () => ({
      state,
      flush,
      setCode,
    }),
    [state, flush]
  )
  const callback = useIdentityCallback(callbackContext)

  const value = useMemo<IdentityContextInterface>(
    () => ({
      ready,
      loading,
      token,
      authenticate,
      logout,
      callback,
      refresh,
    }),
    [ready, loading, token, authenticate, logout, callback, refresh]
  )

  return <IdentityContext.Provider value={value}>{children}</IdentityContext.Provider>
}

const useIdentity = (): IdentityContextInterface => {
  const context = useContext(IdentityContext)
  if (!context) {
    throw new Error('useIdentity must be inside a Provider with a value')
  }
  return context
}

export { IdentityContextProvider, useIdentity }
