import React, { CSSProperties, useEffect, useMemo, useRef, useState } from 'react'
import { Image, Spin } from 'antd'
import { use100vh } from 'react-div-100vh'
import { ImgproxyOptions, useDebounce, useResponsiveImage, useSize } from '../../hooks'
// import css from './LightboxImage.module.css'

interface LightboxImageProps {
  className?: string
  style?: CSSProperties

  /** set to measure container width */
  measureWidth?: boolean

  /** set to measure container height */
  measureHeight?: boolean

  src?: string
  alt?: string
}

export const LightboxImage = (props: LightboxImageProps): JSX.Element => {
  const { className, style, src, alt, measureHeight, measureWidth } = props
  const [lightbox, setLightbox] = useState(false)
  const screenHeight = use100vh() || 0
  const [screenWidth, setScreenWidth] = useState(0)
  const ref = useRef<HTMLDivElement | null>(null)
  const { width, height } = useDebounce(useSize(ref))

  const resizeWidth = useMemo(() => (measureWidth ? Math.round(width) : undefined), [width, measureWidth])
  const resizeHeight = useMemo(() => (measureHeight ? Math.round(height) : undefined), [height, measureHeight])

  useEffect(() => {
    const listener = () => {
      setScreenWidth(window.innerWidth)
    }
    window.addEventListener('resize', listener)

    return () => {
      window.removeEventListener('resize', listener)
    }
  }, [])

  const options = useMemo<ImgproxyOptions>(
    () => ({
      resize: {
        width: resizeWidth,
        height: resizeHeight,
        type: 'fit',
      },
    }),
    [resizeHeight, resizeWidth]
  )

  const ready: boolean = useMemo(
    () => !((measureWidth && !resizeWidth) || (measureHeight && !resizeHeight)),
    [resizeWidth, resizeHeight, measureHeight, measureWidth]
  )

  const { src: responsiveSrc, meta, loading } = useResponsiveImage(ready ? src : undefined, options)

  const isResponsiveImage = useMemo<boolean>(() => !!meta, [meta])

  const lightboxOptions = useMemo<ImgproxyOptions>(
    () => ({
      resize: {
        width: Math.round(screenWidth),
        height: Math.round(screenHeight),
        type: 'fit',
      },
    }),
    [screenHeight, screenWidth]
  )

  const { src: lightboxSrc, loading: lightboxLoading } = useResponsiveImage(
    isResponsiveImage && lightbox ? src : undefined,
    lightboxOptions
  )

  return (
    <div style={style} ref={ref} className={className}>
      {isResponsiveImage ? (
        <>
          <Spin spinning={loading || lightboxLoading}>
            <Image
              preview={{ visible: false }}
              src={responsiveSrc}
              alt={alt || meta?.alternativeText || undefined}
              onClick={() => {
                setLightbox(true)
              }}
            />
          </Spin>
          <div className='hidden'>
            <Image
              preview={{
                visible: lightbox,
                onVisibleChange: (visible) => {
                  setLightbox(visible)
                },
              }}
              src={lightboxSrc}
              alt={alt || meta?.alternativeText || undefined}
            />
          </div>
        </>
      ) : (
        <Image src={src} alt={alt} />
      )}
    </div>
  )
}
