import { useEffect, useState, useMemo, useCallback } from 'react'
import { ApiRequestConfig, useApiRequest } from '../../hooks'
import type { Device, Employee } from '../../@types'

interface EmployeeContext {
  device: Device | null | undefined
  ready: boolean
}

export function useEmployee({ device, ready }: EmployeeContext) {
  const [employee, setEmployee] = useState<Employee | null | undefined>(undefined)
  const [execLogout, setExecLogout] = useState<boolean>(false)

  const getCurrentEmployee = useMemo<ApiRequestConfig>(
    () => ({
      method: 'GET',
      url: '/employees/current',
      skip: !!employee || employee === null || !device || !ready,
    }),
    [employee, device, ready]
  )
  const {
    data: dataCurrent,
    loading: loadingCurrent,
    flush: flushCurrent,
  } = useApiRequest<Employee | null>(getCurrentEmployee)

  useEffect(() => {
    if (dataCurrent !== undefined) {
      setEmployee(dataCurrent)
    }
  }, [dataCurrent])

  useEffect(() => {
    if (employee !== undefined) flushCurrent()
  }, [employee, flushCurrent])

  const logoutEmployee = useMemo<ApiRequestConfig>(
    () => ({
      method: 'DELETE',
      url: '/employees/logout',
      skip: !execLogout,
    }),
    [execLogout]
  )
  const { message: messageLogout, loading: loadingLogout, flush: flushLogout } = useApiRequest<Employee>(logoutEmployee)

  useEffect(() => {
    if (messageLogout) {
      setExecLogout(false)
      setEmployee(null)
      flushLogout()
      flushCurrent()
    }
  }, [messageLogout, flushCurrent, flushLogout])

  useEffect(() => {
    // react to device changes
    if (device === null) {
      // if no device session
      setEmployee(null)
    } else if (device) {
      // if device session, trigger check of current employee session
      setEmployee(undefined)
    }
  }, [device])

  const logout = useCallback(() => {
    if (employee) {
      setExecLogout(true)
    }
  }, [employee])

  const loading = useMemo(() => loadingCurrent || loadingLogout, [loadingCurrent, loadingLogout])

  return { employee, loading, setEmployee, logout }
}
