import { useEffect, useState, useCallback } from 'react'
import { useDebounce } from './Debounce'

export interface WheelOptions {
  onWheelUp?: () => void
  onWheelDown?: () => void
}

export interface WheelInterface {
  ref: (element: HTMLElement | null) => void
}

const MIN_DELTA = 100

export function useWheel(config: WheelOptions): WheelInterface {
  const [delta, setDelta] = useState(0)
  const debouncedDelta = useDebounce(delta, 50)
  const [executing, setExecuting] = useState<boolean>(false)
  const [element, setElement] = useState<HTMLElement | null>(null)

  const ref: WheelInterface['ref'] = useCallback((element) => {
    setElement(element)
  }, [])

  useEffect(() => {
    if (!executing) {
      if (config.onWheelUp && debouncedDelta > 0 && debouncedDelta > MIN_DELTA) {
        setExecuting(true)

        config.onWheelUp()
      }
      if (config.onWheelDown && debouncedDelta < 0 && debouncedDelta < -MIN_DELTA) {
        setExecuting(true)

        config.onWheelDown()
      }
      // reset delta
      setDelta(0)
    }
    if (executing && debouncedDelta === 0) {
      setExecuting(false)
    }
  }, [debouncedDelta, config, executing])

  useEffect(() => {
    const listener = (e: WheelEvent) => {
      setDelta((current) => current + e.deltaY)
    }

    if (element) {
      if (element) {
        element.addEventListener('wheel', listener)

        return () => {
          element.removeEventListener('wheel', listener)
        }
      }
    }
  }, [element])

  return { ref }
}
