import { useEffect } from 'react'

export interface KeyConfig {
  key: KeyboardEvent['key']
  onKeyup?: (e: KeyboardEvent) => void
  onKeydown?: (e: KeyboardEvent) => void
}

export function useKey(config: KeyConfig) {
  useEffect(() => {
    const { key, onKeydown } = config
    if (onKeydown) {
      const listener = (e: KeyboardEvent) => {
        if (e.key === key) onKeydown(e)
      }

      window.addEventListener('keydown', listener)

      return () => {
        window.removeEventListener('keydown', listener)
      }
    }
  }, [config])

  useEffect(() => {
    const { key, onKeyup } = config
    if (onKeyup) {
      const listener = (e: KeyboardEvent) => {
        if (e.key === key) onKeyup(e)
      }

      window.addEventListener('keyup', listener)

      return () => {
        window.removeEventListener('keyup', listener)
      }
    }
  }, [config])
}
