import React, { useMemo } from 'react'
import { use100vh } from 'react-div-100vh'
import { useTranslation } from 'react-i18next'
import { useBasicLayout } from '../../../layouts'
import css from './PostConsultation.module.css'
import { useHandlebars, useQuery } from '../../../../hooks'
import { Heading } from '../../../typography'
import { Button } from 'antd'
import { CardContainer, Markdown } from '../../../utils'
import { useEnvironment } from '../../../../contexts'

export const PostConsultation = (): JSX.Element => {
  const {
    t,
    i18n: { exists },
  } = useTranslation('consultation', { keyPrefix: 'done' })
  const screenHeight = use100vh()
  const { FOOTER_HEIGHT, HEADER_HEIGHT } = useBasicLayout()
  const [params] = useQuery()
  const {
    environment: { APP_POST_CONSULTATION_CTA },
  } = useEnvironment()
  const { compile } = useHandlebars()

  const cta = useMemo<string | undefined>(() => {
    if (!APP_POST_CONSULTATION_CTA || !params.ref) return undefined
    const template = compile(APP_POST_CONSULTATION_CTA)
    return template({ ref: params.ref })
  }, [compile, APP_POST_CONSULTATION_CTA, params])

  return (
    <CardContainer
      className={css['container']}
      style={{ minHeight: `calc(${screenHeight}px - ${HEADER_HEIGHT}px - ${FOOTER_HEIGHT}px)` }}
    >
      <Heading level={1}>{t('title')}</Heading>
      {exists('consultation:done.description') && (
        <Markdown className={css['description']}>{t('description')}</Markdown>
      )}
      {cta && (
        <>
          <Markdown className={css['description']}>{t('cta.description')}</Markdown>
          <div className={css['cta']}>
            <Button type='primary' size='large' href={cta} target='_blank' className={css['cta']}>
              {t('cta.button')}
            </Button>
          </div>
        </>
      )}
    </CardContainer>
  )
}
