import React, { CSSProperties, useEffect, useMemo, useState } from 'react'
import { ConsultationContextProvider, TrainingContextProvider } from '../../../../contexts'
import { Breakpoint, useBreakpoints, useCan } from '../../../../hooks'
import { BasicLayoutProps } from '../../../layouts'
import { Flow } from '../../../content/flow'

import css from './Consultation.module.css'
import { Route, Routes } from 'react-router-dom'
import { Takeover } from '../../../content/consultation/Takeover'

const siderWidths: Partial<{ [breakpoint in Breakpoint]: CSSProperties['width'] }> = {
  MD: '50vw',
  LG: '55vw',
  XL: '55vw',
  XXL: '55vw',
}

interface ConsultationProps {
  onBasicLayoutProps?: (props: BasicLayoutProps) => void
  onHasConsultation?: (hasConsultation?: boolean) => void
}

export const Consultation: React.FC<ConsultationProps> = (props) => {
  const { onBasicLayoutProps, onHasConsultation } = props
  const { breakpoint } = useBreakpoints()
  const [showNavigation, setShowNavigation] = useState<boolean>(true)
  const canSiderView = useCan('sider:view')

  useEffect(() => {
    // this avoids pull to refresh on mobile
    document.getElementsByTagName('html')[0]?.classList.add(css['no-pull-to-refresh'])
    document.body.classList.add(css['no-pull-to-refresh'])

    return () => {
      document.getElementsByTagName('html')[0]?.classList.remove(css['no-pull-to-refresh'])
      document.body.classList.remove(css['no-pull-to-refresh'])
    }
  })

  const consultationLayoutProps = useMemo<BasicLayoutProps>(
    () => ({
      contentFrameStyle: { overflowY: 'hidden' },
      siderWidth: breakpoint && !showNavigation ? siderWidths[breakpoint] : undefined,
      hideSider: canSiderView !== undefined ? !canSiderView : undefined,
    }),
    [breakpoint, showNavigation, canSiderView]
  )

  useEffect(() => {
    onBasicLayoutProps && onBasicLayoutProps(consultationLayoutProps)
  }, [consultationLayoutProps, onBasicLayoutProps])

  return (
    <TrainingContextProvider>
      <ConsultationContextProvider>
        <Routes>
          <Route
            path=''
            element={
              <Flow
                className='p-6'
                onHasConsultation={(hasConsultation) => {
                  onHasConsultation && onHasConsultation(hasConsultation)
                  if (hasConsultation !== undefined) {
                    setShowNavigation(!hasConsultation)
                  }
                }}
              />
            }
          />
          <Route path='takeover' element={<Takeover />} />
        </Routes>
      </ConsultationContextProvider>
    </TrainingContextProvider>
  )
}
