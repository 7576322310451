import React, { CSSProperties, ReactNode } from 'react'
import css from './Container.module.css'

interface ContainerProps {
  className?: string
  children?: ReactNode
  style?: CSSProperties
  id?: string
}

export const Container = (props: ContainerProps): JSX.Element => {
  const { className, children, style, id } = props

  return (
    <div className={className ? `${className} ${css['container']}` : css['container']} style={style} id={id}>
      {children}
    </div>
  )
}
