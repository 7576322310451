import type { AxiosError, AxiosInstance } from 'axios'
import React, { createContext, useContext, PropsWithChildren, useMemo, useState, useRef } from 'react'
import { useApiClient } from './ApiClient'

interface ApiContextInterface {
  clientRef: React.MutableRefObject<AxiosInstance>
  retry: boolean
  setRetry: React.Dispatch<React.SetStateAction<boolean>>
  onError401Ref: React.MutableRefObject<
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ((error: AxiosError<any>) => undefined | AxiosError) | undefined
  >
}

const ApiContext = createContext<ApiContextInterface | undefined>(undefined)

const ApiContextProvider: React.FC<PropsWithChildren> = (props) => {
  const { children } = props
  const [retry, setRetry] = useState<boolean>(false)
  const onError401Ref = useRef<((error: AxiosError) => undefined | AxiosError) | undefined>(undefined)

  const clientRef = useApiClient()

  const value = useMemo<ApiContextInterface>(
    () => ({
      clientRef,
      retry,
      setRetry,
      onError401Ref,
    }),
    [clientRef, retry]
  )

  return <ApiContext.Provider value={value}>{children}</ApiContext.Provider>
}

const useApi = (): ApiContextInterface => {
  const context = useContext(ApiContext)
  if (!context) {
    throw new Error('useApi must be inside a Provider with a value')
  }
  return context
}

export { ApiContextProvider, useApi }
