/* eslint-disable max-len */

// start of md string
export const privacyPolicy = /* md */ `

pharMe erfasst weder Namen noch Adresse oder Geburtsdatum. Alleine die aufgesuchte Apotheke ist in der Lage, die Daten von pharMe mit Ihren Personenbezogenen Daten zu verknüpfen. Aus diesem Grund gelten die Daten als relativ anonym.

### Verantwortliche Stelle

Verantwortliche Stelle im Sinne der Datenschutzgesetze (DSG, nDSG + EU-DSGVO):

Universität Basel  
[Pharmaceutical Care Research Group](https://pharma.unibas.ch/de/research/research-groups/pharmaceutical-care-2170/)  
Klingelbergstrasse 50  
4056 Basel  
Schweiz

### Name des Unternehmens

Universität Basel, Pharmaceutical Care Research Group

### Allgemeines / Einleitung

Gestützt auf Artikel 13 der Schweizerischen Bundesverfassung und die datenschutzrechtlichen Bestimmungen des Bundes (Datenschutzgesetz, DSG) hat jede Person Anspruch auf Schutz ihrer Privatsphäre sowie auf Schutz vor Missbrauch ihrer persönlichen Daten. Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser Datenschutzerklärung.

In Zusammenarbeit mit unseren Hosting-Providern bemühen wir uns, die Datenbanken so gut wie möglich vor unberechtigtem Zugriff, Verlust, Missbrauch oder Verfälschung zu schützen.

Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich.

Durch die Nutzung dieser Website erklären Sie sich mit der Erhebung, Verarbeitung und Nutzung von Daten gemäss der nachfolgenden Beschreibung einverstanden. Diese Website kann grundsätzlich ohne Registrierung besucht werden. Daten wie aufgerufene Seiten oder Namen von aufgerufenen Dateien, Datum und Uhrzeit werden zu statistischen Zwecken auf dem Server gespeichert, ohne dass diese Daten unmittelbar auf Ihre Person bezogen werden. Soweit auf unseren Seiten personenbezogene Daten (beispielsweise Name, Anschrift oder eMail-Adressen) erhoben werden, erfolgt dies, soweit möglich, stets auf freiwilliger Basis. Diese Daten werden ohne Ihre ausdrückliche Zustimmung nicht an Dritte weitergegeben.

### Verarbeitung personenbezogener Daten

Personenbezogene Daten sind alle Informationen, die sich auf eine identifizierte oder identifizierbare Person beziehen. Eine betroffene Person ist eine Person, über die personenbezogene Daten verarbeitet werden. Die Verarbeitung umfasst jeden Umgang mit personenbezogenen Daten, unabhängig von den verwendeten Mitteln und Verfahren, insbesondere die Speicherung, Weitergabe, Beschaffung, Löschung, Aufbewahrung, Veränderung, Vernichtung und Verwendung personenbezogener Daten.

Wir verarbeiten personenbezogene Daten in Übereinstimmung mit dem Schweizer Datenschutzrecht. Sofern und soweit die EU-DSGVO anwendbar ist, verarbeiten wir personenbezogene Daten darüber hinaus auf folgenden Rechtsgrundlagen in Verbindung mit Art. 6 (1) GDPR:

- lit. a) Verarbeitung personenbezogener Daten mit Einwilligung der betroffenen Person.
- lit. b) Verarbeitung personenbezogener Daten zur Erfüllung eines Vertrages mit der betroffenen Person sowie zur Durchführung entsprechender vorvertraglicher Massnahmen.
- lit. c) Verarbeitung personenbezogener Daten zur Erfüllung einer rechtlichen Verpflichtung, der wir nach geltendem Recht der EU oder nach geltendem Recht eines Landes, in dem die GDPR ganz oder teilweise anwendbar ist, unterliegen.
- lit. d) Verarbeitung personenbezogener Daten zur Wahrung lebenswichtiger Interessen der betroffenen Person oder einer anderen natürlichen Person.
- lit. f) Verarbeitung personenbezogener Daten zur Wahrung der berechtigten Interessen von uns oder von Dritten, sofern nicht die Grundfreiheiten und Rechte und Interessen der betroffenen Person überwiegen. Zu den berechtigten Interessen gehören insbesondere unser geschäftliches Interesse, unsere Website bereitstellen zu können, die Informationssicherheit, die Durchsetzung eigener Rechtsansprüche und die Einhaltung des schweizerischen Rechts.

Wir verarbeiten personenbezogene Daten für die Dauer, die für den jeweiligen Zweck oder die jeweiligen Zwecke erforderlich ist. Bei längerfristigen Aufbewahrungspflichten aufgrund gesetzlicher und anderer Verpflichtungen, denen wir unterliegen, schränken wir die Bearbeitung entsprechend ein.

### Cookies

Diese Website verwendet Cookies. Dabei handelt es sich um kleine Textdateien, die es ermöglichen, spezifische, auf den Nutzer bezogene Informationen auf dem Endgerät des Nutzers zu speichern, während der Nutzer die Website nutzt. Cookies ermöglichen es insbesondere, die Nutzungshäufigkeit und die Anzahl der Nutzer der Seiten zu ermitteln, Verhaltensmuster der Seitennutzung zu analysieren, aber auch, unser Angebot kundenfreundlicher zu gestalten. Cookies bleiben über das Ende einer Browser-Sitzung hinaus gespeichert und können bei einem erneuten Besuch der Seite wieder abgerufen werden. Wenn Sie dies nicht wünschen, sollten Sie Ihren Internet-Browser so einstellen, dass er die Annahme von Cookies verweigert.

Ein genereller Widerspruch gegen die Verwendung von Cookies zu Online-Marketing-Zwecken kann für eine Vielzahl der Dienste, insbesondere beim Tracking, über die US-Seite http://www.aboutads.info/choices/ oder die EU-Seite http://www.youronlinechoices.com/ erklärt werden. Darüber hinaus kann die Speicherung von Cookies durch Deaktivierung in den Browsereinstellungen erreicht werden. Bitte beachten Sie, dass in diesem Fall nicht alle Funktionen dieses Online-Angebots genutzt werden können.

### Mit SSL/TLS-Verschlüsselung

Diese Website verwendet aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte, wie z.B. Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL/TLS-Verschlüsselung. Eine verschlüsselte Verbindung erkennen Sie daran, dass die Adresszeile des Browsers von "http://" auf "https://" wechselt und an dem Schloss-Symbol in Ihrer Browserzeile.

Wenn die SSL- oder TLS-Verschlüsselung aktiviert ist, können die Daten, die Sie an uns übermitteln, nicht von Dritten gelesen werden.

### Server-Log-Dateien

Der Provider dieser Website erhebt und speichert automatisch Informationen in so genannten Server-Log-Dateien, die Ihr Browser automatisch an uns übermittelt. Dies sind:

- Browsertyp und Browserversion
- Verwendetes Betriebssystem
- Referrer URL
- Hostname des zugreifenden Rechners
- Zeitpunkt der Serveranfrage

Diese Daten sind nicht bestimmten Personen zuordenbar. Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht vorgenommen. Wir behalten uns vor, diese Daten nachträglich zu prüfen, wenn uns konkrete Anhaltspunkte für eine rechtswidrige Nutzung bekannt werden.

### Rechte der betroffenen Person

#### Recht auf Bestätigung

Jede betroffene Person hat das Recht, vom Betreiber der Website eine Bestätigung darüber zu verlangen, ob sie betreffende personenbezogene Daten verarbeitet werden. Wenn Sie dieses Bestätigungsrecht ausüben möchten, können Sie sich jederzeit an den Datenschutzbeauftragten wenden.

#### Auskunftsrecht

Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das Recht, vom Betreiber dieser Website jederzeit unentgeltlich Auskunft über die zu ihrer Person gespeicherten Daten und eine Kopie dieser Auskunft zu erhalten. Darüber hinaus kann ggf. Auskunft über Folgendes erteilt werden:

- Zwecke der Verarbeitung,
- Kategorien der verarbeiteten personenbezogenen Daten,
- Empfänger, an die die personenbezogenen Daten weitergegeben wurden
- oder werden wenn möglich, die geplante Dauer der Speicherung der personenbezogenen Daten
- oder, falls dies nicht möglich ist, die Kriterien für die Festlegung dieser Dauer
- das Bestehen eines Rechts auf Berichtigung oder Löschung der sie betreffenden personenbezogenen Daten
- oder auf Einschränkung der Verarbeitung durch den für die Verarbeitung Verantwortlichen
- oder ein Recht auf Widerspruch gegen eine solche Verarbeitung
- das Bestehen eines Beschwerderechts bei einer Aufsichtsbehörde wenn die personenbezogenen Daten nicht bei der betroffenen Person erhoben werden: Alle verfügbaren Informationen über die Herkunft der Daten.

Ausserdem hat die betroffene Person das Recht, darüber informiert zu werden, ob personenbezogene Daten in ein Drittland oder an eine internationale Organisation übermittelt worden sind. Ist dies der Fall, so hat die betroffene Person ausserdem das Recht, Auskunft über die geeigneten Garantien im Zusammenhang mit der Übermittlung zu erhalten.

Wenn Sie von diesem Auskunftsrecht Gebrauch machen möchten, können Sie sich jederzeit an unseren Datenschutzbeauftragten wenden.

#### Recht auf Berichtigung

Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das Recht, die unverzügliche Berichtigung sie betreffender unrichtiger personenbezogener Daten zu verlangen. Darüber hinaus hat die betroffene Person das Recht, unter Berücksichtigung der Zwecke der Verarbeitung, die Vervollständigung unvollständiger personenbezogener Daten - auch mittels einer ergänzenden Erklärung - zu verlangen.

Wenn Sie dieses Recht auf Berichtigung ausüben möchten, können Sie sich jederzeit an unseren Datenschutzbeauftragten wenden.

#### Recht auf Löschung (Recht auf Vergessenwerden)

Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das Recht, von dem für die Verarbeitung Verantwortlichen dieser Website die unverzügliche Löschung der sie betreffenden personenbezogenen Daten zu verlangen, sofern einer der folgenden Gründe zutrifft und die Verarbeitung nicht mehr erforderlich ist:

- Die personenbezogenen Daten wurden für Zwecke erhoben oder auf sonstige Weise verarbeitet, für die sie nicht mehr erforderlich sind.
- Die betroffene Person widerruft die Einwilligung, auf der die Verarbeitung beruhte, und es gibt keine andere Rechtsgrundlage für die Verarbeitung
- Die betroffene Person legt aus Gründen, die sich aus ihrer besonderen Situation ergeben, Widerspruch gegen die Verarbeitung ein, und es liegen keine vorrangigen berechtigten Gründe für die Verarbeitung vor, oder die betroffene Person legt im Falle von Direktwerbung und damit verbundenem Profiling Widerspruch gegen die Verarbeitung ein
- Die personenbezogenen Daten wurden unrechtmässig verarbeitet
- Die Löschung der personenbezogenen Daten ist zur Erfüllung einer rechtlichen Verpflichtung nach dem Unionsrecht oder dem Recht der Mitgliedstaaten erforderlich, dem der für die Verarbeitung Verantwortliche unterliegt
- Die personenbezogenen Daten wurden in Bezug auf angebotene Dienste der Informationsgesellschaft erhoben, die direkt an ein Kind gerichtet sind

Wenn einer der oben genannten Gründe zutrifft und Sie die Löschung von personenbezogenen Daten, die beim Betreiber dieser Website gespeichert sind, veranlassen möchten, können Sie sich jederzeit an unseren Datenschutzbeauftragten wenden. Der Datenschutzbeauftragte dieser Website wird veranlassen, dass dem Löschverlangen unverzüglich nachgekommen wird.

#### Recht auf Einschränkung der Verarbeitung

Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das Recht, von dem für die Verarbeitung Verantwortlichen dieser Website die Einschränkung der Verarbeitung zu verlangen, wenn eine der folgenden Bedingungen erfüllt ist:

- Die Richtigkeit der personenbezogenen Daten wird von der betroffenen Person bestritten, und zwar für einen Zeitraum, der es dem für die Verarbeitung Verantwortlichen ermöglicht, die Richtigkeit der personenbezogenen Daten zu überprüfen
- Die Verarbeitung ist unrechtmässig, die betroffene Person legt Widerspruch gegen die Löschung der personenbezogenen Daten ein und verlangt stattdessen die Einschränkung der Nutzung der personenbezogenen Daten
- Der für die Verarbeitung Verantwortliche benötigt die personenbezogenen Daten nicht mehr für die Zwecke der Verarbeitung, die betroffene Person benötigt sie jedoch für die Geltendmachung, Die betroffene Person hat aus Gründen, die sich aus ihrer besonderen Situation ergeben, Widerspruch gegen die Verarbeitung eingelegt, und es steht noch nicht fest, ob die berechtigten Interessen des Verantwortlichen gegenüber denen der betroffenen Person überwiegen

Wenn eine der vorgenannten Voraussetzungen gegeben ist, können Sie sich jederzeit an unseren Datenschutzbeauftragten wenden, um die Einschränkung der Verarbeitung personenbezogener Daten beim Betreiber dieser Website zu verlangen. Der Datenschutzbeauftragte dieser Website wird die Einschränkung der Verarbeitung veranlassen.

#### Recht auf Datenübertragbarkeit

Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das Recht, die sie betreffenden personenbezogenen Daten in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten. Darüber hinaus hat die betroffene Person das Recht, zu erwirken, dass die personenbezogenen Daten direkt von einem für die Verarbeitung Verantwortlichen an einen anderen für die Verarbeitung Verantwortlichen übermittelt werden, sofern dies technisch machbar ist und sofern dadurch nicht die Rechte und Freiheiten anderer Personen beeinträchtigt werden.

Um das Recht auf Datenübertragbarkeit geltend zu machen, können Sie sich jederzeit an den vom Betreiber dieser Website benannten Datenschutzbeauftragten wenden.

#### Ein Widerspruchsrecht

Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das Recht, aus Gründen, die sich aus ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung sie betreffender personenbezogener Daten Widerspruch einzulegen.

Der Betreiber dieser Website wird die personenbezogenen Daten im Falle des Widerspruchs nicht mehr verarbeiten, es sei denn, wir können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die die Interessen, Rechte und Freiheiten der betroffenen Person überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.

Um von Ihrem Widerspruchsrecht Gebrauch zu machen, können Sie sich direkt an den Datenschutzbeauftragten dieser Website wenden.

#### Recht auf Widerruf einer datenschutzrechtlichen Einwilligung

Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das Recht, eine erteilte Einwilligung in die Verarbeitung personenbezogener Daten jederzeit zu widerrufen.

Wenn Sie von Ihrem Recht auf Widerruf einer Einwilligung Gebrauch machen möchten, können Sie sich jederzeit an unseren Datenschutzbeauftragten wenden.

### Widerspruch E-Mail Marketing

Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten zur Übersendung von nicht ausdrücklich angeforderter Werbung und Informationsmaterialien wird hiermit widersprochen. Die Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte im Falle der unverlangten Zusendung von Werbeinformationen, etwa durch Spam-Mails, vor.

### Kostenpflichtige Dienste

Für die Erbringung von kostenpflichtigen Dienstleistungen fragen wir weitere Daten, wie z.B. Zahlungsdaten, ab, um Ihren Auftrag ausführen zu können. Wir speichern diese Daten in unseren Systemen, bis die gesetzlichen Aufbewahrungsfristen abgelaufen sind.

### Urheberrechte

Das Urheberrecht und alle anderen Rechte an den Inhalten, Bildern, Fotos oder sonstigen Dateien auf der Website gehören ausschliesslich dem Betreiber dieser Website oder den namentlich genannten Rechteinhabern. Für die Vervielfältigung sämtlicher Dateien muss vorab die schriftliche Zustimmung der Urheberrechtsinhaber eingeholt werden.

Wer ohne Zustimmung der jeweiligen Urheberrechtsinhaber eine Urheberrechtsverletzung begeht, kann sich strafbar machen und wird unter Umständen schadenersatzpflichtig.

### Haftungsausschluss

Alle Angaben auf unserer Website wurden sorgfältig geprüft. Wir sind bemüht, dafür Sorge zu tragen, dass die von uns bereitgestellten Informationen aktuell, richtig und vollständig sind. Dennoch ist das Auftreten von Fehlern nicht völlig auszuschliessen, so dass wir für die Vollständigkeit, Richtigkeit und Aktualität der Informationen, auch journalistisch-redaktioneller Art, keine Gewähr übernehmen können. Haftungsansprüche, die sich auf Schäden materieller oder ideeller Art beziehen, welche durch die Nutzung oder Nichtnutzung der dargebotenen Informationen bzw. durch die Nutzung fehlerhafter und unvollständiger Informationen verursacht wurden, sind grundsätzlich ausgeschlossen.

Der Herausgeber kann Texte nach eigenem Ermessen und ohne vorherige Ankündigung ändern oder löschen und ist nicht dazu verpflichtet, die Inhalte dieser Website zu aktualisieren. Die Nutzung dieser Website bzw. der Zugang zu ihr erfolgt auf eigenes Risiko des Besuchers. Der Herausgeber, seine Kunden oder Partner sind nicht verantwortlich für Schäden, wie z.B. direkte, indirekte, zufällige oder Folgeschäden, die angeblich durch den Besuch dieser Website verursacht wurden und übernehmen folglich keine Haftung für solche Schäden.

Der Herausgeber übernimmt auch keine Verantwortung oder Haftung für den Inhalt und die Verfügbarkeit von Websites Dritter, die über externe Links von dieser Website aus erreicht werden können. Für den Inhalt der verlinkten Seiten sind ausschliesslich deren Betreiber verantwortlich. Der Herausgeber distanziert sich daher ausdrücklich von allen fremden Inhalten, die möglicherweise straf- oder haftungsrechtlich relevant sind oder gegen die guten Sitten verstossen.

Der Autor übernimmt keine Gewähr für die Richtigkeit, Genauigkeit, Aktualität, Zuverlässigkeit und Vollständigkeit der Informationen.

Haftungsansprüche gegen den Autor wegen Schäden materieller oder immaterieller Art, die aus dem Zugriff oder der Nutzung bzw. Nichtnutzung der veröffentlichten Informationen, durch Missbrauch der Verbindung oder durch technische Störungen entstanden sind, werden ausgeschlossen.

Alle Angebote sind freibleibend. Der Autor behält es sich ausdrücklich vor, Teile der Seiten oder das gesamte Angebot ohne gesonderte Ankündigung zu verändern, zu ergänzen, zu löschen oder die Veröffentlichung zeitweise oder endgültig einzustellen.

### Google WebFonts

Diese Website verwendet für die einheitliche Darstellung von Schriftarten sogenannte Webfonts, die von Google bereitgestellt werden. Wenn Sie eine Seite aufrufen, lädt Ihr Browser die benötigten Web Fonts in seinen Browser-Cache, um Texte und Schriften korrekt darzustellen. Wenn Ihr Browser keine Web Fonts unterstützt, wird eine Standardschriftart von Ihrem Computer verwendet.

Weitere Informationen zu Google Web Fonts finden Sie unter https://developers.google.com/fonts/faq und in den Datenschutzbestimmungen von Google: https://www.google.com/policies/privacy/

### Google TagManager

Der Google Tag Manager ist eine Lösung, mit der wir über eine Schnittstelle sogenannte Website-Tags verwalten und so z.B. Google Analytics und andere Google-Marketingdienste in unser Online-Angebot integrieren können. Der Tag Manager selbst, der die Tags implementiert, verarbeitet keine personenbezogenen Daten der Nutzer. Hinsichtlich der Verarbeitung personenbezogener Daten der Nutzer verweisen wir auf die folgenden Informationen zu den Google-Diensten. Verwendungsrichtlinien: https://www.google.com/intl/de/tagmanager/use-policy.html.

### Änderungen

Wir können diese Datenschutzrichtlinie jederzeit ohne vorherige Ankündigung ändern. Es gilt die jeweils aktuelle, auf unserer Website veröffentlichte Fassung. Soweit die Datenschutzerklärung Teil einer Vereinbarung mit Ihnen ist, werden wir Sie im Falle einer Aktualisierung per E-Mail oder auf andere geeignete Weise über die Änderung informieren.

_Quelle Text: [BrainBox Solutions](https://brainbox.swiss/datenschutz-generator-schweiz/)_

` // end of md string
