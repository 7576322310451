import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Input, Modal } from 'antd'
import { useTranslation } from 'react-i18next'
import { Document, TenantDocument } from '../../@types'
import { ApiRequestConfig, useApiRequest } from '../../hooks'
import { InputLayout } from '../content/component/utils'
import { Heading } from '../typography'

import css from './CreateUpdateModal.module.css'

type ModalProps = Parameters<typeof Modal>[0]
type CreateUpdateModalProps<T extends Document & TenantDocument> = {
  /**
   * id of the entity to edit. Null if a new one is to be created.
   */
  id: string | null

  /**
   * Names of the fields to include in the form
   */
  fields: Array<
    { name: keyof Omit<T, '_id'>; nullable?: boolean } & { layout?: Parameters<typeof InputLayout>[0] } & {
      input?:
        | ({
            type?: 'text'
          } & Parameters<typeof Input>[0])
        | ({ type: 'password' } & Parameters<(typeof Input)['Password']>[0])
    }
  >
  /**
   * Name of the model (used also for API calls)
   */
  modelName: string
  /**
   *  Handler function for closing the modal
   */
  onClose?: (
    e?: React.MouseEvent,
    triggerRefresh?: boolean,
    context?: { document?: T } & Record<string, unknown>
  ) => void

  /**
   * Additional data from context to extend/seed form data
   */
  context?: Record<string, unknown>

  /** indicates that the modal should wait for context to load */
  loading?: boolean
} & ModalProps

export const CreateUpdateModal = <T extends Document & TenantDocument>(
  props: CreateUpdateModalProps<T>
): JSX.Element => {
  const { id, modelName, open, onClose, context, loading: contextLoading = false } = props
  const { t } = useTranslation('admin', { keyPrefix: `${modelName}` })
  const [formData, setFormData] = useState<Partial<T>>({})

  const getModel = useMemo<ApiRequestConfig>(
    () => ({
      method: 'GET',
      url: `/${modelName}/${id}`,
      skip: !id,
    }),
    [modelName, id]
  )
  const { data: getData, loading: getLoading, error: getError, flush } = useApiRequest<T>(getModel)

  const createOrUpdateModel = useMemo(
    (): ApiRequestConfig<Partial<T>> => ({
      method: id ? 'PATCH' : 'POST',
      url: id ? `/${modelName}/${id}` : `/${modelName}`,
      skip: true,
      data: formData,
    }),
    [modelName, id, formData]
  )
  const {
    loading: createOrUpdateLoading,
    data: createOrUpdateData,
    error: createOrUpdateError,
    fire: fireCreateOrUpdate,
    flush: flushCreateOrUpdate,
  } = useApiRequest<T, Partial<T>>(createOrUpdateModel)

  const handleInput = useCallback(
    (event: React.FormEvent<HTMLInputElement>, nullable = false) => {
      setFormData({ ...formData, [event.currentTarget.id]: event.currentTarget.value || (nullable ? null : undefined) })
    },
    [formData]
  )

  const handleCancel = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      setFormData({})
      flush()
      onClose && onClose(event)
    },
    [onClose, flush]
  )

  useEffect(() => {
    if (getData && !getError) setFormData({ ...context, ...getData })
  }, [getData, getError, context])

  useEffect(() => {
    if (createOrUpdateData && !createOrUpdateError && open && onClose) {
      onClose(undefined, true, { document: createOrUpdateData })
      setFormData({})
      flushCreateOrUpdate()
    }
  }, [createOrUpdateData, createOrUpdateError, open, onClose, flushCreateOrUpdate])

  const fields = props.fields.map(({ layout, input, name, nullable }) => {
    const { type = 'text', ...inputProps } = input || {}
    return (
      <InputLayout {...layout} key={name.toString()} label={t(name.toString())} loading={getLoading || contextLoading}>
        {type === 'text' && (
          <Input
            {...inputProps}
            className={css['input']}
            id={name.toString()}
            value={formData[name] as string}
            onInput={
              inputProps.onInput
                ? (e) => {
                    inputProps?.onInput?.(e)
                    handleInput(e, nullable)
                  }
                : (e) => {
                    handleInput(e, nullable)
                  }
            }
            disabled={createOrUpdateLoading || getLoading || contextLoading || inputProps.disabled}
          />
        )}
        {type === 'password' && (
          <Input.Password
            {...inputProps}
            className={css['input']}
            id={name.toString()}
            value={formData[name] as string}
            onInput={
              inputProps.onInput
                ? (e) => {
                    inputProps?.onInput?.(e)
                    handleInput(e, nullable)
                  }
                : (e) => {
                    handleInput(e, nullable)
                  }
            }
            disabled={createOrUpdateLoading || getLoading || contextLoading || inputProps.disabled}
          />
        )}
      </InputLayout>
    )
  })

  return (
    <Modal
      {...props}
      onOk={fireCreateOrUpdate}
      onCancel={handleCancel}
      okButtonProps={{ loading: createOrUpdateLoading, disabled: getLoading || contextLoading }}
    >
      <Heading level={3}>{id ? t('edit') : t('create')}</Heading>
      {fields}
    </Modal>
  )
}
