import React, { ReactNode, useMemo } from 'react'
import css from './Heading.module.css'

interface HeadingProps {
  className?: string

  /** the heading level (1-6) */
  level?: number
  children?: ReactNode
}

export const Heading = (props: HeadingProps): JSX.Element => {
  const { className: propsClassName, children, level } = props

  const className = useMemo(() => {
    if (propsClassName) return `${css['heading']} ${propsClassName}`
    return css['heading']
  }, [propsClassName])

  if (level === 6) return <h6 className={className}>{children}</h6>
  if (level === 5) return <h5 className={className}>{children}</h5>
  if (level === 4) return <h4 className={className}>{children}</h4>
  if (level === 3) return <h3 className={className}>{children}</h3>
  if (level === 2) return <h2 className={className}>{children}</h2>
  return <h1 className={className}>{children}</h1>
}
