import React from 'react'
import { Consultation, Training } from '../../../@types'
import { Button } from 'antd'
import { ConsultationEdit, ConsultationRemove, ReportSelect } from '../consultation'
import { useNavigate } from 'react-router-dom'
import { TrainingStatus, TrainingStatusProps } from './TrainingStatus'
import css from './TrainingItem.module.css'
import { FileDoneOutlined, PlaySquareOutlined } from '@ant-design/icons'
import { useCan, useLocalize } from '../../../hooks'
import { useTranslation } from 'react-i18next'

interface TrainingItemProps {
  consultation: Consultation | null
  training: Training
  status?: TrainingStatusProps['status']
  onChange?: () => void
}

export const TrainingItem = (props: TrainingItemProps): JSX.Element => {
  const { consultation, training, status, onChange } = props
  const navigate = useNavigate()
  const canConsultationRemove = useCan('consultation:remove', { document: consultation })
  const { localize } = useLocalize()
  const { t } = useTranslation('exercises', { keyPrefix: 'trainings.item' })

  return (
    <div className={css['item']}>
      <div>{localize(training.displayName)}</div>

      <TrainingStatus className={css['status']} status={status} assessment={training.assessment} />

      <div className={css['actions']}>
        {canConsultationRemove && !!consultation && (
          <ConsultationRemove
            consultation={consultation._id}
            onDone={() => {
              onChange && onChange()
            }}
            confirm={{ title: t('remove.title'), description: <p>{t('remove.description')}</p> }}
          />
        )}
        {consultation?.training && (status === 'success' || status === 'error' || !training.assessment) && (
          <ReportSelect
            reports={consultation.training.reports}
            consultation={consultation._id}
            button={{
              type: status === 'success' || status === 'error' ? 'primary' : 'default',
              children: !training.assessment ? null : t('report'),
              icon: <FileDoneOutlined />,
            }}
          />
        )}
        {consultation && (status === 'incomplete' || status === 'ignore') && (
          <ConsultationEdit consultation={consultation?._id}>
            {
              <Button
                type={status === 'incomplete' || status === 'ignore' ? 'primary' : 'default'}
                icon={<PlaySquareOutlined />}
              >
                {t('continue')}
              </Button>
            }
          </ConsultationEdit>
        )}
        {!consultation && (
          <Button
            type='primary'
            icon={<PlaySquareOutlined />}
            onClick={() => {
              navigate(`/consultation?training=${training._id}`)
            }}
          >
            {t('start')}
          </Button>
        )}
      </div>
    </div>
  )
}
