import { Button, Layout } from 'antd'
import { CloseOutlined, MenuFoldOutlined } from '@ant-design/icons'
// react-div-100vh fixes the ios toolbar 100vh bug/feature
import { use100vh } from 'react-div-100vh'
import React, {
  useState,
  useEffect,
  ReactNode,
  PropsWithChildren,
  useMemo,
  createContext,
  useRef,
  useContext,
  CSSProperties,
} from 'react'
import { useBreakpoints } from '../../hooks'
import { HeaderNavigation } from '../navigation'
import css from './BasicLayout.module.css'
import logos from '../../svg'
import { useIntro } from '../../contexts'

const { Header, Content, Footer, Sider } = Layout

export interface BasicLayoutProps {
  /** sider top content. If sider is set via context, it has priority over props sider */
  sider?: ReactNode
  /** sider navigation content (sider bottom content) */
  navigation?: ReactNode
  /** icon of the sider collapse toggle button */
  menuButtonIcon?: ReactNode
  /** css styles for the main content frame */
  contentFrameStyle?: React.CSSProperties
  /** width of the non-collapsed sider (default: `SIDER_WIDTH = 250`) */
  siderWidth?: CSSProperties['width']
  /** indicates if the sider overlaps the content (default: `ltMD`) */
  overlaps?: boolean
  /** to forcfully hide sider */
  hideSider?: boolean
  /** set `true` to trigger sider collapse */
  toggleSider?: boolean
  /** event if sider gets toggled */
  onToggleSider?: (siderCollapsed: boolean) => void
}

interface BasicLayoutContextInterface {
  contentFrameRef: React.MutableRefObject<HTMLElement | null>
  HEADER_HEIGHT: number
  FOOTER_HEIGHT: number
  setTitle: React.Dispatch<React.SetStateAction<string | undefined>>

  /** Set sider content. If sider is set via context, it has priority over props sider */
  setSider: React.Dispatch<React.SetStateAction<ReactNode | undefined>>

  /** if the sider is collapsed or not (relevant if sider overlaps) */
  siderCollapsed: boolean

  /** if the sider overlaps the content */
  overlaps: boolean
}

const BasicLayoutContext = createContext<BasicLayoutContextInterface | undefined>(undefined)

const BasicLayout: React.FC<PropsWithChildren<BasicLayoutProps>> = (props) => {
  const SIDER_COLLAPSED_WIDTH = 60
  const SIDER_WIDTH = 250
  const HEADER_HEIGHT = 64
  const FOOTER_HEIGHT = 70

  const {
    children,
    sider: propsSider,
    navigation,
    contentFrameStyle,
    menuButtonIcon = <MenuFoldOutlined />,
    siderWidth = SIDER_WIDTH,
    overlaps: propsOverlaps,
    hideSider = false,
    toggleSider,
    onToggleSider,
  } = props

  const { ltMD } = useBreakpoints()
  /** indicates if sider overlaps content (`true`) or is next to content (`false`) */
  const overlaps = useMemo<boolean>(() => (propsOverlaps !== undefined ? propsOverlaps : ltMD), [ltMD, propsOverlaps])
  const [siderCollapsed, setSiderCollapsed] = useState<boolean>(overlaps)
  const contentFrameRef = useRef<HTMLElement | null>(null)
  const screenHeight = use100vh()
  const [title, setTitle] = useState<string | undefined>(undefined)
  const [stateSider, setSider] = useState<ReactNode | undefined>(undefined)
  const { sider: introNeedsSider, active } = useIntro()

  useEffect(() => {
    if (active) {
      if (introNeedsSider && siderCollapsed) {
        setSiderCollapsed(false)
      } else if (introNeedsSider === false && !siderCollapsed && overlaps) {
        setSiderCollapsed(true)
      }
    }
  }, [introNeedsSider, active, siderCollapsed, overlaps])

  // if sider is set via context, it has priority over props sider
  const sider = useMemo(() => stateSider ?? propsSider, [stateSider, propsSider])

  useEffect(() => {
    // this avoids scrolling in body
    document.body.classList.add(css['no-overflow'])

    return () => {
      document.body.classList.remove(css['no-overflow'])
    }
  })

  useEffect(() => {
    if (!overlaps && siderCollapsed) setSiderCollapsed(false)
  }, [overlaps, siderCollapsed])

  useEffect(() => {
    if (overlaps && toggleSider) {
      setSiderCollapsed((state) => !state)
    }
  }, [overlaps, toggleSider])

  useEffect(() => {
    onToggleSider && onToggleSider(siderCollapsed)
  }, [siderCollapsed, onToggleSider])

  const hasSider = useMemo(() => (!!sider || !!navigation) && !hideSider, [sider, navigation, hideSider])

  const value = useMemo<BasicLayoutContextInterface>(
    () => ({
      contentFrameRef,
      HEADER_HEIGHT,
      FOOTER_HEIGHT,
      setTitle,
      setSider,
      siderCollapsed,
      overlaps,
    }),
    [contentFrameRef, siderCollapsed, overlaps]
  )

  const mainClassName = useMemo(() => {
    const classes = [css['main']]
    if (hasSider && overlaps && !siderCollapsed) classes.push(css['blur'])
    return classes.join(' ')
  }, [hasSider, overlaps, siderCollapsed])

  return (
    <BasicLayoutContext.Provider value={value}>
      <Layout className={css['basic-layout']}>
        {/* TODO: figure out what the controls the header height? */}
        <Header className={css['header']} style={{ maxHeight: `${HEADER_HEIGHT}px` }}>
          <HeaderNavigation title={title} />
        </Header>
        {/* TODO: replace mt-16 with HEADER_HEIGHT */}
        <Layout hasSider={hasSider} className='mt-16'>
          <Layout
            style={{
              overflowY: 'auto',
              marginRight: hasSider && !overlaps ? siderWidth : 0,
              height: `calc(${screenHeight}px - ${HEADER_HEIGHT}px)`,
              display: 'block',
              ...contentFrameStyle,
            }}
            className={mainClassName}
            onClick={
              overlaps && !siderCollapsed
                ? () => {
                    setSiderCollapsed(true)
                  }
                : undefined
            }
            ref={contentFrameRef}
          >
            <Content style={{ minHeight: `calc(${screenHeight}px - ${HEADER_HEIGHT}px - ${FOOTER_HEIGHT}px)` }}>
              {children}
            </Content>
            <Footer className={css['footer']} style={{ maxHeight: FOOTER_HEIGHT }}>
              <div>Powered by</div>
              <div className='pt-1'>
                <a
                  href='https://embrio.tech/en'
                  target='_blank'
                  rel='noreferrer'
                  title='EMBRIO.tech | Digitale Lösungsbauer | Zürich'
                  tabIndex={-1}
                >
                  <logos.EmbrioCompactDark className='h-6 inline' />
                </a>
              </div>
            </Footer>
          </Layout>

          {hasSider && (
            <Sider
              className={css['sider']}
              collapsible
              collapsed={siderCollapsed}
              theme='light'
              collapsedWidth={overlaps ? 0 : SIDER_COLLAPSED_WIDTH}
              width={overlaps ? '80vw' : siderWidth}
              trigger={null}
            >
              <div className={css['sider-container']}>
                <div className={css['content']} id='content'>
                  {sider}
                </div>

                {navigation && (
                  <div className={css['navigation']} id='navigation'>
                    {navigation}
                  </div>
                )}
              </div>
            </Sider>
          )}
        </Layout>
        {hasSider && overlaps && (
          <Button
            id='sider-toggle'
            className={css['sider-collapse-btn']}
            style={{ top: `calc(${HEADER_HEIGHT}px + 1rem)` }}
            shape='circle'
            size='large'
            onClick={() => setSiderCollapsed(!siderCollapsed)}
          >
            {siderCollapsed ? menuButtonIcon : <CloseOutlined />}
          </Button>
        )}
      </Layout>
    </BasicLayoutContext.Provider>
  )
}

const useBasicLayout = (): BasicLayoutContextInterface => {
  const context = useContext(BasicLayoutContext)
  if (!context) {
    throw new Error('useBasicLayout must be inside a Provider with a value')
  }
  return context
}

export { BasicLayout, useBasicLayout }
