import React from 'react'
import ReactDOM from 'react-dom/client'
import { App } from './components'
import './index.css'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import reportWebVitals from './reportWebVitals'
import { ErrorContextProvider, FileContextProvider, MessageContextProvider, ThemeContextProvider } from './contexts'
// react-div-100vh fixes the ios toolbar 100vh bug/feature
import Div100vh from 'react-div-100vh'
import './config/i18n.ts'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <>
    <React.StrictMode>
      {/* <StyleProvider hashPriority='high'> */}
      <ThemeContextProvider>
        <MessageContextProvider>
          <ErrorContextProvider>
            <FileContextProvider>
              <Div100vh id='div100vh'>
                <App />
              </Div100vh>
            </FileContextProvider>
          </ErrorContextProvider>
        </MessageContextProvider>
      </ThemeContextProvider>
      {/* </StyleProvider> */}
    </React.StrictMode>
  </>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
