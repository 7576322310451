/* eslint-disable no-useless-escape */
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import css from './AdminEmployees.module.css'
import dayjs from 'dayjs'
import { ApiRequestConfig, useApiRequest, useBranches, useCan, useUsers } from '../../../../../../hooks'
import { Employee, EmployeeEmail, UpdateEmployee } from '../../../../../../@types'
import { useTranslation } from 'react-i18next'
import { Button, Modal, Table, TableProps, Tooltip } from 'antd'
import {
  DisconnectOutlined,
  EditOutlined,
  InfoCircleFilled,
  PlusOutlined,
  QuestionCircleFilled,
  StopOutlined,
} from '@ant-design/icons'
import { CreateUpdateModal } from '../../../../../admin/CreateUpdateModal'
import { useEnvironment } from '../../../../../../contexts'

export const AdminEmployees: React.FC = (): JSX.Element => {
  const { t } = useTranslation('admin', { keyPrefix: 'employees' })
  const [exec, setExec] = useState<boolean | undefined>(undefined)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const {
    environment: { APP_IDENTITY_CLIENT_ID },
  } = useEnvironment()
  const [modalProps, setModalProps] = useState<{ open: boolean; id: string | null; context?: Record<string, unknown> }>(
    {
      open: false,
      id: null,
    }
  )
  const [patchEmployee, setPatchEmployee] = useState<UpdateEmployee | null>(null)
  const [modal, modalOutlet] = Modal.useModal()

  const canCreateEmployee = useCan('employee:create')

  const PAGE_SIZE = 5
  const getEmployees = useMemo<ApiRequestConfig>(
    () => ({
      method: 'GET',
      url: '/employees',
      skip: !exec,
      params: {
        limit: PAGE_SIZE,
        skip: (currentPage - 1) * PAGE_SIZE,
        sort: { name: 1 },
      },
    }),
    [currentPage, exec]
  )

  const { data: employees, loading: employeesLoading, count: employeesCount } = useApiRequest<Employee[]>(getEmployees)

  const patchEmployeeConfig = useMemo<ApiRequestConfig<UpdateEmployee>>(
    () => ({
      method: 'PATCH',
      url: `/employees/${patchEmployee?._id}`,
      skip: !patchEmployee,
      data: patchEmployee ?? undefined,
    }),
    [patchEmployee]
  )
  const {
    data: patchData,
    loading: patchLoading,
    error: patchError,
  } = useApiRequest<Employee, UpdateEmployee>(patchEmployeeConfig)

  const columns: TableProps<TableData>['columns'] = [
    {
      title: t('name'),
      dataIndex: 'name',
      key: 'name',
      render: (name: string) => <span className={css['primary']}>{name}</span>,
    },
    {
      title: t('branch'),
      dataIndex: 'branch',
      key: 'branch',
      className: css['optional'],
      render: (branch: string | null) => branch && <span className={css['secondary']}>{branch}</span>,
    },
    {
      title: t('lastSeenAt'),
      dataIndex: 'lastSeenAt',
      key: 'lastSeenAt',
      className: css['optional'],
      render: (date: string) => <span className={css['secondary']}>{dayjs(date).fromNow()}</span>,
    },
    {
      title: t('emailLogin'),
      dataIndex: 'email',
      className: css['optional'],
      key: 'email',
      render: (_, record) =>
        record.user && (
          <>
            {!record.email ? (
              <span className={css['pending']}>
                <Tooltip title={t('pending.description')}>
                  <InfoCircleFilled />
                </Tooltip>{' '}
                {t('pending.label')}
              </span>
            ) : (
              <span className={css['secondary']}>{record.email}</span>
            )}{' '}
            <Button
              type='text'
              size='small'
              danger
              icon={<DisconnectOutlined />}
              title={t('unlink.label')}
              aria-label={t('unlink.label')}
              onClick={() => {
                modal.confirm({
                  title: t('unlink.title'),
                  content: <p>{t('unlink.description')}</p>,
                  icon: <QuestionCircleFilled className='text-warning' />,
                  onOk: () => {
                    setPatchEmployee({ _id: record._id, __v: record.__v, user: null })
                  },
                  okText: t('unlink.ok'),
                  cancelText: t('unlink.cancel'),
                  okButtonProps: { danger: true },
                })
              }}
            />
          </>
        ),
    },
    {
      key: 'action',
      align: 'right',
      render: (_: unknown, record: TableData) => (
        <div className={css['actions']}>
          <Button
            id={record._id}
            icon={<EditOutlined />}
            onClick={(e) => {
              toggleModal(e, undefined, record)
            }}
          />
          <Button
            id={record._id}
            type={record.blocked ? 'primary' : undefined}
            danger
            icon={<StopOutlined />}
            onClick={toggleBlockEmployee}
          />
        </div>
      ),
    },
  ]

  const toggleModal = useCallback(
    (event?: React.MouseEvent, triggerRefresh = false, context?: Record<string, unknown>) => {
      const id = event?.currentTarget.id
      setModalProps({ id: id ?? null, open: !modalProps.open, context })
      if (triggerRefresh) setExec(true)
    },
    [modalProps]
  )

  const toggleBlockEmployee = useCallback(
    (event: React.MouseEvent) => {
      const _id = event.currentTarget.id
      const employee = employees?.find((e) => e._id === _id)
      if (employee) setPatchEmployee({ ...employee, blocked: !employee.blocked })
    },
    [employees]
  )

  useEffect(() => {
    if (patchData && !patchLoading && !patchError) {
      setExec(true)
      setPatchEmployee(null)
    }
  }, [patchData, patchLoading, patchError])

  // first load of list when component is initialized
  useEffect(() => {
    if (exec === undefined) {
      setExec(true)
    }
  }, [exec])

  // mark fetch as completed when list here
  useEffect(() => {
    if (employees) {
      setExec(false)
    }
  }, [employees])

  // exec load when page changes
  useEffect(() => {
    setExec(true)
  }, [currentPage])

  const userIds = useMemo<string[]>(
    () => employees?.filter(({ user }) => !!user).map(({ user }) => user as string) || [],
    [employees]
  )
  const { users, loading: usersLoading } = useUsers(userIds)

  const branchIds = useMemo<string[]>(
    () => employees?.filter(({ branch }) => !!branch).map(({ branch }) => branch as string) || [],
    [employees]
  )
  const { branches, loading: branchesLoading } = useBranches(branchIds)

  const tableData = useMemo<TableData[]>(
    () =>
      employees?.map(
        ({ user, branch, ...rest }): TableData => ({
          ...rest,
          user,
          branch: branches.find(({ _id }) => _id === branch)?.name || null,
          email: users
            .filter(({ registrations }) =>
              // remove users with unverified registration
              !!registrations?.find(({ applicationId }) => applicationId === APP_IDENTITY_CLIENT_ID)?.verified
            )
            .find(({ id }) => id === user)?.email,
        })
      ) || [],
    [employees, users, branches, APP_IDENTITY_CLIENT_ID]
  )

  return (
    <>
      <div className={css['table-actions']}>
        <Tooltip title={!canCreateEmployee ? t('tooltipCreate') : undefined}>
          <Button
            icon={<PlusOutlined />}
            onClick={(e) => {
              toggleModal(e)
            }}
            type='primary'
            disabled={!canCreateEmployee}
          />
        </Tooltip>
      </div>
      <Table
        loading={employeesLoading || patchLoading || usersLoading || branchesLoading}
        columns={columns}
        rowKey='_id'
        dataSource={tableData}
        pagination={{
          total: employeesCount,
          pageSize: PAGE_SIZE,
          current: currentPage,
          onChange: (page) => setCurrentPage(page),
          size: 'small',
        }}
      />

      <CreateUpdateModal<Employee & EmployeeEmail>
        {...modalProps}
        modelName='employees'
        fields={[
          { name: 'name', layout: { required: true } },
          {
            name: 'pin',
            input: {
              type: 'password',
              maxLength: 6,
              // prettier-ignore
              pattern: '^\d{0,6}$',

              onInput: (e) => {
                // only process digits
                if (!/^\d{0,6}$/.test(e.currentTarget.value)) e.currentTarget.value = e.currentTarget.value.slice(0, -1)
              },
            },
            layout: { description: t('pinDescription'), required: !modalProps.id },
          },
          { name: 'email', layout: { description: t('emailDescription') } },
        ]}
        onCancel={toggleModal}
        onClose={toggleModal}
      />
      {modalOutlet}
    </>
  )
}

type TableData = Record<string, unknown> & Omit<Employee, 'branch'> & Partial<EmployeeEmail> & { branch: string | null }
