import React, { createContext, useMemo, useContext, PropsWithChildren, useRef, useCallback, useEffect } from 'react'
import type { FileMeta } from '../@types'

export type CacheItem = { src: string; meta: FileMeta }

export type Cache = Record<string, CacheItem>

interface FileContextInterface {
  /**
   * store a file's meta and src (object url) to cache
   *
   * @memberof FileContextInterface
   */
  toCache: (key: string, value: CacheItem) => void

  /**
   * read a files cache entry
   *
   * @memberof FileContextInterface
   */
  fromCache: (key: string) => CacheItem | undefined
}

const FileContext = createContext<FileContextInterface | undefined>(undefined)

const FileContextProvider: React.FC<PropsWithChildren> = (props) => {
  const { children } = props
  const cacheRef = useRef<Cache>({})

  const toCache = useCallback<FileContextInterface['toCache']>((key, value) => {
    if (cacheRef.current[key]) {
      const src = cacheRef.current[key]?.src
      if (src) URL.revokeObjectURL(src)
    }
    cacheRef.current[key] = value
  }, [])

  const fromCache = useCallback<FileContextInterface['fromCache']>((key) => {
    return cacheRef.current[key]
  }, [])

  // delete all cache entries when context provider gets destroyed
  useEffect(() => {
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      Object.entries(cacheRef.current).forEach(([key, { src }]) => {
        URL.revokeObjectURL(src)
        // eslint-disable-next-line react-hooks/exhaustive-deps
        delete cacheRef.current[key]
      })
    }
  }, [])

  const value = useMemo<FileContextInterface>(
    () => ({
      toCache,
      fromCache,
    }),
    [toCache, fromCache]
  )

  return <FileContext.Provider value={value}>{children}</FileContext.Provider>
}

/**
 * provides access to the file cache
 *
 * @return {*}  {FileContextInterface}
 */
const useFile = (): FileContextInterface => {
  const context = useContext(FileContext)
  if (!context) {
    throw new Error('useFile must be inside a Provider with a value')
  }
  return context
}

export { FileContextProvider, useFile }
