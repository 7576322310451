import { AutoComplete, Input, Modal } from 'antd'
import React, { MouseEvent, useEffect, useMemo, useState } from 'react'
import css from './AuthDevice.module.css'
import { InputLayout } from '../content/component/utils'
import { ApiRequestConfig, useApiRequest, useDebounce } from '../../hooks'
import { Branch, Device, ObjectId } from '../../@types'
import { useAuth, useMessage } from '../../contexts'
import { useTranslation } from 'react-i18next'
import { DownOutlined, SearchOutlined } from '@ant-design/icons'

interface AuthDeviceProps {
  show?: boolean
  onCancel?: (e: MouseEvent<HTMLButtonElement>) => void
  onAuthenticated?: (device: Device) => void
}

export const AuthDevice = (props: AuthDeviceProps): JSX.Element => {
  const { show = false, onCancel, onAuthenticated } = props
  const [name, setName] = useState<string | undefined>(undefined)
  const [branch, setBranch] = useState<ObjectId | undefined>()
  const [nameTouched, setNameTouched] = useState<boolean>(false)
  const { message } = useMessage()
  const { setDevice, device } = useAuth()
  const { t } = useTranslation('admin', { keyPrefix: 'device.authenticate' })

  const createDevice = useMemo<ApiRequestConfig<Partial<Device>>>(
    () => ({
      method: 'POST',
      url: '/devices',
      data: {
        name,
        branch,
      },
      skip: true,
    }),
    [name, branch]
  )
  const { data: newDevice, loading, fire: fireCreateDevice } = useApiRequest(createDevice)

  useEffect(() => {
    if (newDevice && name && !device) {
      message.success('Gerät authentifiziert.')
      setDevice(newDevice)
      setName(undefined)
      setNameTouched(false)
      setBranchTouched(false)
      setBranch(undefined)
      setSelected(null)
      onAuthenticated && onAuthenticated(newDevice)
    }
  }, [newDevice, onAuthenticated, message, name, setDevice, device])

  const nameValidation = useMemo(
    () => (nameTouched && !name ? 'Das Gerät brauch einen Namen.' : undefined),
    [name, nameTouched]
  )

  const [search, setSearch] = useState<string | null>(null)
  const [selected, setSelected] = useState<string | null>(null)
  const [branchInputFocused, setBranchInputFocused] = useState<boolean>(false)
  const [branchTouched, setBranchTouched] = useState<boolean>()
  const debouncedSearch = useDebounce(search)

  const branchValidation = useMemo(
    () => (branchTouched && search && !branch ? 'Leer lassen oder existierende Filiale auswählen.' : undefined),
    [branch, branchTouched, search]
  )

  const listBranches = useMemo<ApiRequestConfig>(
    () => ({
      method: 'GET',
      url: '/branches',
      skip: !show,
      params: {
        search: debouncedSearch
          ? {
              name: debouncedSearch,
              city: debouncedSearch,
              street: debouncedSearch,
            }
          : undefined,
      },
    }),
    [debouncedSearch, show]
  )
  const { data: branches, flush: flushBranches } = useApiRequest<Branch[]>(listBranches)

  const options = useMemo(
    () =>
      branches?.map(({ name, _id }) => ({
        label: name,
        value: _id,
      })),
    [branches]
  )

  return (
    <Modal
      title={t('title')}
      open={show}
      onOk={() => {
        fireCreateDevice()
      }}
      onCancel={(e) => {
        setName(undefined)
        setNameTouched(false)
        setBranchTouched(false)
        setSearch(null)
        setBranch(undefined)
        setSelected(null)
        flushBranches()
        onCancel && onCancel(e)
      }}
      cancelText={t('cancel')}
      okText={t('ok')}
      okButtonProps={{ loading, disabled: !!nameValidation || !!branchValidation || !name }}
      cancelButtonProps={{ loading }}
    >
      <div className={css['content']}>
        <p>{t('description')}</p>
        <InputLayout
          label={t('name')}
          status={nameValidation ? 'error' : undefined}
          validation={nameValidation}
          required={true}
        >
          <Input
            className={css['input']}
            value={name}
            onChange={(e) => {
              setName(e.target.value)
            }}
            onBlur={() => {
              setNameTouched(true)
            }}
            status={nameValidation ? 'error' : undefined}
          />
        </InputLayout>
        <InputLayout
          label={t('branch.label')}
          required
          status={branchValidation ? 'error' : undefined}
          validation={branchValidation}
        >
          <AutoComplete
            className={css['input']}
            value={search || selected}
            options={options}
            onSearch={(search) => {
              if (search) {
                setSearch(search)
              } else {
                setSearch(null)
              }
            }}
            onSelect={(selected) => {
              setSearch(null)
              setBranch(selected)
              setSelected(options?.find(({ value }) => value === selected)?.label || null)
            }}
            onClear={() => {
              setBranchTouched(false)
              setSelected(null)
              setSearch(null)
              setBranch(undefined)
            }}
            onBlur={() => {
              setBranchTouched(true)
            }}
            allowClear
          >
            <Input
              suffix={<span className='opacity-25'>{branchInputFocused ? <SearchOutlined /> : <DownOutlined />}</span>}
              onFocus={() => {
                setBranchInputFocused(true)
              }}
              onBlur={() => {
                setBranchInputFocused(false)
              }}
            />
          </AutoComplete>
        </InputLayout>
      </div>
    </Modal>
  )
}
