import React, { ReactNode, useMemo } from 'react'
import { Outlet } from 'react-router-dom'
import { useCan } from '../../hooks'
import { SpinPlaceholder } from '../utils'
import { useAuth, useIdentity } from '../../contexts'
import { Alert, Button } from 'antd'
import { EmployeePin } from './EmployeePin'
import css from './Protect.module.css'
import { Heading } from '../typography'
import { useTranslation } from 'react-i18next'

interface ProtectProps {
  children?: ReactNode

  /** operation to test permission for */
  operation: string

  /** additional permission context for useCan hook */
  context?: Record<string, unknown>

  /** if a OAuth2 user is required to access route */
  requireUser?: boolean

  /** if a employee is required to access route */
  requireEmployee?: boolean

  /** if a public user can access despite required user or employee (only needed if) */
  allowPublic?: boolean

  /** if authentication options should be showed */
  showAuth?: boolean
}

export const Protect = (props: ProtectProps): JSX.Element => {
  const {
    children,
    operation,
    context,
    requireUser = false,
    requireEmployee = false,
    allowPublic = false,
    showAuth = false,
  } = props
  const { user, employee, device } = useAuth()
  const { authenticate } = useIdentity()
  const { t } = useTranslation()

  const permission = useCan(operation, context)

  const showLogin = useMemo<boolean>(() => user === null && requireUser, [user, requireUser])

  const showPin = useMemo<boolean>(
    () => employee === null && !!device && requireEmployee && user === null,
    [requireEmployee, employee, device, user]
  )

  const loading = useMemo(
    () => user === undefined || employee === undefined || device === undefined,
    [user, device, employee]
  )

  const authOk = useMemo<boolean>(() => {
    return (
      (!requireEmployee && !requireUser && !allowPublic) ||
      (requireUser && !!user) ||
      (requireEmployee && !!device && !!employee) ||
      (allowPublic && !device && !user && !employee)
    )
  }, [requireEmployee, requireUser, allowPublic, user, employee, device])

  if (permission && authOk) {
    return <>{children ? children : <Outlet />}</>
  }

  return (
    <>
      {loading ? (
        <SpinPlaceholder id='protect' />
      ) : (
        showAuth && (
          <div className={css['container']}>
            {!authOk && (
              <div>
                <Heading level={2}>{t('protect.authenticate.title')}</Heading>
                <p>{t('protect.authenticate.description')}</p>
              </div>
            )}
            {permission === false && authOk && (
              <Alert
                message={t('protect.noAccess.title')}
                description={t('protect.noAccess.description', { operation })}
                type='error'
              />
            )}
            {showPin && <EmployeePin />}
            {showLogin && (
              <div>
                <Heading level={4}>{t('protect.userLogin.title')}</Heading>
                <Button
                  type='primary'
                  size='large'
                  onClick={() => {
                    window.localStorage.setItem('pathname', location.pathname)
                    authenticate()
                  }}
                >
                  {t('protect.userLogin.button')}
                </Button>
              </div>
            )}
          </div>
        )
      )}
    </>
  )
}
