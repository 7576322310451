import React, { forwardRef, useMemo } from 'react'
import { Alert, AlertProps } from 'antd'
import type { ComponentProps } from '../../../../@types'
// import './Fallback.css'

export const Fallback = forwardRef<HTMLDivElement, ComponentProps>(function Fallback(
  props: ComponentProps,
  ref
): JSX.Element {
  const { type, className, name } = props
  const alertProps = useMemo(
    () => ({
      message: 'Missing component implementation!',
      description: `The component  <${type} /> is not implemented for step ${name}.`,
      type: 'warning' as AlertProps['type'],
    }),
    [type, name]
  )

  return (
    <div ref={ref} className={className}>
      <Alert {...alertProps} />
    </div>
  )
})
