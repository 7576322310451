import React from 'react'
import { CardContainer, Markdown } from '../../../utils'
import { Heading } from '../../../typography'
import { legalNotice } from '../../../../config/legal'
// import css from './LegalNotice.module.css'

export const LegalNotice = (): JSX.Element => {
  return (
    <CardContainer>
      <h1 className='text-primary'>Impressum</h1>
      <Heading level={1} className='mt-4 mb-6'>
        <div className='font-heading font-medium text-5xl'>
          <span className='text-dark'>phar</span>
          <span className='text-primary'>Me</span>
          <span className='text-dark'>.ch</span>
        </div>
      </Heading>
      <p className='text-lg font-medium text-gray-600 mb-8'>
        Eine Web-Applikation der Universität Basel und der Firma EMBRIO.tech
      </p>
      <Markdown>{legalNotice}</Markdown>
    </CardContainer>
  )
}
