import React from 'react'
import css from './FlowsSider.module.css'
import { TrialSwitch } from '../../utils'
import { useCan } from '../../../hooks'

interface FlowsSiderProps {
  className?: string
}

export const FlowsSider = (props: FlowsSiderProps): JSX.Element => {
  const { className } = props

  const canTrialToggle = useCan('trial:toggle')

  return (
    <div className={className ? `${css['container']} ${className}` : css['container']}>
      {canTrialToggle && <TrialSwitch />}
    </div>
  )
}
