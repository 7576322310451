import React, { forwardRef } from 'react'
import type { Localized, ComponentProps } from '../../../../@types'
import { Alert as AntAlert, AlertProps } from 'antd'
import { useLocalize } from '../../../../hooks'
// import css from './Alert.module.css'

export const Alert = forwardRef<HTMLDivElement, ComponentProps<AlertMeta>>(function Alert(
  props: ComponentProps<AlertMeta>,
  ref
): JSX.Element {
  const { meta, className } = props
  const { type = 'info', text } = meta
  const { localize } = useLocalize()

  return (
    <div ref={ref} className={className}>
      <AntAlert showIcon message={localize(text)} type={type} />
    </div>
  )
})

interface AlertMeta {
  text?: Localized
  type?: AlertProps['type']
}
