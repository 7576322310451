import React, { useMemo } from 'react'
import { ObjectId, Report } from '../../../@types'
import { Button, ButtonProps, Dropdown } from 'antd'
import { useNavigate } from 'react-router-dom'
import { DownOutlined } from '@ant-design/icons'
import { useBreakpoints, useLocalize } from '../../../hooks'
import { useTranslation } from 'react-i18next'
import css from './ReportSelect.module.css'

interface ReportSelectProps {
  reports: Report[]
  consultation: ObjectId
  button?: ButtonProps
}

export const ReportSelect = (props: ReportSelectProps): JSX.Element => {
  const { reports, consultation: consultationId, button } = props
  const navigate = useNavigate()
  const { localize } = useLocalize()
  const { t } = useTranslation('report', { keyPrefix: 'select' })
  const { ltSM, isMD } = useBreakpoints()

  const items = useMemo(
    () =>
      reports.map(({ _id: reportId, name }) => ({
        key: `/consultations/${consultationId}/reports/${reportId}`,
        label: localize(name),
        type: 'primary',
      })),
    [reports, consultationId, localize]
  )

  const showAsDropdown = useMemo(
    () => items.length > 2 || (items.length > 1 && (ltSM || isMD)),
    [items.length, ltSM, isMD]
  )

  const buttonProps = useMemo(
    () => ({ children: showAsDropdown ? t('reports') : undefined, ...button }),
    [button, t, showAsDropdown]
  )

  return items.length ? (
    showAsDropdown ? (
      <Dropdown
        menu={{
          items,
          onClick: ({ key }) => {
            if (key) navigate(key)
          },
        }}
        placement='bottomRight'
        trigger={['click']}
      >
        <Button id='report-select' icon={<DownOutlined />} type='primary' {...buttonProps} />
      </Dropdown>
    ) : (
      <div id='report-select' className={css['set']}>
        {items.map(({ label, key }, index) => (
          <Button
            type={index === items.length - 1 ? 'primary' : 'default'}
            {...buttonProps}
            key={key}
            onClick={() => {
              if (key) navigate(key)
            }}
          >
            {label}
          </Button>
        ))}
      </div>
    )
  ) : (
    <></>
  )
}
