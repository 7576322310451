import React from 'react'
import Joyride, { CallBackProps, Step } from 'react-joyride'
import { colors } from '../../../config'
import { Tooltip } from './Tooltip'
import { useTranslation } from 'react-i18next'
// import css from './Intro.module.css'

interface IntroProps {
  /** control if intro is running or not */
  run: boolean
  steps: Step[]
  callback?: (data: CallBackProps) => void
  stepIndex: number
}

export function Intro(props: IntroProps): JSX.Element {
  const { run, steps, callback, stepIndex } = props
  const { t } = useTranslation('intro', { keyPrefix: 'controls' })

  return (
    <Joyride
      callback={callback}
      run={run}
      stepIndex={stepIndex}
      steps={steps}
      tooltipComponent={Tooltip}
      showProgress
      showSkipButton
      continuous
      locale={{
        back: t('back'),
        close: t('close'),
        last: t('last'),
        next: t('next'),
        open: t('open'),
        skip: t('skip'),
      }}
      styles={{
        options: {
          primaryColor: colors.primary.DEFAULT,
        },
      }}
      disableOverlayClose={true}
    />
  )
}
