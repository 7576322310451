import React, { forwardRef, useMemo } from 'react'
import type { ComponentProps } from '../../../@types'
import * as implementations from './implementations'
// import './Component.css'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ComponentSelect = forwardRef<any, ComponentProps<any>>(function ComponentSelect(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  props: ComponentProps<any>,
  ref
): JSX.Element {
  const { type, position, name } = props
  const Selected = useMemo(() => {
    return implementations[type] || implementations['Fallback']
  }, [type])

  return <Selected key={`${position}-${name}`} {...props} ref={ref} />
})
