import React from 'react'
import { ExerciseWithConsultations } from '../../../@types'
import { Card } from '../../utils'
import { Button } from 'antd'
import css from './ExerciseCard.module.css'
import { ReadOutlined } from '@ant-design/icons'
import { ExerciseStatusProps } from './ExerciseStatus'
import { useLocalize } from '../../../hooks'
import { useTranslation } from 'react-i18next'

interface ExerciseCardProps {
  className?: string
  exercise: ExerciseWithConsultations
  status?: ExerciseStatusProps['status']
  onOpen: (exercise: ExerciseWithConsultations) => void
}

export const ExerciseCard = (props: ExerciseCardProps): JSX.Element => {
  const { className, exercise, onOpen } = props
  const { displayName, trainings } = exercise
  const { localize } = useLocalize()
  const { t } = useTranslation('exercises', { keyPrefix: 'card' })

  return (
    <Card className={className}>
      <div className={css['content']}>
        <div className={css['title']}>{localize(displayName)}</div>

        <div className={css['info']}>
          {!!trainings.length && (
            <>
              {trainings.length}&nbsp;{t('trainings')}
            </>
          )}
          {/* {exercise.credits}&nbsp;{t('credits')} */}
        </div>
        {/* TODO: uncomment to show status when FPH is comming and import status from props */}
        {/* <ExerciseStatus className={css['status']} status={status} /> */}
        <div>{/* TODO: remove placeholder div */}</div>
        <div className={css['actions']}>
          <Button
            type='primary'
            icon={<ReadOutlined />}
            onClick={() => {
              onOpen(exercise)
            }}
          >
            {t('button')}
          </Button>
        </div>
      </div>
    </Card>
  )
}
