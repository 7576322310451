import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useRef } from 'react'
import type { ComponentProps, Localized } from '../../../../@types'
import { Switch } from 'antd'
import { useConsultation } from '../../../../contexts'
import css from './Check.module.css'
import { CheckOutlined, ExclamationOutlined } from '@ant-design/icons'
import { Description, Label } from '../../../typography'
import { useLocalize } from '../../../../hooks'

export const Check = forwardRef<unknown, ComponentProps<CheckMeta>>(function Check(
  props: ComponentProps<CheckMeta>,
  ref
): JSX.Element {
  const { meta, data, name, className, status, validation, isActive, isRequired, touched, onFocus, onBlur } = props
  const { update, loading } = useConsultation()
  const { localize } = useLocalize()

  const localRef = useRef<HTMLButtonElement | null>(null)

  useImperativeHandle(ref, () => localRef.current)

  useEffect(() => {
    // if is active but state not yet saved, update state
    if (data !== undefined && data[name] === undefined && isActive) {
      update({ data: { [name]: false } })
    }
  }, [isActive, data, name, update])

  const onChange = (checked: boolean) => {
    localRef.current?.blur()
    // emit input change
    update({ data: { [name]: checked } })
  }

  useEffect(() => {
    if (localRef.current && onFocus) {
      const element = localRef.current

      element.addEventListener('focus', onFocus)

      return () => {
        element.removeEventListener('focus', onFocus)
      }
    }
  }, [isActive, onFocus, name])

  useEffect(() => {
    if (localRef.current && onBlur) {
      const element = localRef.current

      element.addEventListener('blur', onBlur)

      return () => {
        element.removeEventListener('blur', onBlur)
      }
    }
  }, [isActive, onBlur, name])

  const validationClassName = useMemo(() => {
    const classNames: string[] = ['block']
    if (status === 'warning') classNames.push('text-warning')
    if (status === 'error') classNames.push('text-error')
    return classNames.join(' ')
  }, [status])

  return (
    <div className={className ? `${className} ${css['check']}` : css['check']}>
      <div className={css['info']}>
        <Label required={isRequired} className='block'>{localize(meta.label)}</Label>
        {touched && !!validation && <Description className={validationClassName}>{validation}</Description>}
      </div>
      <Switch
        ref={localRef}
        checked={data?.[name] as boolean}
        onChange={onChange}
        loading={isActive && loading}
        checkedChildren={<CheckOutlined />}
        unCheckedChildren={touched && validation ? <ExclamationOutlined /> : undefined}
        className={touched && validation && status ? `bg-${status}` : undefined}
      />
    </div>
  )
})

interface CheckMeta {
  label?: Localized
}
