import React, { forwardRef, useMemo } from 'react'
import { Select as AntSelect } from 'antd'
import type { BaseSelectRef } from 'rc-select'
import type { ComponentProps, Localized, SelectOption, SelectOptions } from '../../../../@types'
import css from './Select.module.css'
import { useConsultation } from '../../../../contexts'
import { InputLayout } from '../utils'
import { useLocalize } from '../../../../hooks'

type SelectMeta = { options: SelectOptions; label?: Localized; description?: Localized }

export const Select = forwardRef<BaseSelectRef, ComponentProps<SelectMeta>>(function Select(
  props: ComponentProps<SelectMeta>,
  ref
): JSX.Element {
  const { meta, className, name, status, validation, data, isRequired, touched, onFocus, onBlur, isActive } = props
  const { options: metaOptions, label, description } = meta
  const { update, loading } = useConsultation()
  const { localize } = useLocalize()

  const options = useMemo(
    () =>
      (metaOptions ?? []).map(({ label, value }) => {
        if (typeof label === 'string') return { label, value }
        return { value, label: localize(label) }
      }),
    [metaOptions, localize]
  )

  const value = useMemo(() => (data?.[name] as undefined | SelectOption)?.value, [data, name])

  return (
    <InputLayout
      className={className}
      label={localize(label)}
      description={localize(description)}
      status={touched ? status : undefined}
      validation={touched ? validation : undefined}
      required={isRequired}
      loading={isActive && loading}
    >
      <AntSelect
        ref={ref}
        options={options}
        value={value}
        className={css['input']}
        status={touched ? status : undefined}
        allowClear
        onChange={(value: string | undefined) => {
          update({ data: { [name]: metaOptions.find(({ value: optionValue }) => value === optionValue) } })
        }}
        onBlur={onBlur}
        disabled={loading}
        onFocus={onFocus}
      />
    </InputLayout>
  )
})
