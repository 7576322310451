import type { StepConfig } from '../../@types'

export const managerSteps: StepConfig[] = [
  {
    key: 'authRequirements',
    location: {
      pathname: '/',
    },
    target: '#app',
    placement: 'center',
    disableScrolling: true,
    sider: false,
  },
  {
    key: 'admin',
    location: {
      pathname: '/admin/employees',
    },
    target: '#sider-navigation #admin',
    placement: 'top',
    disableScrolling: false,
    sider: true,
  },
  {
    key: 'adminEmployees',
    location: {
      pathname: '/admin/employees',
    },
    target: '#admin-nav #employees',
    placement: 'auto',
    disableScrolling: false,
    sider: false,
  },
  {
    key: 'adminDevice',
    location: {
      pathname: '/admin/device',
    },
    target: '#admin-nav #device',
    placement: 'auto',
    disableScrolling: false,
  },
]
