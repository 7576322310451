import { useEffect, useState, useCallback, RefObject } from 'react'

interface Size {
  width: number
  height: number
  ratio: number
}

export const useSize = (
  ref: RefObject<HTMLElement | SVGSVGElement>,
  callback?: (entry: DOMRectReadOnly) => void
): Size => {
  const [size, setSize] = useState<Size>({
    width: 0,
    height: 0,
    ratio: NaN,
  })

  const handleResize = useCallback(
    (entries: ResizeObserverEntry[]) => {
      const [{ contentRect }] = entries
      const { width, height } = contentRect

      setSize(() => ({
        width,
        height,
        ratio: width / height,
      }))

      if (callback) {
        callback(contentRect)
      }
    },
    [callback]
  )

  useEffect(() => {
    if (ref?.current) {
      const resizeObserver = new ResizeObserver((entries: ResizeObserverEntry[]) => handleResize(entries))
      resizeObserver.observe(ref.current)
      return () => {
        resizeObserver.disconnect()
      }
    }
  }, [ref, handleResize])

  return size
}
