import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import Backend from 'i18next-http-backend'
import Detector from 'i18next-browser-languagedetector'
import type { Locale } from '../@types'

export const supportedLocale = ['de-CH', 'fr-CH', 'en-US', 'it-CH'] as const

export const localesMeta: Record<Locale, { short: string; long: string; disabled?: boolean }> = {
  'de-CH': { long: 'Deutsch', short: 'DE' },
  'en-US': { long: 'English', short: 'EN' },
  'fr-CH': { long: 'Français', short: 'FR' },
  'it-CH': { long: 'Italiano', short: 'IT', disabled: true },
}

i18n
  .use(Backend)
  .use(Detector)
  .use(initReactI18next)
  .init({
    debug: false,
    load: 'currentOnly',
    // fallbackLng: supportedLocale[0],
    fallbackLng: false,
    supportedLngs: supportedLocale,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    ns: ['common'],
    defaultNS: 'common',
    backend: {
      loadPath: '/locales/{{ns}}/{{lng}}.json',
    },
    detection: {
      lookupLocalStorage: 'lng',
      lookupSessionStorage: 'lng',
    },
  })

export default i18n
