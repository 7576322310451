import React from 'react'
import css from './TrialSwitch.module.css'
import { useTranslation } from 'react-i18next'
import { useTrial } from '../../contexts'
import { Label } from '../typography'
import { Popover, Switch } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'

interface TrialSwitchProps {
  className?: string
}

export const TrialSwitch = (props: TrialSwitchProps): JSX.Element => {
  const { className } = props
  const { t } = useTranslation('common')
  const { trial, setTrial } = useTrial()

  return (
    <div className={className ? `${className} ${css['check']}` : css['check']}>
      <Label>
        {t('trial.label')}{' '}
        <Popover content={<div className={css['info']}>{t('trial.description')}</div>}>
          {<InfoCircleOutlined className='text-gray-400' />}
        </Popover>
      </Label>
      <Switch
        checked={trial}
        onChange={(value) => {
          setTrial(value)
        }}
      />
    </div>
  )
}
