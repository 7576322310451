import React, { useEffect, useState } from 'react'
import { useQuery } from '../../hooks'
import { useNavigate } from 'react-router-dom'
// import css from './Identity.module.css'
import { SpinPlaceholder } from '../utils'
import { useIdentity } from '../../contexts'

export const Identity = (): JSX.Element => {
  const [params] = useQuery()
  const { ready, callback } = useIdentity()
  const navigate = useNavigate()
  const [handled, setHandled] = useState(false)

  useEffect(() => {
    if (ready && params && !handled) {
      // console.log('––– HANDLE CALLBACK ---')

      callback(params)

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const pathname = window.localStorage.getItem('pathname')
      window.localStorage.removeItem('pathname')
      setHandled(true)

      // TODO: uncomment if redirect to last path desired
      // navigate(pathname || '/')
      navigate('/')
    }
  }, [ready, handled, params, callback, navigate])

  return <SpinPlaceholder fullScreen id='identity' />
}
