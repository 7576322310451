import { Switch } from 'antd'
import React, { useEffect, useMemo, useState } from 'react'
import css from './Handout.module.css'
import { CopyButton, QRCode } from '../../utils'
import { ApiRequestConfig, useApiRequest } from '../../../hooks'
import type { HandoutCreate, Handout as HandoutRead } from '../../../@types'
import { useMessage } from '../../../contexts'
import { useTranslation } from 'react-i18next'

interface HandoutProps {
  className?: string

  /** the handout to init if existing for report and consultation */
  initHandout?: HandoutRead | null

  /** the `_id` of the consultation */
  consultation: string

  /** the `_id` of the corresponding report */
  report: string

  /**
   * event for changes of publish state
   *
   * @param {boolean} published - indicates if the handout is published
   */
  onPublished?: (published: boolean) => void
}

export const Handout = (props: HandoutProps): JSX.Element => {
  const { className, initHandout, report, consultation, onPublished } = props
  const [publish, setPublish] = useState<boolean | undefined>(undefined)
  const [published, setPublished] = useState<boolean | undefined>(undefined)
  const { message } = useMessage()
  const { t } = useTranslation('report')

  const ready = useMemo<boolean>(
    () => published !== undefined && !!report && !!consultation,
    [published, report, consultation]
  )

  useEffect(() => {
    if (initHandout) {
      setPublished(true)
    }
    if (initHandout === null) {
      setPublished(false)
      // automatically publish report handout when opened and not published
      setPublish(true)
    }
  }, [initHandout])

  const createHandout = useMemo<ApiRequestConfig<HandoutCreate>>(
    () => ({
      method: 'POST',
      url: '/handouts',
      skip: !ready || publish !== true || published === true,
      data: {
        report: report as string,
        consultation: consultation as string,
      },
    }),
    [ready, publish, report, consultation, published]
  )
  const {
    data: createdHandout,
    message: createdMessage,
    loading: createLoading,
    flush: flushCreate,
  } = useApiRequest<HandoutRead, HandoutCreate>(createHandout)

  useEffect(() => {
    if (createdMessage) {
      setPublished(true)
      setPublish(undefined)
      onPublished && onPublished(true)
      message.success(t('published'))
    }
  }, [createdMessage, onPublished, message, t])

  const handout = useMemo(() => {
    if (published !== undefined) {
      if (createdHandout && published) {
        return createdHandout
      } else if (initHandout && published) {
        return initHandout
      } else if (!published) {
        return null
      } else {
        return undefined
      }
    }
    return undefined
  }, [published, createdHandout, initHandout])

  const origin = useMemo(() => window.location.origin, [])

  const removeHandout = useMemo<ApiRequestConfig>(
    () => ({
      method: 'DELETE',
      url: `/handouts/${handout?._id}`,
      skip: !ready || publish !== false || published === false || !handout?._id,
    }),
    [publish, ready, handout, published]
  )
  const { message: removedMessage, loading: removeLoading } = useApiRequest<HandoutRead>(removeHandout)

  useEffect(() => {
    if (removedMessage) {
      setPublished(false)
      setPublish(undefined)
      flushCreate()
      onPublished && onPublished(false)
      message.success(t('unpublished'))
    }
  }, [removedMessage, message, onPublished, flushCreate, t])

  const loading = useMemo(() => createLoading || removeLoading, [createLoading, removeLoading])

  const url = useMemo(() => `${origin}/handouts/${handout?.hash}`, [handout, origin])

  return (
    <div id='handout-report' className={className}>
      <div className={css['layout']}>
        <div className={css['control']}>
          <Switch
            loading={loading}
            disabled={!ready}
            checked={published}
            onChange={(value) => {
              setPublish(value)
            }}
          />
          <div className={css['label']}>{t('publish')}</div>
        </div>
        {!!handout && (
          <div id='handout-sharing' className={css['sharing']}>
            <QRCode
              value={url}
              className='max-w-xxxs min-h-xxxs w-full'
              status={loading ? 'loading' : handout ? 'active' : 'expired'}
            />
            <div>
              <CopyButton value={url} />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
