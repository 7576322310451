import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import css from './App.module.css'

import { Identity, Root } from './routes'
import {
  AccessContextProvider,
  ApiContextProvider,
  AuthContextProvider,
  EnvironmentContextProvider,
  IdentityContextProvider,
  IntroContextProvider,
} from '../contexts'
import { Alert } from 'antd'
import { TrialContextProvider } from '../contexts/TrialContext'

const { ErrorBoundary } = Alert

function App() {
  return (
    <div id='app' className={css['container']}>
      <ErrorBoundary>
        <Router>
          <EnvironmentContextProvider>
            <IdentityContextProvider>
              <ApiContextProvider>
                <IntroContextProvider>
                  <Routes>
                    <Route path='/identity' element={<Identity />} />
                    <Route
                      path='*'
                      element={
                        <AuthContextProvider>
                          <AccessContextProvider>
                            <TrialContextProvider>
                              <Root />
                            </TrialContextProvider>
                          </AccessContextProvider>
                        </AuthContextProvider>
                      }
                    ></Route>
                  </Routes>
                </IntroContextProvider>
              </ApiContextProvider>
            </IdentityContextProvider>
          </EnvironmentContextProvider>
        </Router>
      </ErrorBoundary>
    </div>
  )
}

export { App }
