import React from 'react'
import { Markdown } from '../../utils'
import { Heading } from '../../typography'
import { useTranslation } from 'react-i18next'
// import css from './ReportMemo.module.css'

interface ReportMemoProps {
  className?: string
  memo: string
}

export const ReportMemo = (props: ReportMemoProps): JSX.Element => {
  const { className, memo } = props
  const { t } = useTranslation('report')

  return (
    <div className={className}>
      <Heading level={3}>{t('memo')}</Heading>
      <Markdown>{memo}</Markdown>
    </div>
  )
}
