import React, { createContext, useContext, PropsWithChildren, useMemo, useState, useCallback, useEffect } from 'react'
// import { Intro } from '../../components/utils'
import { ACTIONS, CallBackProps, EVENTS, Step } from 'react-joyride'
import type { IntroAppContext, StepConfig } from '../../@types'
import { matchPath, useLocation, useNavigate } from 'react-router-dom'
import { useBreakpoints, useWaitForElement } from '../../hooks'
import { useError } from '../ErrorContext'
import { Intro } from '../../components/utils'
import { useTranslation } from 'react-i18next'

const IntroContext = createContext<IntroContextInterface | undefined>(undefined)

const CONTROL_KEYS = ['back', 'close', 'last', 'next', 'open', 'skip']

const IntroContextProvider: React.FC<PropsWithChildren> = (props) => {
  const { children } = props
  const [intro, setIntro] = useState<StepConfig[]>([])
  const [active, setActive] = useState<boolean>(false)
  const [run, setRun] = useState<boolean>(false)
  const [paused, setPaused] = useState<boolean>(false)
  const [stepIndex, setStepIndex] = useState<number>(0)
  const [context, setContext] = useState<IntroAppContext>({})
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const waitForElement = useWaitForElement()
  const { pushError } = useError()
  const [targetAvailable, setTargetAvailable] = useState<boolean>(false)
  const {
    t,
    i18n: { exists },
  } = useTranslation('intro', { keyPrefix: 'steps' })
  const breakpoints = useBreakpoints()

  const stepConfigs = useMemo(
    () => intro.filter(({ breakpoint }) => !breakpoint || breakpoints[breakpoint]),
    [breakpoints, intro]
  )

  const steps = useMemo<Step[]>(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return stepConfigs.map(({ key, location, ...rest }) => ({
      title: t(`${key}.title`),
      content: t(`${key}.content`),
      locale: Object.fromEntries(
        CONTROL_KEYS.filter((controlKey) => exists(`intro:steps.${key}.controls.${controlKey}`)).map((controlKey) => [
          controlKey,
          t(`${key}.controls.${controlKey}`),
        ])
      ),
      ...rest,
    }))
  }, [stepConfigs, t, exists])

  const hasSteps = useMemo<boolean>(() => !!steps.length, [steps.length])

  const current = useMemo<StepConfig | undefined>(() => {
    return stepConfigs[stepIndex]
  }, [stepIndex, stepConfigs])

  const currentTarget = useMemo<string | undefined>(() => current?.target, [current?.target])
  const currentLocation = useMemo(
    () => (typeof current?.location === 'function' ? current.location(context) : current?.location),
    [current, context]
  )
  const sider = useMemo(() => current?.sider, [current?.sider])

  const atLocation = useMemo<boolean>(() => {
    if (!currentLocation?.pathname) return true
    return !!matchPath(currentLocation.pathname, pathname)
  }, [currentLocation, pathname])

  const play = useCallback(() => {
    if (hasSteps) {
      if (!active) {
        setActive(true)
      }
      if (active && paused) {
        setPaused(false)
      }
    }
  }, [active, paused, hasSteps])

  const pause = useCallback(() => {
    if (active) {
      setPaused(true)
      setRun(false)
    }
  }, [active])

  const stop = useCallback(() => {
    if (active) {
      setRun(false)
      setActive(false)
      setStepIndex(0)
      window.sessionStorage.setItem('intro', 'skip')
    }
  }, [active])

  // navigate to location required by step if not there yet
  useEffect(() => {
    if (active && !paused) {
      if (currentLocation && !atLocation) {
        navigate(currentLocation)
        setTargetAvailable(false)
      }
    }
  }, [active, atLocation, paused, navigate, currentLocation])

  // make sure target element is available
  useEffect(() => {
    if (active && !paused && atLocation && currentTarget) {
      if (!targetAvailable) {
        setRun(false)
        const target = currentTarget
        const controller = new AbortController()

        waitForElement(target, { signal: controller.signal })
          .then(() => {
            setTargetAvailable(true)
          })
          .catch((error) => {
            if (error.name === 'AbortError') {
              console.warn('Aborted element search for: ', target)
            } else {
              pushError(error)
            }
          })

        return () => {
          controller.abort()
        }
      } else {
        setRun(true)
      }
    }
  }, [active, paused, currentTarget, atLocation, targetAvailable, waitForElement, pushError])

  const callback = useCallback(
    (event: CallBackProps) => {
      const { type, action, index, size } = event

      if (action === ACTIONS.NEXT || action === ACTIONS.PREV) {
        if (type === EVENTS.TARGET_NOT_FOUND) {
          // if target is missing set run to false (effects should find target later and resume)
          setRun(false)
          return
        }

        if (type === EVENTS.STEP_AFTER) {
          if (action === ACTIONS.NEXT) {
            if (index === size - 1) {
              stop()
              return
            }
            setStepIndex((last) => Math.min(size - 1, last + 1))
            return
          }

          if (action === ACTIONS.PREV) {
            setStepIndex((last) => Math.max(0, last - 1))
            return
          }

          return
        }
      }

      if (action === ACTIONS.SKIP) {
        stop()
        return
      }

      if (action === ACTIONS.CLOSE) {
        pause()
        return
      }
    },
    [stop, pause]
  )

  const onContext = useCallback<IntroContextInterface['onContext']>((context) => {
    setContext((existing) => ({ ...existing, ...context }))
  }, [])

  const value = useMemo<IntroContextInterface>(
    () => ({
      active,
      paused,
      play,
      stop,
      sider,
      onContext,
      setIntro,
      hasSteps,
    }),
    [active, paused, play, stop, sider, onContext, hasSteps]
  )

  return (
    <IntroContext.Provider value={value}>
      {children}
      <Intro run={run} steps={steps} callback={callback} stepIndex={stepIndex} />
    </IntroContext.Provider>
  )
}

const useIntro = (): IntroContextInterface => {
  const context = useContext(IntroContext)
  if (!context) {
    throw new Error('useIntro must be inside a Provider with a value')
  }
  return context
}

interface IntroContextInterface {
  /**
   * indicates if tour is active
   */
  active: boolean

  /**
   * indicates if tour is paused
   */
  paused: boolean

  /**
   * start or resume intro tour
   */
  play: () => void

  /**
   * stop and reset intro tour
   */
  stop: () => void

  /**
   * if `true` layout sider must be visible
   *
   * @type {boolean}
   * @memberof IntroContextInterface
   */
  sider?: boolean

  /**
   * provide contextual data to the intro steps
   *
   * @memberof IntroContextInterface
   */
  onContext: (context: IntroAppContext) => void

  /** set config for intro steps */
  setIntro: React.Dispatch<React.SetStateAction<StepConfig[]>>

  /** indicates if any intro steps are loaded */
  hasSteps: boolean
}

export { IntroContextProvider, useIntro }
