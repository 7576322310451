import { Button, Dropdown, DropdownProps, MenuProps } from 'antd'
import React, { useMemo, useState } from 'react'
import { useAuth, useIdentity, useMessage } from '../../../../contexts'
import {
  LogoutOutlined,
  UserOutlined,
  LockOutlined,
  UserDeleteOutlined,
  SettingOutlined,
  UserAddOutlined,
  MailOutlined,
} from '@ant-design/icons'
import { useCan } from '../../../../hooks'
import { useTranslation } from 'react-i18next'
import { MenuItemGroupType } from 'antd/es/menu/hooks/useItems'
import { EditEmployee } from './EditEmployee'
// import css from './AuthMenu.module.css'

export const AuthMenu = (): JSX.Element => {
  const { token } = useIdentity()
  const { employee, logout, user, setEmployee } = useAuth()
  const { message } = useMessage()
  const { t } = useTranslation('common', { keyPrefix: 'auth' })
  const [showEditEmployee, setShowEditEmployee] = useState<boolean>(false)

  const canTokenCopy = useCan('token:copy')
  const canAdminEmployee = useCan('admin:employee')

  const userMenu = useMemo<DropdownProps['menu']>(() => {
    const items: MenuProps['items'] = []

    if (user) {
      items.push({
        key: 'user',
        label: t('user.title'),
        type: 'group',
        children: [
          { key: 'userName', icon: <UserOutlined />, label: user.fullName, title: t('user.name') },
          { key: 'userEmail', icon: <MailOutlined />, label: user.email, title: t('user.email') },
        ],
      })
      if (canTokenCopy && token) {
        ;(items[items.length - 1] as MenuItemGroupType)?.children?.push({
          key: 'userToken',
          label: t('user.token'),
          icon: <LockOutlined />,
        })
      }
    }

    if (employee) {
      if (user) {
        items.push({ type: 'divider' })
      }
      items.push({
        key: 'employee',
        label: t('employee.title'),
        type: 'group',
        children: [
          { key: 'employeeName', label: employee.name, icon: <UserDeleteOutlined />, title: t('employee.name') },
        ],
      })
      if (canAdminEmployee) {
        ;(items[items.length - 1] as MenuItemGroupType)?.children?.push({
          key: 'employeeEdit',
          label: t('employee.edit'),
          title: t('employee.edit'),
          icon: <SettingOutlined />,
        })
      }
    }

    if (employee || user) {
      items.push(
        { type: 'divider' },
        { key: 'logout', label: t('logout'), title: t('logout'), icon: <LogoutOutlined /> }
      )
    }

    return {
      onClick: ({ key }) => {
        if (key === 'logout') logout()
        if (key === 'userToken' && token) {
          navigator.clipboard.writeText(token)
          message.info('Access Token copied to clipboard.')
        }
        if (key === 'employeeEdit' && employee) setShowEditEmployee(true)
      },
      items,
    }
  }, [employee, logout, user, canTokenCopy, canAdminEmployee, token, message, t])

  return (
    <>
      <Dropdown menu={userMenu} arrow={true} placement='bottomRight' trigger={['click']}>
        <Button
          id='auth-menu-button'
          type='default'
          shape='circle'
          icon={user ? employee ? <UserAddOutlined /> : <UserOutlined /> : <UserDeleteOutlined />}
        />
      </Dropdown>
      <EditEmployee
        employee={employee}
        show={showEditEmployee}
        onClose={(e, triggerRefresh, context) => {
          const { document } = context || {}
          if (triggerRefresh && document) setEmployee(document)
          setShowEditEmployee(false)
        }}
      />
    </>
  )
}
