import { useMediaQuery } from 'react-responsive'
import { breakpoints } from '../config'
import { useMemo } from 'react'

export type Breakpoint = 'XXS' | keyof typeof breakpoints

interface Breakpoints {
  // match
  isXXS: boolean
  isXS: boolean
  isSM: boolean
  isMD: boolean
  isLG: boolean
  isXL: boolean
  isXXL: boolean

  // greater than
  gtXXS: boolean
  gtXS: boolean
  gtSM: boolean
  gtMD: boolean
  gtLG: boolean
  gtXL: boolean

  // less than
  ltXS: boolean
  ltSM: boolean
  ltMD: boolean
  ltLG: boolean
  ltXL: boolean
  ltXXL: boolean

  // breakpoint range name
  breakpoint: Breakpoint | undefined
}

export type BreakpointKey = keyof Breakpoints

export const useBreakpoints = (): Breakpoints => {
  const { XS, SM, MD, LG, XL, XXL } = breakpoints

  // match
  const isXXS = useMediaQuery({ maxWidth: XS - 1 })
  const isXS = useMediaQuery({ minWidth: XS, maxWidth: SM - 1 })
  const isSM = useMediaQuery({ minWidth: SM, maxWidth: MD - 1 })
  const isMD = useMediaQuery({ minWidth: MD, maxWidth: LG - 1 })
  const isLG = useMediaQuery({ minWidth: LG, maxWidth: XL - 1 })
  const isXL = useMediaQuery({ minWidth: XL, maxWidth: XXL - 1 })
  const isXXL = useMediaQuery({ minWidth: XXL })

  // greater than
  const gtXXS = useMediaQuery({ minWidth: XS })
  const gtXS = useMediaQuery({ minWidth: SM })
  const gtSM = useMediaQuery({ minWidth: MD })
  const gtMD = useMediaQuery({ minWidth: LG })
  const gtLG = useMediaQuery({ minWidth: XL })
  const gtXL = useMediaQuery({ minWidth: XXL })

  // less than
  const ltXS = useMediaQuery({ maxWidth: XS - 1 })
  const ltSM = useMediaQuery({ maxWidth: SM - 1 })
  const ltMD = useMediaQuery({ maxWidth: MD - 1 })
  const ltLG = useMediaQuery({ maxWidth: LG - 1 })
  const ltXL = useMediaQuery({ maxWidth: XL - 1 })
  const ltXXL = useMediaQuery({ maxWidth: XXL - 1 })

  const breakpoint = useMemo<Breakpoint | undefined>(() => {
    if (isXXS) return 'XXS'
    if (isXS) return 'XS'
    if (isSM) return 'SM'
    if (isMD) return 'MD'
    if (isLG) return 'LG'
    if (isXL) return 'XL'
    if (isXXL) return 'XXL'
    return undefined
  }, [isXXS, isXS, isSM, isMD, isLG, isXL, isXXL])

  return {
    isXXS,
    isXS,
    isSM,
    isMD,
    isLG,
    isXL,
    isXXL,
    gtXXS,
    gtXS,
    gtSM,
    gtMD,
    gtLG,
    gtXL,
    ltXS,
    ltSM,
    ltMD,
    ltLG,
    ltXL,
    ltXXL,
    breakpoint,
  }
}
