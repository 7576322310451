import React from 'react'
import { default as ReactMarkdown } from 'react-markdown'
import remarkGfm from 'remark-gfm'
import remarkEmoji from 'remark-emoji'
import rehypeRaw from 'rehype-raw'
import { A, Img, P } from './elements'
// import css from './Markdown.module.css'

interface MarkdownProps {
  className?: string
  children: string
}

export const Markdown = (props: MarkdownProps): JSX.Element => {
  const { className, children } = props

  return (
    <ReactMarkdown
      className={className}
      rehypePlugins={[rehypeRaw]}
      remarkPlugins={[remarkGfm, remarkEmoji]}
      components={{
        p: P,
        img: Img,
        a: A,
      }}
    >
      {children}
    </ReactMarkdown>
  )
}
