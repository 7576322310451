import React, { Component, forwardRef, useEffect, useImperativeHandle, useMemo, useRef } from 'react'
import { ComponentProps, Localized } from '../../../../@types'
import { InputLayout } from '../utils'
import { useConsultation } from '../../../../contexts'
import { DatePicker, TimePicker } from 'antd'
import type { IntRange } from 'rc-picker/lib/interface'
import dayjs, { Dayjs } from 'dayjs'
import 'dayjs/locale/de-ch'
import 'dayjs/locale/fr-ch'
import 'dayjs/locale/it-ch'
import 'dayjs/locale/en'

import type { RangePickerProps } from 'antd/es/date-picker'
import { RulesLogic } from 'json-logic-js'
import { useBreakpoints, useJsonLogic, useLocalize } from '../../../../hooks'
import type { DisabledTime } from 'rc-picker/lib/interface'

import css from './DateInput.module.css'
import { PickerProps } from 'antd/es/date-picker/generatePicker'
import { CommonPickerMethods } from 'antd/es/date-picker/generatePicker/interface'

export const DateInput = forwardRef<unknown, ComponentProps<DateInputMeta>>(function DateInput(
  props: ComponentProps<DateInputMeta>,
  ref
): JSX.Element {
  const { meta, className, name, status, validation, data, isActive, isRequired, touched, onFocus, onBlur } = props
  const {
    showTime = false,
    label,
    description,
    defaultPicker,
    defaultValue,
    disabledDatesRule,
    disabledHours,
    minuteStep = 1,
  } = meta
  const { update, loading, consultationMeta } = useConsultation()
  const { ltXS } = useBreakpoints()
  const { localize } = useLocalize()

  const { apply } = useJsonLogic()
  const dateInputRef = useRef<DateInputRef | null>(null)
  const timeInputRef = useRef<HTMLInputElement | null>(null)

  useImperativeHandle(ref, () => dateInputRef.current)

  // set default value if not set
  useEffect(() => {
    // if is active but state not yet saved, update state
    if (!touched && data !== undefined && data[name] === undefined && isActive && defaultValue) {
      if (defaultValue === 'createdAt' && consultationMeta?.createdAt) {
        update({ data: { [name]: new Date(consultationMeta.createdAt) } })
      } else if (defaultValue === 'updatedAt' && consultationMeta?.updatedAt) {
        update({ data: { [name]: new Date(consultationMeta.updatedAt) } })
      } else if (defaultValue === 'now') {
        update({ data: { [name]: new Date() } })
      } else {
        update({ data: { [name]: new Date(defaultValue) } })
      }
    }
  }, [isActive, data, defaultValue, name, update, consultationMeta, touched])

  // default position of date picker panel
  const defaultPickerValue = useMemo<Dayjs | undefined>(() => {
    if (defaultPicker) {
      if (defaultPicker === 'createdAt' && consultationMeta?.createdAt) return dayjs(consultationMeta.createdAt)
      if (defaultPicker === 'updatedAt' && consultationMeta?.updatedAt) return dayjs(consultationMeta.updatedAt)
      if (defaultPicker === 'now') return dayjs()
      return dayjs(defaultPicker)
    }
    return undefined
  }, [defaultPicker, consultationMeta])

  // rule to evaluete disabled dates
  const disabledDate: undefined | Required<RangePickerProps>['disabledDate'] = useMemo(() => {
    if (!disabledDatesRule) return undefined
    return (current: Dayjs) => {
      const dataKeys = data ? Object.keys(data) : []

      if (!['createdAt', 'updatedAt', 'current', 'now'].every((reservedKey) => !dataKeys.includes(reservedKey))) {
        throw new Error('DateInput: Conflicting step or variable name with reserved data keys!')
      }

      const result = apply(disabledDatesRule, {
        ...data,
        current: current.toDate(),
        createdAt: consultationMeta?.createdAt && new Date(consultationMeta.createdAt),
        updatedAt: consultationMeta?.updatedAt && new Date(consultationMeta.updatedAt),
        now: new Date(),
      })
      if (typeof result !== 'boolean') throw new Error('disabledDatesRule does not return boolean!')
      return result
    }
  }, [disabledDatesRule, consultationMeta, apply, data])

  const disabledTime: undefined | DisabledTime<Dayjs> = useMemo(() => {
    if (!disabledHours || !disabledHours.length) return undefined
    return () => ({
      disabledHours: () => disabledHours,
    })
  }, [disabledHours])

  const stored = useMemo<Date | undefined>(() => {
    if (data?.[name]) {
      if (typeof data[name] === 'number') {
        return new Date(new Date().valueOf() + (data[name] as number))
      }
      return new Date(data[name] as string | Date)
    }

    return undefined
  }, [data, name])

  useEffect(() => {
    if (typeof data?.[name] === 'number') {
      update({ data: { [name]: new Date(new Date().valueOf() + (data[name] as number)) } })
    }
  }, [data, name, update])

  return (
    <InputLayout
      className={className}
      label={localize(label)}
      description={localize(description)}
      status={touched ? status : undefined}
      validation={touched ? validation : undefined}
      required={isRequired}
      loading={isActive && loading}
    >
      <DatePicker
        ref={dateInputRef}
        value={stored ? dayjs(stored) : null}
        // locale={locale}
        className={css['date-input']}
        format={'dd, DD.MM.YYYY'}
        inputReadOnly
        status={touched ? status : undefined}
        disabled={loading}
        disabledDate={disabledDate}
        defaultValue={defaultPickerValue}
        popupClassName={ltXS ? css['centered-popup'] : undefined}
        onChange={(value) => {
          onBlur && onBlur(value)
          if (!value) {
            // if cleared, value === null
            update({ data: { [name]: undefined } })
          } else if (stored && value) {
            // if already an existing date on state, update
            update({
              data: {
                [name]: dayjs(stored)
                  .set('date', value.date())
                  .set('month', value.month())
                  .set('year', value.year())
                  .toDate(),
              },
            })
          } else {
            // else, init new date on state
            update({
              data: {
                [name]: dayjs()
                  .set('date', value.date())
                  .set('month', value.month())
                  .set('year', value.year())
                  .set('minute', (Math.round(new Date().getMinutes() / minuteStep) * minuteStep) % 60)
                  .set('second', 0)
                  .set('millisecond', 0)
                  .toDate(),
              },
            })
          }

          if (showTime) {
            // set focus on time input, if there
            timeInputRef.current?.focus()
          }
        }}
        onBlur={onBlur}
        onFocus={onFocus}
      />

      <TimePicker
        ref={timeInputRef}
        value={stored ? dayjs(stored) : null}
        className={showTime ? css['time-input'] : `${css['time-input']} invisible`}
        format='HH:mm'
        inputReadOnly
        changeOnBlur
        placement='bottomRight'
        status={touched ? status : undefined}
        disabled={loading || !stored}
        minuteStep={minuteStep}
        disabledTime={disabledTime}
        defaultValue={defaultPickerValue}
        allowClear={false}
        popupClassName={ltXS ? css['centered-popup'] : undefined}
        onChange={(value) => {
          if (stored && value) {
            update({
              data: {
                [name]: dayjs(stored).set('hour', value.hour()).set('minute', value.minute()).toDate(),
              },
            })
          } else {
            console.warn('can not set time, because no stored date!')
          }
        }}
        onBlur={onBlur}
        onFocus={onFocus}
      />
    </InputLayout>
  )
})

interface DateInputMeta {
  label?: Localized
  description?: Localized
  /** if time input is required too, default: false */
  showTime?: boolean

  /**
   * time picker minute interval
   * @default 1
   */
  minuteStep?: IntRange<1, 59>

  /** set default date value */
  defaultValue?: 'now' | 'createdAt' | 'updatedAt' | Date

  /** set default picker position */
  defaultPicker?: 'now' | 'createdAt' | 'updatedAt' | Date

  /**
   * JSONLogic Rule that returns a `boolean` for each selectable date (`current`)
   *
   * @returns true for disabled dates
   * @returns false for enabled dates
   *
   * Following arguments/variables can be accessed with `{ "var": ["<ARG>"] }`
   *
   * @arg {DateString} current    - the candidate selectable date
   * @arg {DateString} createdAt  - the cration Date of the consultation
   * @arg {DateString} updatedAt  - the Date the consultation was last updated
   * @arg {DateString} now        - the Date at evaluation
   *
   * To compare dates use custom JSONLogic "date" operation
   */
  disabledDatesRule?: RulesLogic

  /** hours not selectable */
  disabledHours?: number[]
}

type DateInputRef = Component<
  PickerProps<Dayjs> & {
    status?: '' | 'error' | 'warning' | undefined
    hashId?: string | undefined
    popupClassName?: string | undefined
    rootClassName?: string | undefined
  },
  unknown,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  any
> &
  CommonPickerMethods
