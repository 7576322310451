import { useEffect, useState, useCallback } from 'react'
import type { IdentityContextTypes } from '..'
import { useError, useEnvironment } from '..'
import qs from 'qs'

export type Authenticate = () => void

export interface AuthenticateContext {
  verifier: IdentityContextTypes['verifier']
  state: IdentityContextTypes['state']
  callbackUri: IdentityContextTypes['callbackUri']
  token: IdentityContextTypes['token']
  computeHash: (value: string) => Promise<string>
}

export function useAuthenticate(context: AuthenticateContext): Authenticate {
  const { verifier, state, callbackUri, computeHash, token } = context
  const {
    environment: { APP_IDENTITY_URL, APP_IDENTITY_CLIENT_ID, APP_IDENTITY_TENANT_ID },
  } = useEnvironment()
  const { pushError } = useError()

  const [hash, setHash] = useState<string | null>(null)

  useEffect(() => {
    if (verifier) {
      computeHash(verifier)
        .then((hash) => {
          setHash(hash)
        })
        .catch((error) => {
          pushError(error)
        })
    } else {
      setHash(null)
    }
  }, [verifier, pushError, computeHash])

  const authenticate = useCallback<Authenticate>(() => {
    if (!hash || !state || !APP_IDENTITY_CLIENT_ID || !APP_IDENTITY_URL || !APP_IDENTITY_TENANT_ID) {
      pushError(new Error('Can not authenticate because of missing parameters!'))
      return
    }
    if (token) {
      pushError(new Error('Authentication skipped because there is a token already!'))
      return
    }

    const params = {
      client_id: APP_IDENTITY_CLIENT_ID,
      redirect_uri: callbackUri,
      response_type: 'code',
      scope: 'offline_access openid',
      state,
      code_challenge: hash,
      code_challenge_method: 'S256',
      tenantId: APP_IDENTITY_TENANT_ID,
      // locale: 'de',
    }

    const url = `${APP_IDENTITY_URL}/oauth2/authorize?${qs.stringify(params, { encode: true })}`
    window.location.assign(url)
  }, [hash, state, APP_IDENTITY_CLIENT_ID, APP_IDENTITY_URL, APP_IDENTITY_TENANT_ID, pushError, callbackUri, token])

  return authenticate
}
