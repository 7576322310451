import React from 'react'
import css from './ReportSider.module.css'
import { Handout } from './Handout'
import type { Handout as HandoutRead, Report } from '../../../@types'
import { t } from 'i18next'
import { PrintButton } from '../../utils'
import { useCan } from '../../../hooks'

interface ReportSiderProps {
  className?: string
  /** the handout to init if existing for report and consultation */
  initHandout?: HandoutRead | null
  /** the `_id` of the corresponding report */
  report: Report
  /** the `_id` of the consultation */
  consultation: string
  /** indicates if consultation is a training */
  isTraining?: boolean
}

export const ReportSider = (props: ReportSiderProps): JSX.Element => {
  const { className, initHandout, report, consultation, isTraining = false } = props
  const canReportHandout = useCan('report:handout', { report })

  return (
    <div className={className ? `${css['container']} ${className}` : css['container']}>
      {canReportHandout && initHandout !== undefined && !isTraining && (
        <Handout initHandout={initHandout} report={report._id} consultation={consultation} />
      )}
      <div className='text-center'>
        <PrintButton>{t('report.print')}</PrintButton>
      </div>
    </div>
  )
}
