/* eslint-disable @typescript-eslint/no-explicit-any */
import type { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import { useState, useEffect, useRef, useCallback, MutableRefObject } from 'react'
import { useError } from '../contexts'
import type { AppError } from '../@types'

interface Request<ResponseData> {
  /** the response body */
  data?: AxiosResponse<ResponseData>['data']

  /** indicates if request is pending */
  loading: boolean

  /**
   * the error if one. The hook calls `pushError()` internally. You can use `error`
   * to modify the message for example.
   */
  error?: AppError

  /** the response headers */
  headers?: AxiosResponse<ResponseData>['headers']

  /** flush the loaded response data */
  flush: () => void
}

export interface RequestConfig<RequestData = undefined> extends AxiosRequestConfig<RequestData> {
  /** @prop {boolean} skip - if set to true the request is not excecuted */
  skip?: boolean
}

/**
 * hook to perform requests with axios.
 *
 * @arg {object} config - the memorized config object `RequestConfig<RequestData>`
 * @arg {MutableRefObject<AxiosInstance>} axiosRef - the ref to the axios instance to use for the request
 */
export function useRequest<ResponseData = any, RequestData = undefined>(
  config: RequestConfig<RequestData>,
  axiosRef: MutableRefObject<AxiosInstance>
): Request<ResponseData> {
  const controllerRef = useRef<AbortController | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [data, setData] = useState<ResponseData | undefined>(undefined)
  const [headers, setHeaders] = useState<AxiosResponse['headers'] | undefined>(undefined)
  const [error, setLocalError] = useState<AppError | undefined>(undefined)

  const { pushError } = useError()

  const flush = useCallback(() => {
    controllerRef.current?.abort()
    setData(undefined)
    setHeaders(undefined)
    setLocalError(undefined)
    setLoading(false)
  }, [])

  useEffect(() => {
    if (!config.skip) {
      controllerRef.current = new AbortController()
      const controller = controllerRef.current

      // request job
      const request = async () => {
        setLoading(true)

        const { data, headers } = (await axiosRef.current({ ...config, signal: controller.signal })) || {}

        data && setData(data)

        headers && setHeaders(headers)
      }

      // execute job
      request()
        .then(() => {
          setLocalError(undefined)
          setLoading(false)
        })
        .catch((error) => {
          pushError(error)
          setLocalError(error)
          if (error.name !== 'CanceledError') {
            // important: only set loading false if not canceled
            setLoading(false)
          }
        })

      // effect cleanup
      return () => {
        controller.abort()
        controllerRef.current = null
      }
    }
  }, [pushError, config, axiosRef])

  return { data, loading, error, headers, flush }
}
