import { Dispatch, MutableRefObject, useEffect, useRef } from 'react'
import { useError } from '../ErrorContext'
import type { AxiosError, AxiosInstance } from 'axios'
import { useEnvironment } from '../EnvironmentContext'
import axios from 'axios'

export interface IdentityClientContext {
  setExecLogout: Dispatch<React.SetStateAction<boolean>>
}

export function useIdentityClient(context: IdentityClientContext): MutableRefObject<AxiosInstance> {
  const { setExecLogout } = context
  const { pushError } = useError()
  const {
    environment: { APP_IDENTITY_URL, APP_API_URL },
  } = useEnvironment()

  useEffect(() => {
    if (!APP_IDENTITY_URL) pushError(new Error('Environment variable APP_IDENTITY_URL is missing!'))
  }, [APP_IDENTITY_URL, pushError])

  const clientRef = useRef<AxiosInstance>(
    axios.create({
      baseURL: APP_IDENTITY_URL,
      headers: {
        Accept: 'application/json',
      },
    })
  )

  useEffect(() => {
    const client = axios.create({
      baseURL: APP_IDENTITY_URL,
      headers: {
        Accept: 'application/json',
      },
    })
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const interceptor = client.interceptors.response.use(undefined, async (error: AxiosError<any>) => {
      const { response } = error
      const { data } = response || {}

      if (data?.error === 'invalid_grant' && data.error_reason === 'refresh_token_not_found') {
        console.error(data.error_description ?? 'Failed to refresh token!')
        const userId = window.localStorage.getItem('sub')
        if (userId) {
          // revoke refresh tokens if user is known
          await axios({
            method: 'DELETE',
            url: `${APP_API_URL}/users/${userId}/revoke`,
          })
        }
        setExecLogout(true)
      } else if (data?.error === 'invalid_request' && data.error_reason === 'auth_code_not_found') {
        console.error(data.error_description ?? 'Failed to load token!')
        setExecLogout(true)
      }
    })
    clientRef.current = client
    return () => {
      client.interceptors.response.eject(interceptor)
    }
  }, [APP_IDENTITY_URL, APP_API_URL, setExecLogout])

  return clientRef
}
