import { Input } from 'antd'
import React, { CSSProperties, useEffect, useState } from 'react'
import { useDebounce } from '../../hooks'
import { useTranslation } from 'react-i18next'
// import css from './Search.module.css'

const { Search: AntSearch } = Input

interface SearchProps {
  className?: string
  style?: CSSProperties

  /** the search value */
  initValue?: string

  /** debounced callback executed when search value changes */
  onSearch?: (text: string | null) => void

  /**
   * placeholder value
   *
   * @default "Suche..."
   */
  placeholder?: string
}

export const Search = (props: SearchProps): JSX.Element => {
  const { className, style, onSearch, placeholder, initValue } = props
  const [value, setValue] = useState<string | null | undefined>(undefined)
  const { t } = useTranslation('common', { keyPrefix: 'search' })

  const debouncedValue = useDebounce(value)

  useEffect(() => {
    if (initValue && value === undefined) {
      setValue(initValue)
    }
  }, [initValue, value])

  useEffect(() => {
    if (debouncedValue !== undefined) {
      if (onSearch) onSearch(debouncedValue)
    }
  }, [debouncedValue, onSearch])

  return (
    <AntSearch
      value={value || undefined}
      className={className}
      style={style}
      placeholder={placeholder || t('placeholder')}
      allowClear
      onChange={(e) => {
        setValue(e.target.value || null)
      }}
    />
  )
}
