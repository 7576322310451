import React, { useMemo } from 'react'
import css from './ExerciseTrainings.module.css'
import { ExercisePerformance, ExerciseWithConsultations, Training } from '../../../@types'
import { TrainingItem } from './TrainingItem'

interface ExerciseTrainingsProps {
  className?: string
  exercise: ExerciseWithConsultations
  trainings?: Training[]
  performance?: ExercisePerformance['performance']
  onChange?: () => void
}

export const ExerciseTrainings = (props: ExerciseTrainingsProps): JSX.Element => {
  const { className, exercise, trainings = [], performance, onChange } = props

  const trainingsMap = useMemo<Record<string, Omit<Training, '_id'>>>(
    () => Object.fromEntries(trainings.map(({ _id, ...rest }) => [_id, rest])),
    [trainings]
  )

  return (
    <div className={className}>
      <div className={css['list']}>
        {exercise.trainings.map((trainingId, index) => {
          const consultation = exercise.consultations[trainingId]
          const training = trainingsMap[trainingId]

          if (!training) throw new Error(`Training with _id=${trainingId} is missing!`)

          return (
            <TrainingItem
              key={trainingId}
              consultation={consultation}
              training={consultation?.training || { ...training, _id: trainingId }}
              status={performance?.[index]?.status}
              onChange={onChange}
            />
          )
        })}
      </div>
    </div>
  )
}
