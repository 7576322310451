import React, { CSSProperties, ReactNode } from 'react'
import css from './CardContainer.module.css'

interface CardContainerProps {
  className?: string
  children?: ReactNode
  style?: CSSProperties
  id?: string
}

export const CardContainer = (props: CardContainerProps): JSX.Element => {
  const { className, children, style, id } = props

  return (
    <div id={id} className={className ? `${className} ${css['container']}` : css['container']} style={style}>
      <div className={css['card']}>{children}</div>
    </div>
  )
}
