import React, { useEffect, useMemo, useState } from 'react'
import css from './EmployeePin.module.css'
import { Input, Spin } from 'antd'
import { ApiRequestConfig, useApiRequest } from '../../hooks'
import type { Employee } from '../../@types'
import { Heading } from '../typography'
import { useAuth } from '../../contexts'
import { useTranslation } from 'react-i18next'

interface EmployeePinProps {
  className?: string
  onAuthenticated?: (employee: Employee) => void
}

export const EmployeePin: React.FC<EmployeePinProps> = (props) => {
  const { className, onAuthenticated } = props
  const [pin, setPin] = useState<string>('')
  const { setEmployee } = useAuth()
  const { t } = useTranslation()

  const pinReady = useMemo<boolean>(() => pin.length === 6, [pin])

  const authenticate = useMemo<ApiRequestConfig<{ pin: string }>>(
    () => ({
      method: 'POST',
      url: '/employees/authenticate',
      skip: !pinReady,
      data: { pin },
    }),
    [pin, pinReady]
  )
  const { data, loading } = useApiRequest<Employee, { pin: string }>(authenticate)

  useEffect(() => {
    if (data) {
      setEmployee(data)
      onAuthenticated && onAuthenticated(data)
    }
  }, [data, onAuthenticated, setEmployee])

  return (
    <div className={className}>
      <Heading level={4}>{t('protect.employeePin')}</Heading>
      <div className={css['container']}>
        <Spin spinning={loading}>
          <Input.Password
            size='large'
            value={pin}
            autoCapitalize='off'
            placeholder=''
            onChange={(e) => {
              const { value } = e.target
              if (/^\d{0,6}$/.test(value)) {
                setPin(value)
              }
            }}
            className={css['input']}
            pattern='\d*'
            inputMode='numeric'
          />
        </Spin>
      </div>
    </div>
  )
}
