import React, { CSSProperties, ReactNode } from 'react'
import css from './Card.module.css'

interface CardProps {
  className?: string
  children?: ReactNode
  style?: CSSProperties
  id?: string
}

export const Card = (props: CardProps): JSX.Element => {
  const { className, children, style, id } = props

  return (
    <div id={id} className={className ? `${className} ${css['card']}` : css['card']} style={style}>
      {children}
    </div>
  )
}
