import React, { createContext, useMemo, useContext, PropsWithChildren, useEffect, useState, useCallback } from 'react'
import type { Training } from '../../@types'
import { ApiRequestConfig, useApiRequest, useQuery } from '../../hooks'
import { useError } from '../ErrorContext'
import { Spin } from 'antd'

interface TrainingContextInterface {
  /** the training document */
  training: Training | null | undefined
  onCreated: () => void
}

const TrainingContext = createContext<TrainingContextInterface | undefined>(undefined)

const TrainingContextProvider: React.FC<PropsWithChildren> = (props) => {
  const { children } = props
  const { pushError } = useError()
  const [params, setParams] = useQuery()
  const [initialized, setInitialized] = useState<boolean>(false)
  const [training, setTraining] = useState<TrainingContextInterface['training']>(undefined)

  const trainingId = useMemo(() => params.training, [params.training])

  useEffect(() => {
    if (!trainingId) {
      setTraining(null)
    }
    setInitialized(true)
    if (trainingId && typeof trainingId !== 'string') {
      pushError(new Error('Query param training has wrong format!'))
    }
  }, [trainingId, pushError])

  const getTraining = useMemo<ApiRequestConfig>(
    () => ({
      method: 'GET',
      url: `/trainings/${trainingId}`,
      skip: !trainingId || !(training === undefined) || !initialized,
    }),
    [trainingId, training, initialized]
  )
  const { data: loadedTraining, loading, flush } = useApiRequest<Training>(getTraining)

  useEffect(() => {
    if (loadedTraining && trainingId) {
      setTraining(loadedTraining)
    }
  }, [loadedTraining, trainingId, setParams, flush])

  const onCreated = useCallback<TrainingContextInterface['onCreated']>(() => {
    // clear url params from training param after consultation is created
    if (params.training) {
      setParams((oldParams) => {
        return { ...oldParams, training: undefined }
      })
    }
  }, [setParams, params.training])

  const value = useMemo<TrainingContextInterface>(() => ({ training, onCreated }), [training, onCreated])

  return (
    <TrainingContext.Provider value={value}>
      <Spin spinning={loading}>{children}</Spin>
    </TrainingContext.Provider>
  )
}

const useTraining = (): TrainingContextInterface => {
  const context = useContext(TrainingContext)
  if (!context) {
    throw new Error('useTraining must be inside a Provider with a value')
  }
  return context
}

export { TrainingContextProvider, useTraining }
