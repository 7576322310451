import jsonLogic from 'json-logic-js'

interface JsonLogic {
  apply: typeof jsonLogic.apply
  mapData: (data: Record<string, unknown>, nameMap?: Record<string, string>) => Record<string, unknown>
}

const nullishCoalescing = function <First, Second>(first: First, second: Second) {
  return first ?? second
}
jsonLogic.add_operation('??', nullishCoalescing)

const precise = function <First, Second>(first: First, second: Second) {
  const number = Number(first)
  const precision = Math.round(Number(second))
  return number.toPrecision(precision)
}
jsonLogic.add_operation('precise', precise)

/**
 * `"date"` operation to make date strings comparable with logic operators (`===`, `>=`, `!==`, etc.).
 *
 * usage: `{ "date": { "var": ["createdAt"] } }`
 *
 * @returns the the number of milliseconds for this date
 * since the epoch, which is defined as the midnight at
 * the beginning of January 1, 1970, UTC.
 */
const date = function <Arg extends string | number>(arg: Arg) {
  return new Date(arg).valueOf()
}
jsonLogic.add_operation('date', date)

const parseJson = function <Arg extends string>(arg: Arg) {
  return JSON.parse(arg)
}
jsonLogic.add_operation('parseJson', parseJson)

export function useJsonLogic(): JsonLogic {
  return {
    apply: jsonLogic.apply,
    mapData: (data, nameMap) =>
      nameMap ? Object.fromEntries(Object.entries(data).map(([key, value]) => [nameMap[key] || key, value])) : data,
  }
}
