import Handlebars from 'handlebars/dist/handlebars'
import { supportedLocale } from '../config/i18n'

type H = typeof Handlebars

Handlebars.registerHelper('round', function (value: string | number | bigint | undefined, decimals: number): string {
  if (value === undefined) return '\u00a0'
  const _value = Number(value)
  if (isNaN(_value)) return '–\u00a0'
  if (_value === 0) return '0'
  if (_value === Infinity) return '\u221e\u00a0' // infinity symbol
  if (_value === -Infinity) return '-\u221e\u00a0' // infinity symbol
  // eslint-disable-next-line quotes
  return (Math.round(_value * 10 ** decimals) / 10 ** decimals).toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'")
})

Handlebars.registerHelper(
  'abbreviate',
  function (value: string | number | bigint | undefined, precision: number): string {
    const SI_SYMBOLS: { [k: number]: string } = {
      3: 'K',
      6: 'M',
      9: 'B',
      12: 'T',
    }
    if (value === undefined) return '\u00a0'
    const _value = Number(value)
    if (isNaN(_value)) return '–\u00a0'
    if (_value === 0) return '0'
    if (_value === Infinity) return '\u221e\u00a0' // infinity symbol
    if (_value === -Infinity) return '-\u221e\u00a0' // infinity symbol
    const log = Math.floor(Math.log10(Math.abs(Number(_value.toPrecision(precision)))))
    const magnitude = log - (log % 3)
    return `${Number((_value / 10 ** magnitude).toPrecision(precision))}${
      SI_SYMBOLS[magnitude] ? SI_SYMBOLS[magnitude] : magnitude !== 0 ? `e${magnitude}` : ''
    }`
  }
)

Handlebars.registerHelper(
  'date',
  function (value?: Date | string | number, showTime?: boolean | string | number, options?) {
    if (value === undefined) {
      console.warn(`Handlebars: can not apply date helper. Value of ${options?.name} is undefined`)
      return undefined
    }
    const date = new Date(value)
    return date.toLocaleString(supportedLocale[0], {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: showTime ? '2-digit' : undefined,
      minute: showTime ? '2-digit' : undefined,
    })
  }
)

/** turn date into a numerical value for comparability */
Handlebars.registerHelper('valueOf', function (value?: Date | string | number, options?) {
  if (value === undefined) {
    console.warn(`Handlebars: can not apply valueOf helper. Value of ${options?.name} is undefined`)
    return undefined
  }
  return new Date(value).valueOf()
})

/** equality */
Handlebars.registerHelper('eq', function (first, second) {
  return first === second
})

/** greater than */
Handlebars.registerHelper('gt', function (first, second) {
  return first > second
})

/** greater or equal than */
Handlebars.registerHelper('gte', function (first, second) {
  return first >= second
})

/** less than */
Handlebars.registerHelper('lt', function (first, second) {
  return first < second
})

/** less or equal than */
Handlebars.registerHelper('lte', function (first, second) {
  return first <= second
})

/** not equal */
Handlebars.registerHelper('ne', function (first, second) {
  return first !== second
})

/** check if value equal true */
Handlebars.registerHelper('isTrue', function (value) {
  return value === true
})

/** check if value equal false */
Handlebars.registerHelper('isFalse', function (value) {
  return value === false
})

Handlebars.registerHelper('and', function (...args): boolean {
  const options = args[args.length - 1]
  const values = args.slice(0, args.length - 1)
  if (!values.length) {
    console.warn(`Handlebars: "and" helper has empty list of arguments for ${options?.name}.`)
    return false
  }
  if (!values.length) return false
  return values.reduce((acc: boolean, arg) => acc && arg, true)
})

Handlebars.registerHelper('or', function (...args): boolean {
  const options = args[args.length - 1]
  const values = args.slice(0, args.length - 1)
  if (!values.length) {
    console.warn(`Handlebars: "or" helper has empty list of arguments for ${options?.name}.`)
    return false
  }
  return values.reduce((acc: boolean, arg) => acc || arg, false)
})

Handlebars.registerHelper('helperMissing', function (...args) {
  const options = args[args.length - 1]
  console.warn('Missing handlebars helper: ', options?.name)
  return undefined
})

export function useHandlebars(): H {
  return Handlebars
}
