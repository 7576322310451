import { Modal, Spin } from 'antd'
import React, { useEffect, useMemo, useState } from 'react'
import { useApiRequest } from '../../../hooks'
import type { Handover as HandoverDocument } from '../../../@types'
import css from './Handover.module.css'
import { CopyButton, QRCode } from '../../utils'
import { useTranslation } from 'react-i18next'
import { useTrial } from '../../../contexts'

interface HandoverProps {
  className?: string
  execute: boolean
  /** event when done (click on ok) */
  onDone?: () => void
  /** event when handover is aborted */
  onCancel?: () => void
}

/** component to process handover logic and display relevant ui components */
export const Handover: React.FC<HandoverProps> = (props) => {
  const { className, execute, onDone, onCancel } = props
  const [cancel, setCancel] = useState<boolean>(false)
  const [modal, modalOutlet] = Modal.useModal()
  const { t } = useTranslation('consultation', { keyPrefix: 'handover' })
  const { trial } = useTrial()

  const createHandover = useMemo(
    () => ({ method: 'POST', url: '/consultations/handover', skip: !execute || cancel }),
    [execute, cancel]
  )
  const {
    data: dataHandover,
    loading: loadingHandover,
    flush: flushHandover,
  } = useApiRequest<HandoverDocument>(createHandover)

  const code = useMemo(() => dataHandover?.code || '______', [dataHandover])

  const getTakeover = useMemo(
    () => ({ method: 'GET', url: '/consultations/takeover', skip: !cancel || !code, params: { code } }),
    [cancel, code]
  )
  const {
    loading: loadingTakeover,
    message: messageTakeover,
    flush: flushTakeover,
  } = useApiRequest<undefined>(getTakeover)

  useEffect(() => {
    if (messageTakeover) {
      flushHandover()
      flushTakeover()
      setCancel(false)
      onCancel && onCancel()
    }
  }, [messageTakeover, flushHandover, flushTakeover, onCancel])

  const takeback = () => {
    setCancel(true)
  }

  const finish = () => {
    flushHandover()
    flushTakeover()
    onDone && onDone()
  }

  const loading = useMemo(() => loadingHandover || loadingTakeover, [loadingHandover, loadingTakeover])

  const url = useMemo(
    () => code && `${window.location.origin}/consultation/takeover?code=${code}${trial ? '&trial=true' : ''}`,
    [code, trial]
  )

  return (
    <div className={className}>
      <Modal
        title={t('title')}
        open={execute}
        onOk={() => {
          modal.confirm({
            title: t('confirm.title'),
            content: <p>{t('confirm.description')}</p>,
            onOk: () => {
              finish()
            },
            okText: t('confirm.ok'),
            cancelText: t('confirm.cancel'),
          })
        }}
        onCancel={takeback}
        cancelText={t('cancel')}
        okText={t('ok')}
        okButtonProps={{ loading }}
        cancelButtonProps={{ loading }}
      >
        <p>{t('description')}</p>
        <Spin spinning={loading}>
          <div className={css['code-container']}>
            <pre key={code} className={css['code']}>{code}</pre>
            {url && (
              <>
                <QRCode value={url} className='max-w-xxs w-full' />
                <CopyButton value={url} />
              </>
            )}
          </div>
        </Spin>
      </Modal>
      {modalOutlet}
    </div>
  )
}
