import { Button, ButtonProps, Modal } from 'antd'
import React, { useEffect, useMemo, useRef } from 'react'
import { ApiRequestConfig, useApiRequest } from '../../../hooks'
import css from './ConsultationsCSV.module.css'
import { useTranslation } from 'react-i18next'
import { useError } from '../../../contexts'
import { QuestionCircleFilled } from '@ant-design/icons'

interface ConsultationsCSVProps extends ButtonProps {
  query?: Api.ListQuery
}

export const ConsultationsCSV = (props: ConsultationsCSVProps): JSX.Element => {
  const { query, ...buttonProps } = props
  const [modal, modalOutlet] = Modal.useModal()
  const downloadLinkRef = useRef<HTMLAnchorElement | null>(null)
  const { t } = useTranslation('consultations', { keyPrefix: 'csv' })
  const { pushError } = useError()

  const getConsultationsCSV = useMemo<ApiRequestConfig>(
    () => ({
      method: 'GET',
      url: '/consultations/export',
      params: query,
      skip: true,
    }),
    [query]
  )
  const { data, loading, fire, flush } = useApiRequest(getConsultationsCSV)

  useEffect(() => {
    if (data) {
      const blob = new Blob([data], { type: 'text/csv' })
      const url = URL.createObjectURL(blob)

      const name = `${new Date().toISOString().slice(0, 10).replace(/-/g, '')}_pharMe_consultations.csv`

      const downloadLink = downloadLinkRef.current

      if (!downloadLink) {
        pushError(new Error('Missing download link ref!'))
        return
      }

      downloadLink.href = url
      downloadLink.download = name
      downloadLink.click()

      flush()

      return () => {
        URL.revokeObjectURL(url)
      }
    }
  }, [data, pushError, flush])

  return (
    <>
      <Button
        {...buttonProps}
        onClick={() => {
          modal.confirm({
            title: t('modal.title'),
            content: <p>{t('modal.description')}</p>,
            icon: <QuestionCircleFilled className='text-info' />,
            onOk: () => {
              fire()
            },
            okText: t('modal.ok'),
            cancelText: t('modal.cancel'),
          })
        }}
        loading={loading}
      />
      {modalOutlet}
      <a className={css['download-link']} ref={downloadLinkRef} />
    </>
  )
}
