import { useCallback, useEffect } from 'react'
import { IdentityContextTypes, useEnvironment, useError } from '../../contexts'
import qs from 'qs'

export type Logout = () => void

export interface LogoutContext {
  state: IdentityContextTypes['state']
  identity: IdentityContextTypes['identity']
  callbackUri: IdentityContextTypes['callbackUri']
  flushToken: IdentityContextTypes['flushToken']
  execLogout: boolean
}

export function useLogout(context: LogoutContext): Logout {
  const { state, identity, callbackUri, flushToken, execLogout } = context

  const {
    environment: { APP_IDENTITY_URL, APP_IDENTITY_CLIENT_ID, APP_IDENTITY_TENANT_ID },
  } = useEnvironment()
  const { pushError } = useError()

  const logout = useCallback<Logout>(() => {
    if (!state || !APP_IDENTITY_CLIENT_ID || !APP_IDENTITY_URL || !APP_IDENTITY_TENANT_ID) {
      pushError(new Error('Can not logout because of missing parameters!'))
      return
    }

    flushToken()

    const params = {
      client_id: APP_IDENTITY_CLIENT_ID,
      post_logout_redirect_uri: callbackUri,
      id_token_hint: identity ?? undefined,
      state,
      tenantId: APP_IDENTITY_TENANT_ID,
    }

    const url = `${APP_IDENTITY_URL}/oauth2/logout?${qs.stringify(params, { encode: true })}`
    window.location.assign(url)
  }, [
    identity,
    state,
    APP_IDENTITY_CLIENT_ID,
    APP_IDENTITY_URL,
    APP_IDENTITY_TENANT_ID,
    pushError,
    callbackUri,
    flushToken,
  ])

  useEffect(() => {
    if (execLogout) {
      logout()
    }
  }, [execLogout, logout])

  return logout
}
