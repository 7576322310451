import React, { useEffect, useMemo } from 'react'
import { ConsultationContextInterface } from '../../../contexts'
import { Info } from './Info'
// import css from './InfoList.module.css'
import { Empty } from 'antd'

interface InfoListProps {
  className?: string
  consultationContext: ConsultationContextInterface

  /**
   * triggered when info list changes
   *
   * @memberof InfoListProps
   * @param {number[]} activeWithInfo - contains the indexes of currently active steps which have a info component
   */
  onChange?: (activeWithInfo: number[]) => void
}

export const InfoList: React.FC<InfoListProps> = (props) => {
  const { className, consultationContext, onChange } = props
  const { flow, active, visible, data } = consultationContext

  /** All `steps` of the consultation flow  */
  const steps = useMemo(() => flow?.steps || [], [flow])

  /** contains the indexes of currently active steps which have a info component */
  const activeWithInfo = useMemo(() => active.filter((position) => !!steps[position]?.info), [steps, active])

  const infos = useMemo(
    () =>
      activeWithInfo
        .map((position) => {
          const step = steps[position]
          if (!step?.info) throw new Error('Step info is undefined!')
          const { info, name, nameMap } = step
          return (
            <Info
              key={position}
              component={info}
              stepName={name}
              position={position}
              visible={visible.includes(position)}
              nameMap={nameMap}
              data={data}
              className='mb-4'
            />
          )
        })
        .filter((info) => info !== null),
    [activeWithInfo, data, steps, visible]
  )

  useEffect(() => {
    onChange && onChange(activeWithInfo)
  }, [activeWithInfo, onChange])

  return (
    <div className={className}>
      {infos.length ? infos : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description='Keine Infos' />}
    </div>
  )
}
