import { Tabs, TabsProps, Tooltip } from 'antd'
import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react'
import { InfoList } from './InfoList'
import { BugOutlined, EditOutlined, InfoCircleFilled, InfoCircleOutlined, ProfileOutlined } from '@ant-design/icons'
import { Memo } from './Memo'
import { useBreakpoints, useQuery } from '../../../hooks'
import './ConsultationSider.css'
import { ConsultationContextInterface } from '../../../contexts'
import { Text } from '../component/implementations'
import { useTranslation } from 'react-i18next'

interface ConsultationSiderProps {
  className?: string
  consultationContext: ConsultationContextInterface
}

export const ConsultationSider: React.FC<ConsultationSiderProps> = (props) => {
  const { className, consultationContext } = props
  const { ltSM } = useBreakpoints()
  const { flow, data } = consultationContext
  const [activeWithInfo, setActiveWithInfo] = useState<number[]>([])
  const [params] = useQuery()
  const [activeKey, setActiveKey] = useState<string>('info')
  const { t } = useTranslation('consultation')

  const summary = useMemo(() => flow?.summary, [flow])

  const hasInfo = useMemo(() => !!activeWithInfo.length, [activeWithInfo])

  const buildLabel = useCallback(
    (text: ReactNode, icon: ReactNode, key: string) => (
      <span className='tab-label' id={key}>
        {ltSM ? (
          <Tooltip title={text}>{icon}</Tooltip>
        ) : (
          <>
            {icon} {text}
          </>
        )}
      </span>
    ),
    [ltSM]
  )

  const items = useMemo<TabsProps['items']>(() => {
    const items = [
      {
        key: 'info',
        label: buildLabel(
          t('sider.infos'),
          hasInfo ? <InfoCircleFilled className='text-primary' /> : <InfoCircleOutlined />,
          'info'
        ),
        children: (
          <InfoList
            consultationContext={consultationContext}
            onChange={(withInfo) => {
              // if activeWithIntro changes
              if (
                // length changes
                withInfo.length !== activeWithInfo.length ||
                // or content changes
                (withInfo.length &&
                  activeWithInfo.length &&
                  !withInfo.every((position, index) => position === activeWithInfo[index]))
              ) {
                setActiveWithInfo(withInfo)
              }
            }}
          />
        ),
      },
      {
        key: 'memo',
        label: buildLabel(t('sider.comment'), <EditOutlined />, 'memo'),
        children: <Memo consultationContext={consultationContext} />,
      },
    ]

    if (summary?.component) {
      items.splice(0, 0, {
        key: 'summary',
        label: buildLabel(t('sider.summary'), <ProfileOutlined />, 'summary'),
        children: (
          <Text name='summary' position={0} data={data} nameMap={summary.nameMap || undefined} {...summary.component} />
        ),
      })
    }

    if (params.debug) {
      items.push({
        key: 'debug',
        label: buildLabel(t('sider.debug'), <BugOutlined />, 'debug'),
        children: (
          <div>
            <pre>{JSON.stringify(data, null, 2)}</pre>
          </div>
        ),
      })
    }

    return items
  }, [buildLabel, summary, data, consultationContext, hasInfo, params.debug, t, activeWithInfo])

  useEffect(() => {
    if (summary?.component) {
      setActiveKey('summary')
    }
  }, [summary?.component])

  useEffect(() => {
    // when activeWithInfo changes reset active tab depending if info or not
    if (activeWithInfo.length) {
      setActiveKey('info')
    } else {
      setActiveKey('summary')
    }
  }, [activeWithInfo])

  return (
    <div className={className}>
      <Tabs
        activeKey={activeKey}
        items={items}
        className='h-full consultation-sider'
        onTabClick={(key) => {
          setActiveKey(key)
        }}
      />
    </div>
  )
}
