import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Heading } from '../../../typography'
import { Empty, Pagination, PaginationProps, Spin } from 'antd'
import { ApiRequestConfig, useApiRequest, useEmployees, useQuery, useUsers } from '../../../../hooks'
import { Consultation, ObjectId } from '../../../../@types'
import css from './ConsultationsList.module.css'
import { ConsultationCard, ConsultationsSider } from '../../../content/consultation'
import { Container } from '../../../utils'
import { useBasicLayout } from '../../../layouts'
import { useTranslation } from 'react-i18next'
import { useIntro } from '../../../../contexts'

export const ConsultationsList = (): JSX.Element => {
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [refSearch, setRefSearch] = useState<string | null | undefined>(undefined)
  const [initialized, setInitialized] = useState<boolean | undefined>(undefined)
  const { setSider } = useBasicLayout()
  const [params, setParams] = useQuery()
  const { t } = useTranslation('consultations')
  const { onContext } = useIntro()

  // initialize refSearch with ref query param if existing
  useEffect(() => {
    if (refSearch === undefined && params.ref && typeof params.ref === 'string') {
      setRefSearch(params.ref)
    }
  }, [params, refSearch, initialized])

  // onSearch callback to update refSearch
  const onSearch = useCallback((text: string | null) => {
    setRefSearch(text)
  }, [])

  // sync ref query param with refSearch value
  useEffect(() => {
    if (refSearch !== undefined) {
      setParams((oldParams) => ({ ...oldParams, ref: refSearch || undefined }))
    }
  }, [refSearch, setParams])

  const [pageSize, setPageSize] = useState<number>(5)

  const getConsultations = useMemo<ApiRequestConfig>(
    () => ({
      method: 'GET',
      url: '/consultations',
      skip: !initialized,
      params: {
        limit: pageSize,
        skip: (currentPage - 1) * pageSize,
        sort: { updatedAt: -1 },
        search: refSearch ? { ref: refSearch } : undefined,
        project: {
          'flow.steps': 0,
          'flow.variables': 0,
          'flow.summary': 0,
          data: 0,
          memo: 0,
        },
      },
    }),
    [currentPage, refSearch, initialized, pageSize]
  )
  const { data: consultations, count, loading: consultationsLoading } = useApiRequest<Consultation[]>(getConsultations)

  useEffect(() => {
    setSider(
      <ConsultationsSider className='p-6' refSearch={refSearch} onSearch={onSearch} query={getConsultations.params} />
    )
    return () => {
      setSider(undefined)
    }
  }, [setSider, onSearch, refSearch, getConsultations.params])

  const employeeIds = useMemo<ObjectId[]>(
    () =>
      [
        ...new Set(
          consultations
            ?.map(({ createdBy, updatedBy }) => updatedBy || createdBy)
            .filter((value) => typeof value === 'string' && /^[a-f\d]{24}$/.test(value)) || []
        ),
      ] as string[],
    [consultations]
  )

  const { employees, loading: employeesLoading } = useEmployees(employeeIds)

  // TODO: load list of users
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const userIds = useMemo<ObjectId[]>(
    () =>
      [
        ...new Set(
          consultations
            ?.map(({ createdBy, updatedBy }) => updatedBy || createdBy)
            .filter(
              (value) =>
                typeof value === 'string' &&
                /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/.test(value)
            ) || []
        ),
      ] as string[],
    [consultations]
  )

  const { users, loading: usersLoading } = useUsers(userIds)

  const loading = useMemo<boolean>(
    () => consultationsLoading || employeesLoading || usersLoading,
    [consultationsLoading, employeesLoading, usersLoading]
  )

  // pagination
  const pagination = useMemo<PaginationProps>(
    () => ({
      hideOnSinglePage: false,
      current: currentPage,
      pageSize: pageSize,
      total: count,
      size: 'small',
      showSizeChanger: true,
      onChange: (page: number) => {
        setCurrentPage(page || 1)
      },
      onShowSizeChange: (current, size) => {
        setCurrentPage(1)
        setPageSize(size)
      },
      pageSizeOptions: [5, 10, 50],
    }),
    [currentPage, count, pageSize]
  )

  useEffect(() => {
    if (initialized === undefined) {
      setInitialized(true)
    }
  }, [initialized])

  useEffect(() => {
    if (consultations?.length) {
      onContext({ consultation: consultations[0] })
    }
  }, [consultations, onContext])

  return (
    <Container id='consultations-list'>
      <Heading level={2}>{t('title')}</Heading>
      <Spin spinning={loading}>
        {/* <div id='list'> */}
        {consultations?.length ? (
          consultations.map((consultation) => {
            return (
              <ConsultationCard
                key={consultation._id}
                className={css['card']}
                consultation={consultation}
                users={users}
                employees={employees}
              />
            )
          })
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
        {/* </div> */}
      </Spin>
      <div className={css['pagination']}>
        <Pagination {...pagination} />
      </div>
    </Container>
  )
}
