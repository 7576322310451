import { Empty, Spin } from 'antd'
import React, { useEffect, useState, useMemo } from 'react'
import { ApiRequestConfig, useApiRequest } from '../../../../hooks'
import { Flow } from '../../../../@types'
import { Heading } from '../../../typography'
import { Container } from '../../../utils'
// import css from './FlowsList.module.css'
import { FlowCard, FlowsSider } from '../../../content'
import { useTranslation } from 'react-i18next'
import { useBasicLayout } from '../../../layouts'

export const FlowsList = (): JSX.Element => {
  const [loaded, setLoaded] = useState<boolean>(false)
  const { t } = useTranslation('flows')
  const { setSider } = useBasicLayout()

  const getFlows = useMemo<ApiRequestConfig>(
    () => ({
      method: 'GET',
      url: '/flows',
      skip: loaded,
    }),
    [loaded]
  )
  const { data: flows, loading } = useApiRequest<Flow[]>(getFlows)

  useEffect(() => {
    setLoaded(!!flows)
  }, [flows])

  useEffect(() => {
    setSider(<FlowsSider className='p-6' />)
    return () => {
      setSider(undefined)
    }
  }, [setSider])

  return (
    <Container>
      <Spin spinning={loading}>
        <Heading level={2}>{t('title')}</Heading>
        <div id='flows'>
          {flows?.length ? (
            flows?.map((flow) => <FlowCard flow={flow} key={flow._id} className='mb-4' />)
          ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )}
        </div>
      </Spin>
    </Container>
  )
}
