import type { Roles } from '@embrio-tech/easy-rbac-plus'
import type { Role, AccessParams } from '../@types'

/** list of supported roles */
export const roleNames = [
  /** the customer of a pharmacy */
  'customer',
  /** someone who uses the app for training */
  'student',
  /** an employee of a pharmacy */
  'employee',
  /** a manager of a pharmacy */
  'manager',
  /** the owner/admin of a pharmacy chain */
  'owner',
  /** manages flows, exercises and trainings */
  'editor',
  /** uses pharMe data for research purposes */
  'researcher',
  /** a global admin with all permissions for all tenants */
  'god',
] as const

export const permissions: Roles<AccessParams, Role> = {
  customer: {
    can: [
      'consultation:access',
      'consultation:handover',
      'consultation:exitToRoot', // controls exist message and post-exit redirect
    ],
    inherits: [],
  },

  student: {
    can: [
      'sider:view',
      'consultation:access',
      'consultation:close',
      'exercises:view',
      'consultationInfos:view',
      'consultation:takeover',
      'report:view',
      {
        name: 'consultation:remove',
        when: async ({ document, auth }) => {
          const { user } = auth || {}
          return document?.createdBy === user?.id
        },
      },
      'consultation:exitToExercises', // controls exist message and post-exit redirect
    ],
    inherits: [],
  },

  employee: {
    can: [
      'sider:view',
      'consultation:close',
      'consultation:access',
      'consultation:view',
      {
        name: 'consultation:reedit',
        when: async ({ document, auth, trial }) => {
          const { employee, user } = auth || {}
          const { createdBy, updatedBy, expiresAt } = document || {}
          return (
            ((createdBy && employee?._id === createdBy) ||
              (updatedBy && employee?._id === updatedBy) ||
              (createdBy && user?.id === createdBy) ||
              (updatedBy && user?.id === updatedBy)) &&
            (!expiresAt || (expiresAt && trial))
          )
        },
      },
      'consultations:view',
      'report:view',
      'flows:view',
      'consultationInfos:view',
      'consultation:takeover',
      'consultation:exitToArchive', // controls exist message and post-exit redirect
      {
        name: 'report:handout',
        when: async ({ report }) => {
          return report?.access === 'public'
        },
      },
      {
        name: 'report:memoView',
        when: async ({ report }) => {
          return report?.access === 'internal'
        },
      },
      'intro:employee',
      {
        name: 'admin:employee',
        when: async ({ auth }) => {
          const { employee } = auth || {}
          return !!employee
        },
      },
    ],
    inherits: [],
  },

  manager: {
    can: [
      'consultations:view',
      'device:create',
      'device:removeCurrent',
      'intro:manager',
      {
        name: 'employee:create',
        when: async ({ auth }) => {
          return !!auth?.device?.branch
        },
      },
      'admin:employees',
      'admin:device',
      'report:view',
    ],
    inherits: [],
  },

  owner: {
    can: ['consultation:reedit', 'consultation:csv', 'admin:employees', 'employee:create'],
    inherits: ['manager'],
  },

  editor: {
    can: ['token:copy'],
  },

  researcher: {
    can: ['consultation:csv', 'consultations:view', 'token:copy', 'report:view'],
  },

  god: {
    can: ['*:*'],
  },
}
