import React, { forwardRef, useMemo } from 'react'
import type { ComponentProps, Localized } from '../../../../@types'
import { Button } from 'antd'
import css from './BinarySelect.module.css'
import { useConsultation } from '../../../../contexts'
import { CheckOutlined } from '@ant-design/icons'
import { InputLayout } from '../utils'
import { useLocalize } from '../../../../hooks'

export const BinarySelect = forwardRef<HTMLElement, ComponentProps<BinarySelectMeta>>(function BinarySelect(
  props: ComponentProps<BinarySelectMeta>,
  ref
): JSX.Element {
  const { meta, data, name, isRequired, className, status, validation, touched, onFocus, onBlur, isActive } = props
  const { update, loading } = useConsultation()
  const { localize } = useLocalize()

  const value = useMemo<boolean | undefined>(() => data?.[name] as boolean | undefined, [data, name])

  const onClickTrue = () => {
    onBlur && onBlur(undefined)
    // emit input change
    value === true ? update({ data: { [name]: undefined } }) : update({ data: { [name]: true } })
  }

  const onClickFalse = () => {
    onBlur && onBlur(undefined)
    // emit input change
    value === false ? update({ data: { [name]: undefined } }) : update({ data: { [name]: false } })
  }

  return (
    <InputLayout
      className={className}
      label={localize(meta['label'])}
      description={localize(meta['description'])}
      status={touched ? status : undefined}
      validation={touched ? validation : undefined}
      required={isRequired}
      loading={isActive && loading}
    >
      {meta['falseLabel'] && <div className={css['option-label']}>{localize(meta['falseLabel'])}</div>}

      <Button.Group>
        <Button
          ref={ref}
          onClick={onClickFalse}
          shape='default'
          icon={<CheckOutlined className={value === false ? 'visible' : 'invisible'} />}
          onFocus={onFocus}
          disabled={loading}
        />
        <Button
          onClick={onClickTrue}
          shape='default'
          icon={<CheckOutlined className={value === true ? 'visible' : 'invisible'} />}
          onFocus={onFocus}
          disabled={loading}
        />
      </Button.Group>

      {meta['trueLabel'] && <div className={css['option-label']}>{localize(meta['trueLabel'])}</div>}
    </InputLayout>
  )
})

interface BinarySelectMeta {
  label?: Localized
  description?: Localized
  trueLabel?: Localized
  falseLabel?: Localized
}
