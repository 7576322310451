import { EditOutlined } from '@ant-design/icons'
import { Button, Modal, Tooltip } from 'antd'
import React, { ReactNode, useEffect, useMemo, useState } from 'react'
import type { Handover, HandoverCreate, ObjectId } from '../../../@types'
import { ApiRequestConfig, useApiRequest } from '../../../hooks'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
// import css from './ConsultationEdit.module.css'

interface ConsultationEditProps {
  className?: string
  /** the consultation's `_id` */
  consultation: ObjectId

  children?: ReactNode
}

export const ConsultationEdit = (props: ConsultationEditProps): JSX.Element => {
  const { className, consultation, children } = props
  const [prompt, setPrompt] = useState<boolean>(false)
  const [edit, setEdit] = useState<boolean>(false)
  const navigate = useNavigate()
  const { t } = useTranslation('consultations', { keyPrefix: 'edit' })

  const createHandover = useMemo<ApiRequestConfig<HandoverCreate>>(
    () => ({ method: 'POST', url: '/handovers', skip: !edit || !consultation, data: { consultation } }),
    [edit, consultation]
  )
  const { data: handover, loading } = useApiRequest<Handover, HandoverCreate>(createHandover)

  useEffect(() => {
    if (handover) {
      navigate(`/consultation/takeover?code=${handover.code}`)
    }
  }, [handover, navigate])

  return (
    <>
      {children ? (
        <div
          onClick={() => {
            setPrompt(true)
          }}
        >
          {children}
        </div>
      ) : (
        <Tooltip title={t('tooltip')}>
          <Button
            loading={loading || !!handover}
            className={className}
            icon={<EditOutlined />}
            onClick={() => {
              setPrompt(true)
            }}
          />
        </Tooltip>
      )}
      <Modal
        title={t('title')}
        open={prompt}
        onOk={() => {
          setEdit(true)
        }}
        onCancel={() => {
          setPrompt(false)
        }}
        cancelText={t('cancel')}
        okText={t('ok')}
        okButtonProps={{ loading }}
        cancelButtonProps={{ loading }}
      >
        <p>{t('description')}</p>
      </Modal>
    </>
  )
}
