import { MutableRefObject, useEffect, useRef } from 'react'
import { useError } from '../ErrorContext'
import type { AxiosInstance } from 'axios'
import { useEnvironment } from '../EnvironmentContext'
import axios from 'axios'
import qs from 'qs'
import { useTranslation } from 'react-i18next'

export function useApiClient(): MutableRefObject<AxiosInstance> {
  const { pushError } = useError()
  const {
    environment: { APP_API_URL },
  } = useEnvironment()
  const { i18n } = useTranslation()

  useEffect(() => {
    if (!APP_API_URL) pushError(new Error('Environment variable APP_IDENTITY_URL is missing!'))
  }, [APP_API_URL, pushError])

  const clientRef = useRef<AxiosInstance>(
    axios.create({
      baseURL: APP_API_URL,
      withCredentials: true,
      headers: {
        Accept: 'application/json',
        'Accept-Language': i18n.language,
      },
      paramsSerializer: {
        serialize: function (params) {
          return qs.stringify(params)
        },
      },
    })
  )

  useEffect(() => {
    clientRef.current = axios.create({
      baseURL: APP_API_URL,
      withCredentials: true,
      headers: {
        Accept: 'application/json',
        'Accept-Language': i18n.language,
      },
      paramsSerializer: {
        serialize: function (params) {
          return qs.stringify(params)
        },
      },
    })
  }, [APP_API_URL, i18n.language])

  return clientRef
}
