interface Options {
  timeout?: number
  signal?: AbortSignal
}

async function waitForElement(selector: string, options: Options = {}): Promise<Element> {
  const { timeout = 5000, signal = undefined } = options

  return new Promise((resolve, reject) => {
    if (signal?.aborted) {
      reject(signal.reason)
    }

    const element = document.querySelector(selector)
    if (element) {
      // if element is there, return it.
      return resolve(element)
    }

    const observer = new MutationObserver((_, observer) => {
      const element = document.querySelector(selector)
      if (element) {
        observer.disconnect()
        resolve(element)
      }
    })

    if (signal) {
      signal.addEventListener(
        'abort',
        () => {
          observer.disconnect()
          reject(signal.reason)
        },
        { once: true }
      )
    }

    setTimeout(() => {
      observer.disconnect()
      reject(new Error(`Timeout! Could not find element with ${selector} within ${timeout}ms`))
    }, timeout)

    observer.observe(document.body, {
      subtree: true,
      childList: true,
    })
  })
}

export function useWaitForElement() {
  return waitForElement
}
