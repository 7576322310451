import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Alert, Spin } from 'antd'
import { ConsultationContextInterface, useConsultation } from '../../../contexts'
import { Step } from './Step'
import { useBasicLayout } from '../../layouts'
import { WheelOptions, useCan, useWheel } from '../../../hooks'
import css from './Flow.module.css'
import { ConsultationSider } from '../consultation'
import { FlowControls } from './FlowControls'
import { Heading } from '../../typography'
import { CustomerInput } from './CustomerInput'
import { useNavigate } from 'react-router-dom'
import { useSwipeable, SwipeableProps } from 'react-swipeable'
import { useTranslation } from 'react-i18next'

/** relative offset of active step from top in percent */
const TOP_OFFSET = 0.15

interface FlowProps {
  className?: string
  onHasConsultation?: (takeover: boolean | undefined) => void
}

export const Flow: React.FC<FlowProps> = (props) => {
  const { className, onHasConsultation } = props
  const stepRefs = useRef<HTMLDivElement[]>([])
  const consultationContext = useConsultation()
  const {
    flow,
    active,
    initLoading,
    visible,
    data,
    hasConsultation,
    consultation,
    canBackward,
    canForward,
    move,
    flushing,
    consultationMeta,
  } = consultationContext
  const { contentFrameRef, FOOTER_HEIGHT, setSider } = useBasicLayout()
  const canInfosView = useCan('consultationInfos:view')
  const canExitToRoot = useCan('consultation:exitToRoot')
  const canExitToExercises = useCan('consultation:exitToExercises')
  const canExitToArchive = useCan('consultation:exitToArchive')
  const [closed, setClosed] = useState<ConsultationContextInterface['flushing'] | null>(null)
  const navigate = useNavigate()
  const { t } = useTranslation('consultation')

  /** the index (position) of the first step in the active group */
  const firstActive = useMemo<number>(() => active[0] || 0, [active])
  /** the index (position) of the last step in the active group */
  const lastActive = useMemo<number>(() => active[active.length - 1] || 0, [active])

  useEffect(() => {
    if (stepRefs.current[firstActive] && contentFrameRef.current) {
      contentFrameRef.current.scrollBy({
        // TODO: enable smooth scroll and fix bug with toggle pre saving
        // behavior: isSafari ? undefined : 'smooth',
        top:
          // top position of step
          stepRefs.current[firstActive].getBoundingClientRect().top -
          // subtract top position of fram
          contentFrameRef.current.getBoundingClientRect().top -
          // subtract offset from top:
          TOP_OFFSET * contentFrameRef.current.getBoundingClientRect().height,
      })
    }
  }, [firstActive, contentFrameRef])

  const getEndSpace = useCallback(
    (index: number) => {
      const isLastVisible = index === visible[visible.length - 1]
      if (!isLastVisible || !contentFrameRef.current || !stepRefs.current[index]) return undefined
      return (
        contentFrameRef.current.getBoundingClientRect().height * (1 - TOP_OFFSET) -
        stepRefs.current[index].getBoundingClientRect().height -
        FOOTER_HEIGHT -
        24 // padding bottom of container
      )
    },
    [FOOTER_HEIGHT, contentFrameRef, visible]
  )

  /** All `steps` of the consultation flow  */
  const steps = useMemo(() => flow?.steps || [], [flow])

  useEffect(() => {
    if (steps.length) {
      setClosed(null)
    }
  }, [steps.length])

  useEffect(() => {
    if (canInfosView) {
      setSider(
        steps.length ? <ConsultationSider consultationContext={consultationContext} className='h-full' /> : undefined
      )
      return () => {
        setSider(undefined)
      }
    }
  }, [setSider, visible, active, steps, data, canInfosView, consultationContext])

  useEffect(() => {
    onHasConsultation && onHasConsultation(hasConsultation)
  }, [hasConsultation, onHasConsultation])

  useEffect(() => {
    if (flushing && consultationMeta?.ref) {
      // detect if a consultation was closed
      setClosed(flushing)
    }
  }, [flushing, consultationMeta?.ref])

  useEffect(() => {
    if (closed?.ref && hasConsultation === false) {
      if (closed.isTraining && canExitToExercises) {
        navigate('/exercises')
        return
      }
      if (canExitToArchive) {
        navigate(`/consultations?ref=${closed.ref}`)
        return
      }
      if (canExitToRoot) {
        navigate(`/done?ref=${closed.ref}`)
        return
      }
    }
  }, [closed, hasConsultation, canExitToArchive, canExitToExercises, canExitToRoot, navigate])

  const swipeOptions = useMemo<SwipeableProps>(
    () => ({
      onSwipedUp: () => {
        if (canForward) {
          move({ direction: 'forward' })
        }
      },
      onSwipedDown: () => {
        if (canBackward) {
          move({ direction: 'backward' })
        }
      },
    }),
    [canBackward, canForward, move]
  )
  const swipeHandlers = useSwipeable(swipeOptions)

  // TODO: remove wheel navigation if annoying
  const wheelOptions = useMemo<WheelOptions>(
    () => ({
      onWheelUp: () => {
        if (canForward) {
          move({ direction: 'forward' })
        }
      },
      onWheelDown: () => {
        if (canBackward) {
          move({ direction: 'backward' })
        }
      },
    }),
    [canBackward, canForward, move]
  )
  const wheel = useWheel(wheelOptions)

  const containerRef = useCallback(
    (ref: HTMLDivElement | null) => {
      swipeHandlers.ref(ref)
      wheel.ref(ref)
    },
    [swipeHandlers, wheel]
  )

  const getWrapperClassName = useCallback(
    (index: number) => {
      const classNames: string[] = []
      if (active.includes(index)) classNames.push('active')
      if (index === firstActive) classNames.push('first-active')
      if (index === lastActive) classNames.push('last-active')
      return classNames.length ? classNames.join(' ') : undefined
    },
    [active, firstActive, lastActive]
  )

  return (
    <div id='flow-container' className={className}>
      <Spin spinning={initLoading}>
        <div>
          {steps.length ? (
            <div id='steps-container' className={css['container']} {...swipeHandlers} ref={containerRef}>
              {steps.map((step, index) => (
                <div
                  key={step._id}
                  id={index.toString()}
                  className={getWrapperClassName(index)}
                  ref={(element) => {
                    if (element) stepRefs.current.splice(index, 1, element)
                  }}
                  style={{ marginBottom: getEndSpace(index) }}
                >
                  <Step
                    step={step}
                    position={index}
                    visible={visible.includes(index)}
                    className={visible.includes(index) ? 'mb-6' : undefined}
                  />
                </div>
              ))}
            </div>
          ) : (
            hasConsultation !== undefined && !closed?.ref && (
              <Alert
                message={<Heading level={3}>{t('missing.title')}</Heading>}
                description={t('missing.description')}
                type='warning'
              />
            )
          )}
        </div>
      </Spin>
      {hasConsultation !== undefined ? hasConsultation && <FlowControls /> : null}
      {consultation?.training && <CustomerInput />}
    </div>
  )
}
