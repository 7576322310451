import React, { createContext, useMemo, useContext, PropsWithChildren, useCallback, Dispatch, useEffect } from 'react'
import type { Device, Employee, IdentityUser, Role } from '../../@types'
import { SpinPlaceholder } from '../../components/utils'
import { useEnvironment } from '../EnvironmentContext'
import { useError } from '../ErrorContext'
import { useIdentity } from '../IdentityContext'
import { useDevice } from './Device'
import { useEmployee } from './Employee'
import { useUser } from './User'
import { useLocation, useNavigate } from 'react-router-dom'
import { useApi } from '../ApiContext'
import { AxiosError } from 'axios'

interface AuthContextInterface {
  roles: Role[]
  device?: Device | null
  employee?: Employee | null
  user?: IdentityUser | null
  loading: boolean
  logout: () => void
  ready: boolean
  setEmployee: Dispatch<React.SetStateAction<Employee | null | undefined>>
  setDevice: Dispatch<React.SetStateAction<Device | null | undefined>>
}

const AuthContext = createContext<AuthContextInterface | undefined>(undefined)

const AuthContextProvider: React.FC<PropsWithChildren> = (props) => {
  const { children } = props
  const { loading: identityLoading, logout: logoutUser, token, refresh } = useIdentity()
  const location = useLocation()
  const { pushError } = useError()
  const {
    environment: { APP_IDENTITY_CLIENT_ID },
  } = useEnvironment()
  const userCtx = useMemo(() => ({ ready: token !== undefined }), [token])
  const { user, loading: userLoading } = useUser(userCtx)
  const { device, loading: deviceLoading, setDevice } = useDevice()
  const employeeCtx = useMemo(() => ({ ready: token !== undefined, device: device }), [token, device])
  const { employee, loading: employeeLoading, logout: logoutEmployee, setEmployee } = useEmployee(employeeCtx)
  const { onError401Ref, retry, setRetry } = useApi()
  const navigate = useNavigate()

  const roles = useMemo<Role[]>(() => {
    const roles: Role[] = []

    // if user, use user role
    if (user) {
      const { registrations = [] } = user
      const userRoles = registrations.find(({ applicationId }) => applicationId === APP_IDENTITY_CLIENT_ID)?.roles
      if (!userRoles?.length) pushError(new Error('Your user does not have any role!'))
      roles.push(...(userRoles ?? []))
    }

    if (employee) {
      roles.push('employee')
    }

    // if nothing, use customer role
    if (!roles.length) roles.push('customer')
    return roles
  }, [user, employee, APP_IDENTITY_CLIENT_ID, pushError])

  const ready = useMemo(
    () => device !== undefined && employee !== undefined && user !== undefined,
    [device, employee, user]
  )

  const loading = useMemo<boolean>(
    () => deviceLoading || employeeLoading || userLoading || identityLoading,
    [deviceLoading, employeeLoading, userLoading, identityLoading]
  )

  const logout = useCallback(() => {
    window.localStorage.setItem('pathname', location.pathname)
    if (employee && !user) {
      logoutEmployee()
    } else if (user && !employee) {
      logoutUser()
    } else if (user && employee) {
      // TODO: make logout user with employee work!
      logoutUser()
      logoutEmployee()
    }
  }, [employee, user, logoutEmployee, logoutUser, location])

  const onError401 = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (error: AxiosError<Api.Payload<Record<string, any>>>) => {
      const context = error.response?.data?.data?.['context'] as Record<string, unknown> | undefined
      const { origin } = context || {}

      if (!retry && token && origin === 'requireOAuthUser') {
        refresh()
        setRetry(true)
        return undefined
      }

      if ((employee && origin === 'requireEmployeeSession') || (user && origin === 'requireOAuthUser')) {
        logout()
      }

      if (origin === 'requireConsultationSession') {
        navigate('/')
      }

      return error
    },
    [retry, token, refresh, setRetry, employee, user, logout, navigate]
  )

  useEffect(() => {
    onError401Ref.current = onError401
  }, [onError401, onError401Ref])

  const value = useMemo<AuthContextInterface>(
    () => ({
      roles,
      user,
      device,
      employee,
      loading,
      logout,
      ready,
      setEmployee,
      setDevice,
    }),
    [roles, user, device, employee, loading, logout, ready, setEmployee, setDevice]
  )

  return (
    <AuthContext.Provider value={value}>
      {ready && !loading ? children : <SpinPlaceholder fullScreen id='auth-context' />}
    </AuthContext.Provider>
  )
}

const useAuth = (): AuthContextInterface => {
  const context = useContext(AuthContext)
  if (!context) {
    throw new Error('useAuth must be inside a Provider with a value')
  }
  return context
}

export { AuthContextProvider, useAuth }
