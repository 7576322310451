import React, { useMemo } from 'react'
import type { MenuProps } from 'antd'
import { Menu } from 'antd'
import { useLocation, useNavigate } from 'react-router-dom'
import { MenuItemType } from 'antd/es/menu/hooks/useItems'

// import css from './SiderNavigation.module.css'

interface SiderNavigationProps {
  className?: string
  items: MenuItemType[]
  onClick?: MenuProps['onClick']
}

export const SiderNavigation = (props: SiderNavigationProps): JSX.Element => {
  const { className, items, onClick } = props
  const navigate = useNavigate()
  const location = useLocation()

  const selectedKeys = useMemo<string[]>(() => {
    return items
      .filter(({ key }) => {
        return location.pathname.includes(key.toString())
      })
      .map(({ key }) => key.toString())
  }, [location, items])

  return (
    <div className={className}>
      <Menu
        id='sider-navigation'
        selectedKeys={selectedKeys}
        items={items}
        onClick={(event) => {
          const { key } = event
          onClick && onClick(event)
          if (key) navigate(key)
        }}
      />
    </div>
  )
}
