import { Modal } from 'antd'
import React, { useEffect, useMemo, useState } from 'react'
import { useConsultation, useMessage } from '../../../contexts'
import { ApiRequestConfig, useApiRequest, useQuery } from '../../../hooks'
import { useTranslation } from 'react-i18next'
// import css from './Close.module.css'

interface CloseProps {
  className?: string
  execute: boolean
  /** event when consultation is closed successfully */
  onDone?: () => void
  /** event when closing is aborted */
  onCancel?: () => void
  /** flag to toggle between 'Abschliessen' and 'Verlassen' */
  showExit?: boolean
}

/** component to process closing logic and a confirmation prompt */
export const Close: React.FC<CloseProps> = (props) => {
  const { className, execute, onDone, onCancel, showExit = false } = props
  const [closing, setClosing] = useState<boolean>(false)
  const { message } = useMessage()
  const { t } = useTranslation('consultation', { keyPrefix: 'close' })
  const [params, setParams] = useQuery()
  const { hasConsultation } = useConsultation()

  // close current session
  const closeConsultation = useMemo<ApiRequestConfig>(
    () => ({ method: 'DELETE', url: '/consultations/close', skip: !closing }),
    [closing]
  )
  const { loading, message: closedMessage, flush } = useApiRequest<undefined>(closeConsultation)

  useEffect(() => {
    if (closedMessage && closing) {
      setClosing(false)
      message.info(t('success'))
      flush()
      onDone && onDone()
    }
  }, [closedMessage, flush, message, closing, onDone, t])

  // allows to close consultation with query param `close` (used for intro tour)
  useEffect(() => {
    if (typeof params.close === 'string') {
      if (hasConsultation) {
        setClosing(true)
      }
      setParams((oldParams) => ({ ...oldParams, close: undefined }))
    }
  }, [params, hasConsultation, setParams])

  return (
    <div className={className}>
      <Modal
        title={showExit ? t('exit.title') : t('default.title')}
        open={execute}
        onOk={() => {
          setClosing(true)
        }}
        onCancel={onCancel}
        cancelText={t('cancel')}
        okText={showExit ? t('ok.exit') : t('ok.default')}
        okButtonProps={{ loading }}
        cancelButtonProps={{ loading }}
      >
        {showExit ? <p>{t('exit.description')}</p> : <p> {t('default.description')}</p>}
      </Modal>
    </div>
  )
}
