import React, { ReactNode } from 'react'
import css from './InputLabel.module.css'
import { Label } from '../typography'

interface InputLabelProps {
  className?: string
  children?: ReactNode
  label: string
  id?: string
}

export const InputLabel = (props: InputLabelProps): JSX.Element => {
  const { className, children, label, id } = props

  return (
    <div id={id} className={className ? `${className} ${css['container']}` : css['container']}>
      <div>
        <label htmlFor={label}>
          <Label>{label}</Label>
        </label>
      </div>
      <div id={label}>{children}</div>
    </div>
  )
}
