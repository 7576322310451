import React, { ReactNode, useMemo } from 'react'
import css from './Description.module.css'

interface DescriptionProps {
  className?: string
  children?: ReactNode
}

export const Description: React.FC<DescriptionProps> = (props) => {
  const { className: propsClassName, children } = props

  const className = useMemo(() => {
    const classNames = propsClassName?.split(' ') || []
    classNames.push(css.description)
    return classNames.join(' ')
  }, [propsClassName])

  return <span className={className}>{children}</span>
}
