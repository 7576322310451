import React, { useEffect, useMemo, useState } from 'react'
import { Heading } from '../../../typography'
import { Container } from '../../../utils'
import { ApiRequestConfig, useApiRequest, useExercise, useLocalize } from '../../../../hooks'
import { ExercisePerformance, ExerciseWithConsultations } from '../../../../@types/models/exercise'
import { Empty, Modal, Pagination, PaginationProps, Spin } from 'antd'
import css from './ExerciseList.module.css'
import { ExerciseCard, ExerciseTrainings } from '../../../content'
import { Training } from '../../../../@types'
import { ExerciseStatus } from '../../../content/training/ExerciseStatus'
import { useTranslation } from 'react-i18next'

export const ExerciseList = (): JSX.Element => {
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [openExercise, setOpenExercise] = useState<ExerciseWithConsultations | null>(null)
  const { exerciseStatus, trainingStatus } = useExercise()
  const { localize } = useLocalize()
  const { t } = useTranslation('exercises')

  const [initialized, setInitialized] = useState<boolean | undefined>(undefined)

  const PAGE_SIZE = 5

  const getExercises = useMemo<ApiRequestConfig>(
    () => ({
      method: 'GET',
      url: '/exercises/consultations',
      skip: initialized === undefined,
      params: {
        limit: PAGE_SIZE,
        skip: (currentPage - 1) * PAGE_SIZE,
        sort: { 'displayName.de-CH': 1 },
      },
    }),
    [currentPage, initialized]
  )
  const { data: exercises, count, loading: exercisesLoading } = useApiRequest<ExerciseWithConsultations[]>(getExercises)

  const trainingIds = useMemo<string[]>(
    () => [
      ...new Set(
        (exercises || []).reduce((ids: string[], { trainings }) => {
          ids.push(...trainings)
          return ids
        }, [])
      ),
    ],
    [exercises]
  )

  const getTrainings = useMemo<ApiRequestConfig>(
    () => ({
      method: 'GET',
      url: '/trainings',
      skip: !exercises?.length,
      params: {
        // max 5 trainings per exercise loaded, increase if more
        limit: 5 * PAGE_SIZE,
        filter: {
          _id: { $in: trainingIds },
        },
      },
    }),
    [exercises, trainingIds]
  )
  const { data: trainings, loading: trainingsLoading } = useApiRequest<Training[]>(getTrainings)

  const trainingsMap = useMemo<Record<string, Omit<Training, '_id'>>>(
    () => Object.fromEntries((trainings || []).map(({ _id, ...rest }) => [_id, rest])),
    [trainings]
  )

  const performance = useMemo<ExercisePerformance[] | undefined>(
    () =>
      exercises?.map(({ _id, credits, trainings, consultations }) => {
        const performance = trainings.map((id) => ({
          training: id,
          status: trainingStatus(consultations[id]?.training || { ...trainingsMap[id], _id: id }, consultations[id]),
        }))

        return {
          exercise: _id,
          credits,
          status: exerciseStatus(trainings, performance),
          performance,
        }
      }),
    [trainingsMap, exerciseStatus, trainingStatus, exercises]
  )

  const openExercisePerformance = useMemo(
    () => performance?.find(({ exercise }) => exercise === openExercise?._id),
    [openExercise, performance]
  )

  // pagination
  const pagination = useMemo<PaginationProps>(
    () => ({
      hideOnSinglePage: true,
      current: currentPage,
      pageSize: PAGE_SIZE,
      total: count,
      size: 'small',
      showSizeChanger: false,
      onChange: (page: number) => {
        setCurrentPage(page || 1)
      },
    }),
    [currentPage, count]
  )

  const loading = useMemo<boolean>(() => exercisesLoading || trainingsLoading, [trainingsLoading, exercisesLoading])

  useEffect(() => {
    if (initialized === undefined) {
      setInitialized(true)
    }
  }, [initialized])

  return (
    <Container>
      <Heading level={2}>{t('title')}</Heading>
      <Spin spinning={loading}>
        {exercises?.length ? (
          exercises?.map((exercise, index) => {
            const { _id } = exercise
            return (
              <ExerciseCard
                key={_id}
                exercise={exercise}
                className={css['card']}
                status={performance?.[index]?.status}
                onOpen={(exercise) => {
                  setOpenExercise(exercise)
                }}
              />
            )
          })
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
      </Spin>
      <div className={css['pagination']}>
        <Pagination {...pagination} />
      </div>
      <Modal
        title={
          <div className={css['modal-title']}>
            <ExerciseStatus status={openExercisePerformance?.status} />
            <Heading className='mb-0' level={3}>
              {localize(openExercise?.displayName)}
            </Heading>
          </div>
        }
        open={!!openExercise}
        onOk={() => {
          setOpenExercise(null)
        }}
        onCancel={() => {
          setOpenExercise(null)
        }}
        okText={t('trainings.ok')}
        okButtonProps={{ loading, type: 'default' }}
        cancelButtonProps={{ loading, className: 'hidden' }}
      >
        <Heading level={4}>{t('trainings.title')}</Heading>
        {openExercise && (
          <ExerciseTrainings
            exercise={openExercise}
            trainings={trainings}
            performance={openExercisePerformance?.performance}
            onChange={() => {
              setInitialized(undefined)
              setOpenExercise(null)
            }}
          />
        )}
      </Modal>
    </Container>
  )
}
