import { Button, Dropdown, MenuProps } from 'antd'
import React, { useMemo } from 'react'
import { localesMeta, supportedLocale } from '../../../config/i18n'
import { useTranslation } from 'react-i18next'
import { GlobalOutlined } from '@ant-design/icons'
import css from './LocaleSelect.module.css'

interface LocaleSelectProps {
  className?: string
}

export const LocaleSelect = (props: LocaleSelectProps): JSX.Element => {
  const { className } = props
  const { i18n } = useTranslation()

  const locales = useMemo<MenuProps['items']>(
    () =>
      supportedLocale.map((locale) => ({
        key: locale,
        label: localesMeta[locale]?.long,
        icon: <div className={css['icon']}>{localesMeta[locale]?.short}</div>,
        disabled: localesMeta[locale]?.disabled,
      })),
    []
  )

  return (
    <Dropdown
      menu={{
        items: locales,
        onClick: ({ key }) => {
          i18n.changeLanguage(key)
        },
        selectedKeys: [i18n.language],
      }}
      arrow={true}
      placement='bottomRight'
      trigger={['click']}
    >
      <Button type='default' shape='circle' className={className} icon={<GlobalOutlined />} />
    </Dropdown>
  )
}
