/* eslint-disable max-len */

// start md string
export const legalNotice = /* md */ `

### Inhaltliche Verantwortung

__[Pharmaceutical Care Research Group](https://pharma.unibas.ch/de/research/research-groups/pharmaceutical-care-2170/)__  
Universität Basel  
Klingelbergstrasse 50  
4056 Basel  
Schweiz

#### Idee, Konzeption und pharmazeutische Fachinhalte

[Esther Spinatsch](https://pharma.unibas.ch/de/personen/esther-spinatsch/)

#### Vertretungsberechtigte Personen

[Prof. Dr. Samuel Allemann](https://pharma.unibas.ch/de/personen/samuel-allemann/)

#### Name des Unternehmens

__[Pharmaceutical Care Research Group](https://pharma.unibas.ch/de/research/research-groups/pharmaceutical-care-2170/)__  
Universität Basel  
Registrationsnummer: CHE-115.244.907  
Umsatzsteuer-Identifikationsnummer: CHE-115.244.907 MWST

#### Haftungsausschluss

Der Autor übernimmt keine Gewähr für die Richtigkeit, Genauigkeit, Aktualität, Zuverlässigkeit und Vollständigkeit der Informationen.
Haftungsansprüche gegen den Autor wegen Schäden materieller oder immaterieller Art, die aus dem Zugriff oder der Nutzung bzw. Nichtnutzung der veröffentlichten Informationen, durch Missbrauch der Verbindung oder durch technische Störungen entstanden sind, werden ausgeschlossen.

Alle Angebote sind freibleibend. Der Autor behält es sich ausdrücklich vor, Teile der Seiten oder das gesamte Angebot ohne gesonderte Ankündigung zu verändern, zu ergänzen, zu löschen oder die Veröffentlichung zeitweise oder endgültig einzustellen.

#### Haftungsausschluss für Inhalte und Links

Verweise und Links auf Webseiten Dritter liegen ausserhalb unseres Verantwortungsbereichs. Es wird jegliche Verantwortung für solche Webseiten abgelehnt. Der Zugriff und die Nutzung solcher Webseiten erfolgen auf eigene Gefahr des jeweiligen Nutzers.

#### Urheberrechtserklärung

Die Urheber- und alle anderen Rechte an Inhalten, Bildern, Fotos oder anderen Dateien auf dieser Website, gehören ausschliesslich Universität Basel, Pharmaceutical Care Research Group oder den speziell genannten Rechteinhabern. Für die Reproduktion jeglicher Elemente ist die schriftliche Zustimmung des Urheberrechtsträgers im Voraus einzuholen.

_Quelle Text: [BrainBox Solutions](https://brainbox.swiss/impressum-generator-schweiz/)_


### Technische Verantwortung und Betrieb

__[EMBRIO.tech AG](https://embrio.tech/de)__ (nachfolgend EMBRIO.tech)  
Via Santa Croce 13  
6826 Riva San Vitale, Schweiz  
Schweiz

#### Urheberrechtserklärung

Jegliches geistiges Eigentum an der technischen Implementierung der Applikation, ob registriert oder nicht, wie beispielsweise Urheberrechte, Know-how und Marken (nachfolgend «Geistiges Eigentum») ist ausschliesslich im Besitz von EMBRIO.tech. EMBRIO.tech behält die Rechte an ihrem Geistigen Eigentum und an den Computerprogrammen, unabhängig von deren Schutz und davon, ob die Kundin oder Dritte daran Änderungen vorgenommen haben.

` // end md string
