import { AliasToken } from 'antd/es/theme/internal'
import React, { createContext, PropsWithChildren, useContext, useEffect, useMemo, useState } from 'react'
import { Spin, ConfigProvider } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import type { Locale } from 'antd/es/locale'
import deDE from 'antd/locale/de_DE'
import enUS from 'antd/locale/en_US'
import frFR from 'antd/locale/fr_FR'
import itIT from 'antd/locale/it_IT'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import updateLocale from 'dayjs/plugin/updateLocale'
import 'dayjs/locale/de-ch'
import 'dayjs/locale/fr-ch'
import 'dayjs/locale/it-ch'
import 'dayjs/locale/en'
// eslint-disable-next-line @typescript-eslint/no-var-requires
import { breakpoints, colors } from '../config'
import { useTranslation } from 'react-i18next'

dayjs.extend(relativeTime)
dayjs.extend(updateLocale)
dayjs.updateLocale('en', {
  // also start english calander on monday
  weekStart: 1,
})

const defaultToken: Partial<AliasToken> = {
  colorPrimary: colors.primary.DEFAULT,
  colorInfo: colors.info.DEFAULT,
  colorSuccess: colors.success.DEFAULT,
  colorWarning: colors.warning.DEFAULT,
  colorError: colors.error.DEFAULT,
  colorBgLayout: colors.light.DEFAULT,

  // colorTextLightSolid: colors.dark.DEFAULT,

  borderRadius: 6,

  screenXS: breakpoints.XS,
  screenXSMin: breakpoints.XS,
  screenXSMax: breakpoints.SM - 1,

  screenSM: breakpoints.SM,
  screenSMMin: breakpoints.SM,
  screenSMMax: breakpoints.MD - 1,

  screenMD: breakpoints.MD,
  screenMDMin: breakpoints.MD,
  screenMDMax: breakpoints.LG - 1,

  screenLG: breakpoints.LG,
  screenLGMin: breakpoints.LG,
  screenLGMax: breakpoints.XL - 1,

  screenXL: breakpoints.XL,
  screenXLMin: breakpoints.XL,
  screenXLMax: breakpoints.XXL - 1,

  screenXXL: breakpoints.XXL,
  screenXXLMin: breakpoints.XXL,

  fontSize: 16,
  fontFamily:
    'Montserrat, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", \
    Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
}

// set loading icon globally. TODO: refactor to separate config file
Spin.setDefaultIndicator(<LoadingOutlined className='text-gray-600' style={{ fontSize: '1.5rem' }} />)

interface ThemeContextInterface {
  token: Partial<AliasToken>
  setToken: React.Dispatch<React.SetStateAction<Partial<AliasToken>>>
}

const ThemeContext = createContext<ThemeContextInterface | undefined>(undefined)

const ThemeContextProvider: React.FC<PropsWithChildren> = (props) => {
  const { children } = props

  const { i18n } = useTranslation()

  const [token, setToken] = useState<Partial<AliasToken>>(defaultToken)

  const value = useMemo<ThemeContextInterface>(
    () => ({
      token,
      setToken,
    }),
    [token, setToken]
  )

  const antdLocales: Record<string, Locale> = {
    'de-CH': deDE,
    'en-US': enUS,
    'fr-CH': frFR,
    'it-CH': itIT,
  }

  useEffect(() => {
    dayjs.locale(i18n.language)
    document.documentElement.lang = i18n.language
  }, [i18n.language])

  return (
    <ThemeContext.Provider value={value}>
      <ConfigProvider
        theme={{
          token,
          components: {
            Layout: {
              headerBg: colors.dark.DEFAULT,
            },
            Menu: {
              horizontalLineHeight: '3.5rem',
            },
          },
        }}
        locale={antdLocales[i18n.language]}
      >
        {children}
      </ConfigProvider>
    </ThemeContext.Provider>
  )
}

const useTheme = (): ThemeContextInterface => {
  const context = useContext(ThemeContext)
  if (!context) {
    throw new Error('useTheme must be inside a Provider with a value')
  }
  return context
}

export { ThemeContextProvider, useTheme }
