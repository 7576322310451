import React, { createContext, useState, useMemo, useContext, useEffect, PropsWithChildren, useCallback } from 'react'
import { AppError } from '../@types'
import { useMessage } from './MessageContext'
import { useTranslation } from 'react-i18next'

interface ErrorContextInterface {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errors: AppError[]
  pushError: (error: AppError) => void
  // setError: React.Dispatch<React.SetStateAction<AppError | null>>
}

const ErrorContext = createContext<ErrorContextInterface | undefined>(undefined)

const ErrorContextProvider: React.FC<PropsWithChildren> = (props) => {
  const { children } = props
  const [errors, setErrors] = useState<AppError[]>([])
  const { message } = useMessage()
  const { t } = useTranslation('error')

  const pushError = useCallback((error: AppError) => {
    setErrors((existingErrors) => [...existingErrors, error])
  }, [])

  const handleError = useCallback(
    (error: AppError) => {
      // TODO: improve error messaging for different errors (e.g. ApolloError)
      if (error && error.name !== 'CanceledError') {
        if (error.name === 'AxiosError' && typeof error.response?.data === 'object') {
          const { message, data, statusCode } = error.response.data
          if (typeof data?.context?.origin === 'string' && statusCode === 401) {
            error.message = t('401.message', { origin: data.context.origin })
          } else if (typeof data?.context?.key === 'string' && statusCode) {
            error.message = t(`${statusCode}.${data.context.key}`)
          } else if (typeof message === 'string') {
            error.message = message
          }
        }
        message.error(error.message, 10)
        console.error(error)
      }
    },
    [message, t]
  )

  const value = useMemo<ErrorContextInterface>(
    () => ({
      errors,
      pushError,
    }),
    [errors, pushError]
  )

  useEffect(() => {
    if (errors.length) {
      const error = errors[0]
      setErrors((errors) => errors.splice(1))
      handleError(error)
    }
  }, [errors, handleError])

  return <ErrorContext.Provider value={value}>{children}</ErrorContext.Provider>
}

const useError = (): ErrorContextInterface => {
  const context = useContext(ErrorContext)
  if (!context) {
    throw new Error('useError must be inside a Provider with a value')
  }
  return context
}

export { ErrorContextProvider, useError }
