import React from 'react'
import type { ExtraProps } from 'react-markdown'

// import css from './A.module.css'

export const A = (
  markdownProps: React.ClassAttributes<HTMLAnchorElement> & React.AnchorHTMLAttributes<HTMLAnchorElement> & ExtraProps
): JSX.Element => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { node, ...props } = markdownProps

  // use div element instead of p element, to avoid error:
  //  <div> cannot appear as a descendant of <p> for Img component
  return <a target='_blank' tabIndex={-1} {...props} />
}
