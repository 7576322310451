import { Button, Tooltip as AntTooltip } from 'antd'
import React from 'react'
import { TooltipRenderProps } from 'react-joyride'
import { Card } from '../Card'
import css from './Tooltip.module.css'
import Icon, { FullscreenExitOutlined, StepBackwardOutlined, StepForwardOutlined } from '@ant-design/icons'
import { Heading } from '../../typography'
import { useBreakpoints } from '../../../hooks'
import { Play, Stop } from '../../../svg/icons'
import { Markdown } from '../markdown'
import { useTranslation } from 'react-i18next'

export function Tooltip(props: TooltipRenderProps): JSX.Element {
  const {
    step: { title, content, locale },
    index,
    isLastStep,
    size,
    backProps,
    primaryProps,
    closeProps,
    skipProps,
    tooltipProps,
  } = props
  const { ltSM } = useBreakpoints()
  const { t } = useTranslation('intro')

  return (
    <div {...tooltipProps} className={css['wrapper']}>
      <Card className={css['card']}>
        <div className={css['container']}>
          <div className={css['header']}>
            <div className={css['status']}>{index !== 0 ? `${index} von ${size - 1}` : t('intro')}</div>
            {index !== 0 && (
              <div className={css['controls']}>
                <Button
                  type='text'
                  size='small'
                  className={css['back']}
                  icon={<StepBackwardOutlined className={css['icon']} />}
                  {...backProps}
                />

                <Button
                  {...primaryProps}
                  type='text'
                  size='small'
                  className={css['next']}
                  icon={
                    index === 0 ? (
                      <Icon component={Play} className={css['icon']} />
                    ) : isLastStep ? (
                      <Icon component={Stop} className={css['icon']} />
                    ) : (
                      <StepForwardOutlined className={css['icon']} />
                    )
                  }
                />

                <AntTooltip title={locale?.close}>
                  <Button
                    type='text'
                    size='small'
                    icon={<FullscreenExitOutlined className={css['icon']} />}
                    className={css['close']}
                    {...closeProps}
                  />
                </AntTooltip>
              </div>
            )}
          </div>

          <div className={css['content']}>
            {title && <Heading level={4}>{title}</Heading>}
            {typeof content === 'string' ? <Markdown>{content}</Markdown> : content}
          </div>
          <div className={css['footer']}>
            {index !== 0 &&
              (ltSM ? (
                <AntTooltip title={locale?.back}>
                  <Button className={css['back']} icon={<StepBackwardOutlined />} {...backProps} />
                </AntTooltip>
              ) : (
                <Button icon={<StepBackwardOutlined />} {...backProps}>
                  {locale?.back}
                </Button>
              ))}

            {!isLastStep &&
              (ltSM && index !== 0 ? (
                <AntTooltip title={locale?.skip}>
                  <Button {...skipProps} icon={<Icon component={Stop} />} />
                </AntTooltip>
              ) : (
                <Button
                  {...skipProps}
                  // type={index === 0 ? 'default' : 'dashed'}
                  icon={<Icon component={Stop} />}
                  className='shrink w-auto truncate xs:shrink-0'
                >
                  {locale?.skip}
                </Button>
              ))}
            {ltSM && index !== 0 ? (
              <AntTooltip title={isLastStep ? locale?.last : locale?.next}>
                <Button
                  {...primaryProps}
                  type='primary'
                  icon={
                    index === 0 ? (
                      <Icon component={Play} />
                    ) : isLastStep ? (
                      <Icon component={Stop} />
                    ) : (
                      <StepForwardOutlined />
                    )
                  }
                />
              </AntTooltip>
            ) : (
              <Button
                {...primaryProps}
                type='primary'
                icon={
                  index === 0 ? (
                    <Icon component={Play} />
                  ) : isLastStep ? (
                    <Icon component={Stop} />
                  ) : (
                    <StepForwardOutlined />
                  )
                }
              >
                {isLastStep ? locale?.last : locale?.next}
              </Button>
            )}
          </div>
        </div>
      </Card>
    </div>
  )
}
