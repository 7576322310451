import { useEffect, useState, useMemo } from 'react'
import { ApiRequestConfig, useApiRequest } from '../../hooks'
import type { Device } from '../../@types'

export function useDevice() {
  const [device, setDevice] = useState<Device | null | undefined>(undefined)

  const getDevice = useMemo<ApiRequestConfig>(
    () => ({
      method: 'GET',
      url: '/devices/current',
      skip: !!device || device === null,
    }),
    [device]
  )
  const { data, loading, error, flush } = useApiRequest<Device | null>(getDevice)

  useEffect(() => {
    if (data !== undefined) setDevice(data)
  }, [data])

  useEffect(() => {
    if (device !== undefined) flush()
  }, [device, flush])

  return { device, loading, error, setDevice }
}
