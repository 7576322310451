import React, { forwardRef, useEffect, useImperativeHandle, useRef } from 'react'
import type { ComponentProps, Localized } from '../../../../@types'
import { Switch } from 'antd'
import { useConsultation } from '../../../../contexts'
import css from './Toggle.module.css'
import { InputLayout } from '../utils'
import { useLocalize } from '../../../../hooks'

export const Toggle = forwardRef<unknown, ComponentProps<ToggleMeta>>(function Toggle(
  props: ComponentProps<ToggleMeta>,
  ref
): JSX.Element {
  const {
    meta: { label, description, trueLabel, falseLabel },
    data,
    name,
    className,
    status,
    validation,
    isActive,
    isRequired,
    touched,
    onFocus,
    onBlur,
  } = props
  const { update, loading } = useConsultation()
  const { localize } = useLocalize()

  const localRef = useRef<HTMLButtonElement | null>(null)

  useImperativeHandle(ref, () => localRef.current)

  useEffect(() => {
    // if is active but state not yet saved, update state
    if (data !== undefined && data[name] === undefined && isActive) {
      update({ data: { [name]: false } })
    }
  }, [isActive, data, name, update])

  const onChange = (checked: boolean) => {
    localRef.current?.blur()
    // emit input change
    update({ data: { [name]: checked } })
  }

  useEffect(() => {
    if (localRef.current && onFocus) {
      const element = localRef.current

      element.addEventListener('focus', onFocus)

      return () => {
        element.removeEventListener('focus', onFocus)
      }
    }
  }, [isActive, onFocus, name])

  useEffect(() => {
    if (localRef.current && onBlur) {
      const element = localRef.current

      element.addEventListener('blur', onBlur)

      return () => {
        element.removeEventListener('blur', onBlur)
      }
    }
  }, [isActive, onBlur, name])

  return (
    <InputLayout
      className={className}
      label={localize(label)}
      description={localize(description)}
      status={touched ? status : undefined}
      validation={touched ? validation : undefined}
      required={isRequired}
    >
      {localize(falseLabel) && <div className={css['option-label']}>{localize(falseLabel)}</div>}
      <Switch ref={localRef} checked={data?.[name] as boolean} onChange={onChange} loading={loading} />
      {localize(trueLabel) && <div className={css['option-label']}>{localize(trueLabel)}</div>}
    </InputLayout>
  )
})

interface ToggleMeta {
  label?: Localized
  description?: Localized
  falseLabel?: Localized
  trueLabel?: Localized
}
