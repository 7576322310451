import React from 'react'
import type { ExtraProps } from 'react-markdown'
import css from './P.module.css'

export const P = (
  markdownProps: React.ClassAttributes<HTMLParagraphElement> & React.HTMLAttributes<HTMLParagraphElement> & ExtraProps
): JSX.Element => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { node, ...props } = markdownProps

  // use div element instead of p element, to avoid error:
  //  <div> cannot appear as a descendant of <p> for Img component
  return <div className={css['p']} {...props} />
}
