import React, { forwardRef, useCallback, useImperativeHandle, useRef } from 'react'
import { InputNumber } from 'antd'
import type { ComponentProps, Localized } from '../../../../@types'
import { useConsultation } from '../../../../contexts'
import { useDebouncedCallback, useLocalize } from '../../../../hooks'
import css from './NumberInput.module.css'
import { InputLayout } from '../utils'

export const NumberInput = forwardRef<unknown, ComponentProps<NumberInputMeta>>(function NumberInput(
  props: ComponentProps<NumberInputMeta>,
  ref
): JSX.Element {
  const { meta, className, name, status, validation, data, isRequired, touched, onFocus, onBlur, isActive } = props
  const { min, max, step, label, description, unit } = meta
  const { update, loading } = useConsultation()
  const { localize } = useLocalize()

  const localRef = useRef<HTMLInputElement | null>(null)

  useImperativeHandle(ref, () => localRef.current)

  const onChange = useDebouncedCallback(
    useCallback(
      (value: number | null) => {
        update({ data: { [name]: value ?? undefined } })
      },
      [update, name]
    )
  )

  return (
    <InputLayout
      className={className}
      label={localize(label)}
      description={localize(description)}
      status={touched ? status : undefined}
      validation={touched ? validation : undefined}
      required={isRequired}
      loading={isActive && loading}
    >
      <InputNumber
        ref={localRef}
        value={(data?.[name] as number) ?? null}
        className={css['input']}
        min={min}
        max={max}
        step={step}
        status={touched ? status : undefined}
        // the disable on loading causes a focus loss.
        // TODO: don't enable disable on loading or find better solution.
        // disabled={loading}
        addonAfter={localize(unit)}
        controls={false}
        onChange={onChange}
        onPressEnter={() => {
          localRef.current?.blur()
        }}
        keyboard={false}
        onBlur={onBlur}
        onFocus={onFocus}
        pattern='\d*'
        inputMode='numeric'
      />
    </InputLayout>
  )
})

interface NumberInputMeta {
  min?: number
  max?: number
  step?: number
  label?: Localized
  description?: Localized
  unit?: string | Localized
}
