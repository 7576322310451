import { Button, ButtonProps, Spin } from 'antd'
import React, { CSSProperties, ReactNode, forwardRef } from 'react'
import css from './ControlButton.module.css'
import type { ButtonType } from 'antd/lib/button'

interface ControlButtonProps extends ButtonProps {
  icon: ReactNode
  className?: string
  style?: CSSProperties
  disabled?: boolean
  loading?: boolean
  type?: ButtonType
  onClick?: React.MouseEventHandler<HTMLAnchorElement> & React.MouseEventHandler<HTMLButtonElement>
  id?: string
  pulse?: boolean
}

export const ControlButton = forwardRef<HTMLElement, ControlButtonProps>(function ControlButton(
  props: ControlButtonProps,
  ref
): JSX.Element {
  const { className, style, disabled = false, onClick, loading = false, icon, type, id, pulse = false, ...rest } = props

  return (
    <div className={className ? `${className} ${css['control']}` : css['control']} style={style} id={id}>
      {pulse && <div className={css['pulse']}></div>}
      <Button
        ref={ref}
        className={css['button']}
        disabled={disabled}
        shape='circle'
        type={type}
        size='large'
        onClick={onClick}
        style={{ lineHeight: 0 }}
        {...rest}
      >
        {loading ? <Spin /> : icon}
      </Button>
    </div>
  )
})
