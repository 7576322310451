import { apply } from 'json-logic-js'
import type { Consultation, Training, TrainingPerformance } from '../@types'

export const supportedExerciseStatus = ['passed', 'completed', 'incomplete', 'open'] as const

type ExerciseStatus = (typeof supportedExerciseStatus)[number]

/**
 *
 * @param trainings - the ids of the trainings which belong to the exercise
 * @param consultations - the consultations which belong to the exercise's trainings
 * @returns the status of the exercise (one of `supportedExerciseStatus`)
 */
function exerciseStatus(trainings: string[], performance: TrainingPerformance[]): ExerciseStatus | undefined {
  const trainingsStatusMap = Object.fromEntries(performance.map(({ training, status }) => [training, status]))
  const ignore = trainings.reduce((count: number, id) => {
    if (trainingsStatusMap[id] === 'ignore') count++
    return count
  }, 0)
  const total = trainings.length - ignore
  const open = trainings.reduce((count: number, id) => {
    if (trainingsStatusMap[id] === 'open') count++
    return count
  }, 0)
  const success = trainings.reduce((count: number, id) => {
    if (trainingsStatusMap[id] === 'success') count++
    return count
  }, 0)

  // // TODO: use more counters if needed
  // const incomplete = trainings.reduce((count: number, id) => {
  //   if (trainingsStatusMap[id] === 'incomplete') count++
  //   return count
  // }, 0)
  // const error = trainings.reduce((count: number, id) => {
  //   if (trainingsStatusMap[id] === 'error') count++
  //   return count
  // }, 0)

  if (open === total) return 'open'
  if (success === total) return 'passed'
  if (open === 0) return 'completed'
  if (open > 0) return 'incomplete'
  return undefined
}

export const supportedTrainingStatus = ['error', 'success', 'incomplete', 'open', 'ignore'] as const

type TrainingStatus = (typeof supportedTrainingStatus)[number]

function getData(consultation: Consultation): Consultation['data'] | undefined {
  const { data } = consultation || {}
  const { variables } = consultation.flow || {}
  if (!data || !variables) return undefined
  const computed = Object.fromEntries(Object.entries(variables).map(([key, rule]) => [key, apply(rule, data)]))
  return { ...data, ...computed }
}

/**
 *
 * @param training - the training object to compute the status for
 * @param consultation - the consultation to the training if existing, else `null`
 * @returns the status of the training (one of `supportedTrainingStatus`)
 */
function trainingStatus(training: Training, consultation: Consultation | null): TrainingStatus | undefined {
  // if no assessment, success if consultation
  if (!training.assessment) return 'ignore'

  // if no consultation, the training was not started
  if (consultation === null) return 'open'

  // if assessment, evaluate it with data
  const result = apply(training.assessment, getData(consultation))

  // if non-valid status return undefined
  if (result && (typeof result !== 'string' || !supportedTrainingStatus.includes(result as TrainingStatus))) {
    console.warn(
      `training assessment of training ${training._id} produced invalid status for consultation ${consultation._id}.`,
      '\ntypeof status: ',
      typeof status,
      '\nstatus:\n',
      status
    )
    return undefined
  }

  // return assessment result
  return result
}

export function useExercise() {
  return { exerciseStatus, trainingStatus }
}
