import React, { MouseEvent, useState, useMemo, useEffect } from 'react'
import { Device } from '../../@types'
import { Modal } from 'antd'
import css from './RemoveDevice.module.css'
import { ApiRequestConfig, useApiRequest } from '../../hooks'
import { useAuth, useMessage } from '../../contexts'
import { useTranslation } from 'react-i18next'

interface RemoveDeviceProps {
  show?: boolean
  onCancel?: (e: MouseEvent<HTMLButtonElement>) => void
  onRemoved?: (device: Device) => void
}

export const RemoveDevice = (props: RemoveDeviceProps): JSX.Element => {
  const { show, onCancel, onRemoved } = props
  const [exec, setExec] = useState<boolean>(false)
  const { message } = useMessage()
  const { setDevice, device } = useAuth()
  const { t } = useTranslation('admin', { keyPrefix: 'device.remove' })

  const removeDevice = useMemo<ApiRequestConfig>(
    () => ({
      method: 'DELETE',
      url: '/devices/current',
      skip: !exec || !device,
    }),
    [exec, device]
  )
  const { data: deletedDevice, loading } = useApiRequest<Device>(removeDevice)

  useEffect(() => {
    if (deletedDevice && device) {
      message.success('Gerät entfernt.')
      setDevice(null)
      setExec(false)
      onRemoved && onRemoved(deletedDevice)
    }
  }, [deletedDevice, device, message, onRemoved, setDevice])

  return (
    <Modal
      title={t('title')}
      open={show}
      onOk={() => {
        setExec(true)
      }}
      onCancel={(e) => {
        onCancel && onCancel(e)
      }}
      cancelText={t('cancel')}
      okText={t('ok')}
      okButtonProps={{ loading }}
      cancelButtonProps={{ loading }}
    >
      <div className={css['content']}>
        <p>{t('description')}</p>
      </div>
    </Modal>
  )
}
