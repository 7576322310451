import React, { createContext, useMemo, useContext, PropsWithChildren, useRef } from 'react'
import type { AccessParams, GlobalAccessParams, Role } from '../@types'
import { RBAC } from '@embrio-tech/easy-rbac-plus'
import { permissions } from '../config'
import { useAuth } from './AuthContext'

interface AccessContextInterface {
  // TODO: type global access context
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  context: GlobalAccessParams
  rbac: RBAC<AccessParams, Role>
  roles: Role[]
}

const AccessContext = createContext<AccessContextInterface | undefined>(undefined)

const AccessContextProvider: React.FC<PropsWithChildren> = (props) => {
  const { children } = props
  const rbacRef = useRef(new RBAC(permissions))
  const { roles, user, device, employee } = useAuth()

  // TODO: set global context
  const context = useMemo(
    () => ({
      auth: { user, device, employee },
    }),
    [user, device, employee]
  )

  const value = useMemo<AccessContextInterface>(
    () => ({
      roles,
      context,
      rbac: rbacRef.current,
    }),
    [roles, context]
  )

  return <AccessContext.Provider value={value}>{children}</AccessContext.Provider>
}

const useAccess = (): AccessContextInterface => {
  const context = useContext(AccessContext)
  if (!context) {
    throw new Error('useAccess must be inside a Provider with a value')
  }
  return context
}

export { AccessContextProvider, useAccess }
