import React, { forwardRef } from 'react'
import type { Localized, ComponentProps } from '../../../../@types'
import './Title.css'
import { useLocalize } from '../../../../hooks'

export const Title = forwardRef<HTMLDivElement, ComponentProps<TitleMeta>>(function Title(
  props: ComponentProps<TitleMeta>,
  ref
): JSX.Element {
  const {
    meta: { text },
    className,
  } = props
  const { localize } = useLocalize()

  return (
    <div ref={ref} className={className}>
      <h2 className='title'>{localize(text)}</h2>
    </div>
  )
})

interface TitleMeta {
  text?: Localized
}
